import React from 'react';
import { CreateCarFields } from '@spa-cars/models';
import { Input } from '../../../input';
import { ACTIONS } from '../NewCarModal/pages/actions';
import { StepLayout } from '../NewCarModal/pages/common';

interface TokenInputProps {
  state: Partial<CreateCarFields>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function TokenInput({ state, handleChange }: TokenInputProps) {
  return (
    <StepLayout
      step={0}
      title="Token de su vehículo"
      description="Indique el token de su vehículo"
    >
      <div className="mt-5">
        <Input
          value={state.token ?? ''}
          name={ACTIONS.TOKEN}
          placeholder="Ingrese el token"
          onChange={handleChange}
          required
        />
      </div>
    </StepLayout>
  );
}

export default TokenInput;
