/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Multimedia } from '@spa-cars/models';
import { ScrollCarouselWrapper } from '../shared';

interface ShopCarouselSectionProps {
  images: Multimedia[];
}

function ShopCarouselSection({ images = [] }: ShopCarouselSectionProps) {
  const [indexSelected, setIndexSelected] = React.useState(0);
  const selectImg = (e: React.MouseEvent<HTMLDivElement>) => {
    const { idx } = e.currentTarget.dataset;
    setIndexSelected(Number(idx));
  };

  return (
    <div className="flex flex-col py-5 lg:py-0  lg:pl-5 ">
      {/* selected image */}
      <div className="w-full">
        <div className="relative z-10 h-[15rem] md:h-[28rem] ">
          <img
            className="carousel-img w-full h-full rounded-lg md:rounded-2xl transition relative object-cover"
            src={images[indexSelected]?.src}
            alt={images[indexSelected]?.alt}
          />
        </div>
      </div>
      <ScrollCarouselWrapper scrollLength={120} controlsClassName="top-[40px]">
        {images.map((img, i) => (
          <div
            key={`shop-img-${i}`}
            className="relative z-10 w-[4rem] md:w-[6.5rem] h-[4rem]  md:h-[6.5rem] p-1 cursor-pointer"
            data-idx={i}
            onClick={selectImg}
          >
            <img
              className={`carousel-img w-full h-full rounded-lg md:rounded-2xl transition relative object-cover ${
                i === indexSelected ? 'opacity-100' : 'opacity-60'
              }`}
              src={img.src}
              alt={img.alt}
            />
          </div>
        ))}
      </ScrollCarouselWrapper>
    </div>
  );
}

export default ShopCarouselSection;
