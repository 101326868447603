/* eslint-disable react/button-has-type */
import React from 'react';

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>(function Button({ className, children, type = 'button', ...props }, ref) {
  return (
    <button
      type={type}
      className={` py-2 px-4 rounded w-full bg-primary-400  font-semibold tracking-wide hover:opacity-80 ${className} ${
        className?.includes('text-') ? '' : 'text-white'
      }`}
      {...props}
      ref={ref}
    >
      {children}
    </button>
  );
});
