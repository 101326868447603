import React from 'react';
import { Vehicle } from '@spa-cars/models';

interface LowerCardProps {
  car: Vehicle;
}

export default function LowerCard({ car }: LowerCardProps) {
  return (
    <div className="flex mt-4">
      <span className="text-neutral-300 font-medium">Placa</span>
      <span className="text-neutral-100 font-medium ml-auto">{car.plate}</span>
    </div>
  );
}
