import React from 'react';
import { SelectProducts } from '@spa-cars/ui';
import { Appointment, Product } from '@spa-cars/models';
import { useNotify } from '../../hooks';
import {
  ACTIONS,
  ACTIONS_TYPE,
  PaymentState,
  TShopcartProduct,
} from './reducer';
import { VariantOption } from '../../lib/variantsOptions';

interface SelectProductsWrapperProps {
  state: Partial<PaymentState>;
  dispatch: React.Dispatch<ACTIONS_TYPE>;
  engineOils: Product[];
  hydraulicOils: Product[];
  wiperWashers: Product[];
  oilFilters: Product[];
  flushes: Product[];
  appointment: Appointment;
}

function SelectProductsWrapper({
  state,
  dispatch,
  engineOils,
  hydraulicOils,
  wiperWashers,
  flushes,
  appointment,
  oilFilters,
}: SelectProductsWrapperProps) {
  const notify = useNotify();

  const handleChangeItem = (action: ACTIONS_TYPE) => {
    let lastSelection = '';
    let quantity = 0;
    let options = [];
    let products = [];
    switch (action.type) {
      case ACTIONS.ENGINE_OIL:
        quantity = appointment.vehicle.specs.oilQuantity;
        options = state.engineOilOptions;
        products = engineOils;
        lastSelection = state.engineOil;
        break;
      case ACTIONS.WIPER_WASHER:
        quantity = 1;
        options = state.wiperWasherOptions;
        products = wiperWashers;
        lastSelection = state.wiperWasher;
        break;
      case ACTIONS.HYDRAULIC_OIL:
        quantity = 1;
        options = state.hydraulicOilOptions;
        products = hydraulicOils;
        lastSelection = state.hydraulicOil;
        break;
      case ACTIONS.OIL_FILTER:
        quantity = 1;
        options = state.oilFilterOptions;
        products = oilFilters;
        lastSelection = state.oilFilter;
        break;
      case ACTIONS.FLUSH:
        quantity = 1;
        options = state.flushOptions;
        products = flushes;
        lastSelection = state.flush;
        break;
      default:
        break;
    }

    let updatedShopcartProducts = [...state.shopcartProducts];

    // delete last selection
    updatedShopcartProducts = updatedShopcartProducts.filter(
      (item) => item?.variant?._id !== lastSelection
    );

    // add new selection to shopcart
    const wasAdded = addItemToShopcart(
      action.payload as string,
      quantity,
      options,
      products,
      updatedShopcartProducts
    );

    if (wasAdded) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch({ type: action.type as any, payload: action.payload });
    } else {
      dispatch({ type: action.type as any, payload: '' });
    }

    dispatch({ type: ACTIONS.LOADING_SHOPCART, payload: false });
  };

  /**
   * @description:add item to shopcart
   * @param variantId
   * @param quantity
   * @param options: options of that product type
   * @param products: array of that product type
   *
   */
  const addItemToShopcart = (
    variantId: string,
    quantity: number,
    options: VariantOption[],
    products: Product[],
    updatedShopcartProducts: TShopcartProduct[]
  ) => {
    dispatch({ type: ACTIONS.LOADING_SHOPCART, payload: true });
    // get product option
    const _productOption = options.find(
      (variant) => variant?.variantId === variantId
    );
    // get product
    const _product = products.find(
      (product) => product?._id === _productOption?.productId
    );
    // get variant to validate stock
    const _variant = _product?.variants.find(
      (variant) => variant?._id === _productOption?.variantId
    );
    // there is stock
    if (_variant?.stock > quantity) {
      // add to shop cart
      const _item = {
        product: _product,
        variant: _variant,
        quantity,
      };
      const newShopcartProducts = [...updatedShopcartProducts, _item];
      dispatch({
        type: ACTIONS.SHOPCART_PRODUCTS,
        payload: [...newShopcartProducts],
      });
      return true;
    }
    notify('Stock insuficiente para el producto seleccionado', 'error');
    return false;
  };

  return <SelectProducts state={state} handleChangeItem={handleChangeItem} />;
}

export default SelectProductsWrapper;
