import React from 'react';
import { Modal } from '@spa-cars/ui';
import { Multimedia } from '@spa-cars/models';

interface StepPhotoModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  img: Multimedia;
}

function StepPhotoModal({ isOpen, setIsOpen, img }: StepPhotoModal) {
  return (
    <Modal isOpen={isOpen} setOpen={setIsOpen} className=" overflow-auto">
      <div className="bg-white p-8  rounded-lg relative flex ">
        <img
          className="w-auto object-cover object-center mx-auto"
          src={img?.src}
          alt={img?.alt}
        />
      </div>
    </Modal>
  );
}

export default StepPhotoModal;
