import React from 'react';
import { useRouter } from 'next/router';
import { CalendarIcon } from '@spa-cars/ui';
import { Appointment, Pagination } from '@spa-cars/models';
import { CTACard } from '../../shared';
import AppointmentRecord from './AppointmentRecord';
import TodayAppointments from './TodayAppointments';
import UpcomingAppointments from './UpcomingAppointments';

interface MyAppointmentsProps {
  upcomingAppointments: {
    todaysAppointments: Appointment[];
    nextAppointments: Appointment[];
  };
  appointmentRecord: Pagination<Appointment>;
}

function MyAppointments({
  upcomingAppointments,
  appointmentRecord,
}: MyAppointmentsProps) {
  const router = useRouter();

  return (
    <main className="w-full max-w-[1440px] m-auto px-5 pb-10">
      <section>
        <CTACard
          text="Si aún no tienes vehículos ingresados, regístralo ahora y agenda tu cita"
          icon={<CalendarIcon className="w-5 h-5 mr-2 text-white" />}
          actionTitle=" Agenda tu cita ahora mismo"
          action={() => {
            router.push('/appointment/create');
          }}
        />
      </section>
      <section className="w-full flex flex-wrap">
        <div className="w-full sm:min-w-[22rem]  lg:w-5/12 xl:w-1/3">
          <TodayAppointments
            todayAppointments={upcomingAppointments.todaysAppointments}
          />
        </div>
        <div className="w-full  lg:w-7/12 xl:w-2/3">
          <UpcomingAppointments
            upcomingAppointments={upcomingAppointments.nextAppointments}
          />
        </div>
      </section>
      <section className="w-full">
        <AppointmentRecord appointmentRecord={appointmentRecord} />
      </section>
    </main>
  );
}

export default MyAppointments;
