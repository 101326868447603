/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import StepNumber from './StepNumber';
import StepTitle from './StepTitle';

interface StepLayoutProps {
  step: number;
  title: string;
  description: string;
  children: React.ReactNode;
  loading?: boolean;
  showDescription?: boolean;
  className?: string;
  onClick?: () => void;
}

export default function StepLayout({
  step,
  title,
  description,
  children,
  loading = false,
  showDescription = true,
  className = '',
  onClick,
}: StepLayoutProps) {
  return (
    <div className={`w-full flex  ${className || 'my-8'}`}>
      <div
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        <StepNumber step={step} />
      </div>
      <div className="ml-5 w-full md:pr-5">
        <div
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          <StepTitle
            title={title}
            description={description}
            loading={loading}
            showDescription={showDescription}
          />
        </div>
        {children}
      </div>
    </div>
  );
}
