import React from 'react';
import ShopServiceCard from './ShopServiceCard';

interface ShopServicesProps {
  // temporal
  services: {
    name: string;
    icon: React.ReactNode;
  }[];
}

function ShopServices({ services }: ShopServicesProps) {
  return (
    <div className="w-full mt-5">
      <span className="text-primary-300 font-semibold text-lg">Servicios</span>
      <div className=" flex w-full mt-5 flex-wrap justify-center md:justify-start ">
        {services.map((item, i) => (
          <ShopServiceCard
            key={`shop-service-${i}`}
            name={item.name}
            icon={item.icon}
          />
        ))}
        <div />
      </div>
    </div>
  );
}

export default ShopServices;
