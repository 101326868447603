import React from 'react';
import Head from 'next/head';
import logo from '../../public/images/logo.png';

interface SeoHeadProps {
  title?: string;
  description?: string;
  canonicalUrl?: string;
  image?: string;
}

function SeoHead({ title, description, canonicalUrl, image }: SeoHeadProps) {
  const defaults = {
    title: 'Spacars',
    description: 'Spacars',
    image: logo.src,
    // FIXME
    canonicalUrl: 'www.spacars.com',
    globalMeta: {
      siteName: 'Spacars',
    },
  };
  return (
    <Head>
      {/*
        	Fundamental head elements important for SEO.
    	*/}
      <title>{title} </title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl || defaults.canonicalUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={title || defaults.title} />
      <meta
        itemProp="description"
        content={description || defaults.description}
      />
      <meta itemProp="image" content={image || defaults.image} />
      {/*
        	Open graph meta tags.
    	*/}
      <meta property="og:site_name" content={defaults.globalMeta.siteName} />
      <meta property="og:title" content={title || defaults.title} />
      <meta
        property="og:description"
        content={description || defaults.description}
      />
      <meta property="og:image" content={image || defaults.image} />
      <meta property="og:url" content={title || defaults.title} />
      <meta property="og:type" content="website" />
      {/*
        	twitter meta tags.
    	*/}
      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:title" content={title || defaults.title} />
      <meta
        name="twitter:description"
        content={description || defaults.description}
      />
      <meta name="twitter:image" content={image || defaults.image} />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
}

export default SeoHead;
