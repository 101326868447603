import React from 'react';
import { Alert, Button } from '@spa-cars/ui';

interface ConfirmDeleteAccountStep1Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

function ConfirmDeleteAccountStep1({
  setIsOpen,
  setStep,
}: ConfirmDeleteAccountStep1Props) {
  return (
    <>
      <Alert
        title="¿Estás seguro que deseas eliminar tu cuenta?"
        text="No podrás revertir esta acción."
        show
      />
      <div className="flex space-x-5 justify-center">
        <div>
          <Button onClick={() => setIsOpen(false)}>Volver</Button>
        </div>
        <div>
          <Button className="bg-danger-100" onClick={() => setStep(2)}>
            Eliminar cuenta
          </Button>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeleteAccountStep1;
