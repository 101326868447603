import { gql } from '@apollo/client';
import {
  APPOINTMENT_FRAGMENT,
  DISCOUNT_FRAGMENT,
  ORDER_FRAGMENT,
  ORDER_PRODUCT_FRAGMENT,
  SHOPCART_FRAGMENT,
  USER_FRAGMENT,
  VEHICLE_FRAGMENT,
} from './fragments';

// auth-----------------------------
export const SIGN_UP = gql`
  mutation SIGN_UP($data: SignUpInput!) {
    signUp(data: $data) {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput!) {
    signIn(data: $data) {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;

// User------------------------------------------------------
export const UPDATE_USER = gql`
  mutation UPDATE_USER(
    $record: UpdateOneUserInput!
    $filter: FilterUpdateOneUserInput
  ) {
    updateUser(record: $record, filter: $filter) {
      record {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      success
      err
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($data: DeleteUserInput) {
    deleteUser(data: $data) {
      success
    }
  }
`;

// -------------------------------- s3
export const SIGN_S3 = gql`
  mutation SIGN_S3($data: SignS3Input!) {
    signS3(data: $data) {
      signedRequest
      url
    }
  }
`;

// -------------------------------- Vehicle
export const CREATE_VEHICLE = gql`
  mutation CREATE_VEHICLE($data: CreateVehicleInput!) {
    createVehicle(data: $data) {
      ...VehicleFragment
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const UPDATE_VEHICLE = gql`
  mutation UPDATE_VEHICLE(
    $record: UpdateOneVehicleInput!
    $filter: FilterUpdateOneVehicleInput
  ) {
    updateVehicle(record: $record, filter: $filter) {
      recordId
      record {
        ...VehicleFragment
      }
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const UNTANGLE_VEHICLE = gql`
  mutation UNTANGLE_VEHICLE($data: UntangleVehicleInput!) {
    untangleVehicle(data: $data) {
      ...VehicleFragment
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const LINK_VEHICLE = gql`
  mutation LINK_VEHICLE($data: LinkVehicleInput) {
    linkVehicle(data: $data) {
      ...VehicleFragment
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const VERIFY_VEHICLE_OWNER = gql`
  mutation VerifyVehicleOwner($data: VerifyVehicleOwnerInput) {
    verifyVehicleOwner(data: $data) {
      verified
    }
  }
`;

// -------------------------------- Appointment
export const CREATE_APPOINTMENT = gql`
  mutation CREATE_APPOINTMENT($data: CreateAppointmentInput) {
    createAppointment(data: $data) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const CREATE_NEXT_BLOCK_APPOINTMENT = gql`
  mutation CREATE_NEXT_BLOCK_APPOINTMENT($data: NextBlockInput) {
    createNextBlockAppointment(data: $data) {
      success
    }
  }
`;

export const UPDATE_APPOINTMENT = gql`
  mutation UPDATE_APPOINTMENT(
    $record: UpdateOneAppointmentInput!
    $filter: FilterUpdateOneAppointmentInput
  ) {
    updateAppointment(record: $record, filter: $filter) {
      recordId
    }
  }
`;

// ---------------------------------- shopcart

export const ADD_TO_SHOPCART = gql`
  mutation ADD_TO_SHOPCART($data: AddToShopcartInput) {
    addToShopcart(data: $data) {
      shopcart {
        ...ShopcartFragment
      }
      tally {
        subtotal
        total
        discountTotal
        totalTax
      }
    }
  }
  ${SHOPCART_FRAGMENT}
`;

export const DELETE_FROM_SHOPCART = gql`
  mutation DELETE_FROM_SHOPCART($data: DeleteFromShopcart) {
    deleteFromShopcart(data: $data) {
      shopcart {
        ...ShopcartFragment
      }
      tally {
        subtotal
        total
        discountTotal
        totalTax
      }
    }
  }

  ${SHOPCART_FRAGMENT}
`;

// ---------------------------------- order

export const CREATE_ORDER = gql`
  mutation CREATE_ORDER($data: CreateOrderInput) {
    createOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const UPDATE_ORDER_PRODUCT = gql`
  mutation UPDATE_ORDER_PRODUCT(
    $record: UpdateOneOrderProductInput!
    $filter: FilterUpdateOneOrderProductInput
  ) {
    updateOrderProduct(filter: $filter, record: $record) {
      ...OrderProductFragment
    }
  }

  ${ORDER_PRODUCT_FRAGMENT}
`;

export const APPLY_DISCOUNT = gql`
  mutation APPLY_DISCOUNT($data: ApplyDiscountInput) {
    applyDiscount(data: $data) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

// Stripe ------------------------------------------------------
export const CREATE_PAYMENT_INTENT_STRIPE = gql`
  mutation CREATE_PAYMENT_INTENT_STRIPE(
    $data: CreatePaymentIntentStripeInput!
  ) {
    createPaymentIntentStripe(data: $data) {
      data
    }
  }
`;

export const CREATE_PAYMENT_INTENT_LUKA = gql`
  mutation CREATE_PAYMENT_INTENT_LUKA($data: CreatePaymentIntentLukaInput) {
    createPaymentIntentLuka(data: $data) {
      url
    }
  }
`;
