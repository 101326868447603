import React from 'react';
import { CarsCarousel } from '@spa-cars/ui';
import { Vehicle } from '@spa-cars/models';
import NewCarModalWrapper from './NewCarModal/NewCarModalWrapper';

interface CarsCarouselProps {
  cars: Vehicle[];
  carSelected: string;
  setCar: React.Dispatch<React.SetStateAction<string>>;
  setCars: React.Dispatch<React.SetStateAction<Vehicle[]>>;
}

function MyCars({ cars, carSelected, setCar, setCars }: CarsCarouselProps) {
  const [newCarModalIsOpen, setNewCarModalIsOpen] = React.useState(false);
  return (
    <div>
      <CarsCarousel
        myCars={cars.length > 0 ? cars : []}
        carSelected={carSelected}
        setCar={setCar}
        setNewCarModalIsOpen={setNewCarModalIsOpen}
      />
      <NewCarModalWrapper
        myCars={cars.length > 0 ? cars : []}
        isOpen={newCarModalIsOpen}
        setIsOpen={setNewCarModalIsOpen}
        setMyCars={setCars}
        setCar={setCar}
      />
    </div>
  );
}

export default MyCars;
