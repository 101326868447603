import React from 'react';
import Link from 'next/link';
import { useRefDimensions, CardCarouselWrapper } from '@spa-cars/ui';
import { Appointment } from '@spa-cars/models';
import isoTypo from '../../../public/images/isotypo-yellow.png';
import AppointmentCard from './AppointmentCard';

interface TodayAppointmentsProp {
  todayAppointments: Appointment[];
}

function TodayAppointments({ todayAppointments }: TodayAppointmentsProp) {
  // carousel cards refs
  const refs = todayAppointments?.reduce((acc, value, index) => {
    acc[index] = React.createRef<HTMLDivElement>();
    return acc;
  }, []);

  const { width: cardWidth } = useRefDimensions(
    refs?.length > 0 ? refs[0] : null
  );

  return (
    <div className="w-full flex flex-col md:pr-4">
      <div className="w-full flex my-10 pr-5">
        <img
          className="w-8 h-6 mr-5 mt-1"
          src={isoTypo.src}
          alt="spa-car-logo"
        />
        <div className="pb-2">
          <h2 className="text-xl md:text-2xl text-neutral-400 font-bold ">
            Hoy
          </h2>
          <span className="text-neutral-300 font-light text-md  ">
            Estas son las reservas agendadas para hoy
          </span>
        </div>
      </div>
      <div className="flex w-full px-5 sm:px-12">
        {todayAppointments?.length === 0 ? (
          <Link href="/appointment/create" className="flex w-full">
            <div
              ref={refs[0]}
              className="my-2 mt-4 mx-auto w-[17.5rem] sm:w-[20rem] h-[250px] bg-primary-300 rounded-lg shadow-sm  flex flex-col justify-center items-center"
            >
              {' '}
              <span className="text-7xl text-text-white">+</span>
              <span className="text-2xl text-text-white">Agendar</span>
            </div>
          </Link>
        ) : null}
        {todayAppointments?.length === 1 ? (
          <AppointmentCard
            className="mt-5"
            appointment={todayAppointments[0]}
          />
        ) : null}
        {todayAppointments?.length > 1 ? (
          <CardCarouselWrapper cardDimension={cardWidth} elementsToShow={1}>
            {todayAppointments.map((appointment, i) => (
              <div key={`today-appointments-${i}`} ref={refs[i]}>
                <AppointmentCard
                  key={appointment._id}
                  className="mx-3   w-[17.5rem] sm:w-[22rem]"
                  appointment={appointment}
                />
              </div>
            ))}
          </CardCarouselWrapper>
        ) : null}
      </div>
    </div>
  );
}

export default TodayAppointments;
