import React from 'react';
import { Button, Modal } from '@spa-cars/ui';
import ConfirmDeleteAccountStep1 from './ConfirmDeleteAccountStep1';
import ConfirmDeleteAccountStep2 from './ConfirmDeleteAccountStep2';

interface ConfirmDeleteAccountModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ConfirmDeleteAccountModal({
  isOpen,
  setIsOpen,
}: ConfirmDeleteAccountModalProps) {
  const [step, setStep] = React.useState(1);

  return (
    <Modal
      isOpen={isOpen}
      setOpen={setIsOpen}
      staticMode
      className="max-w-[40rem]"
    >
      <div className="w-full flex flex-col px-7 md:px-10 py-8 rounded-lg bg-white flex-wrap  space-y-10  ">
        {step === 1 ? (
          <ConfirmDeleteAccountStep1 setIsOpen={setIsOpen} setStep={setStep} />
        ) : null}
        {step === 2 ? (
          <ConfirmDeleteAccountStep2 setIsOpen={setIsOpen} setStep={setStep} />
        ) : null}
      </div>
    </Modal>
  );
}

export default ConfirmDeleteAccountModal;
