import React from 'react';
import dayjs from 'dayjs';
import { BuildIcon, CarIcon, formatCurrency } from '@spa-cars/ui';
import {
  Appointment,
  User,
  Vehicle,
  VehicleBrand,
  VehicleModel,
} from '@spa-cars/models';

interface AppointmentSummaryProps {
  mechanic: User;
  vehicle: Vehicle;
  appointment: Appointment;
}

function AppointmentSummary({
  mechanic,
  appointment,
}: AppointmentSummaryProps) {
  return (
    <div className="w-full  max-w-sm bg-white p-4 flex flex-col rounded-lg shadow xl:max-h-[80vh] xl:overflow-auto  sm:mx-0  xl:mt-[-40px]">
      <div className="w-full">
        <h2 className="text-xl md:text-2xl text-primary-400 font-semibold ">
          Reserva #{appointment?.reservationNumber ?? ''}
        </h2>
      </div>
      <hr className=" my-3" />
      <div className="w-full flex flex-col">
        <span className="text-primary-300 font-semibold text-sm">Sede</span>
        <span className="text-neutral-400 ">
          {appointment?.location?.name ?? ''}
        </span>
      </div>
      <hr className=" my-3" />
      <div className="w-full flex flex-col">
        <span className="text-primary-300 font-semibold text-sm">Fecha</span>
        <span className="text-neutral-400 ">
          {dayjs(appointment?.date).format('LLLL') ?? ''}
        </span>
      </div>
      <hr className=" my-3" />
      <div className="w-full flex flex-col">
        <span className="text-primary-300 font-semibold text-sm">
          Operador{' '}
        </span>
        <span className="text-neutral-400  flex items-center ">
          <BuildIcon className="w-5 h-5 text-neutral-100 mr-2" />{' '}
          {mechanic?.firstName} {mechanic?.lastName}
        </span>
      </div>
      <hr className=" my-3" />
      <div className="w-full flex flex-col">
        <span className="text-primary-300 font-semibold text-sm">
          Vehículo{' '}
        </span>
        <span className="text-neutral-400  flex items-center ">
          <CarIcon className="w-5 h-5 text-neutral-100 mr-2" />{' '}
          {(appointment?.vehicle.model as VehicleModel)?.name ?? ''},{' '}
          {(appointment?.vehicle.brand as VehicleBrand)?.name ?? ''}
          <span className="ml-auto text-neutral-100">2022</span>
        </span>
        <span className="text-neutral-100">
          {appointment?.vehicle?.plate ?? ''}
        </span>
      </div>
      <hr className=" my-3" />
      <div className="w-full flex flex-col">
        <span className="text-primary-300 font-semibold text-sm">
          Datos personales
        </span>
        <span className="text-neutral-400 ">
          {appointment?.driver?.name ?? ''}
        </span>
        <span className="text-neutral-400 ">
          {appointment.driver.dniType}-{appointment?.driver?.dni ?? ''}
        </span>
        <span className="text-neutral-400 ">{appointment?.user?.email}</span>
        <span className="text-neutral-400 ">
          +{appointment?.driver?.phone?.code ?? appointment?.user?.phone?.code}-
          {appointment?.driver?.phone?.phone ?? appointment?.user?.phone?.phone}{' '}
        </span>
      </div>
      <div className="w-full flex flex-col">
        <span className="text-primary-300 font-semibold text-sm">
          Método de pago
        </span>
        <span className="text-neutral-400 ">
          {appointment?.order?.paymentInfo?.gateway}
        </span>
      </div>
      <hr className=" my-3" />
      <div className="w-full flex text-lg  ">
        <span className="text-primary-300 font-semibold ">Total</span>
        <span className="ml-auto text-primary-300 font-semibold">
          ${formatCurrency((appointment?.order?.total ?? 0).toFixed(2))}
        </span>
      </div>
    </div>
  );
}

export default AppointmentSummary;
