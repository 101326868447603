/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import isoTypo from '../../public/images/isotypo-white.png';

interface CTACardProps {
  text: string;
  icon: React.ReactNode;
  actionTitle: string;
  action: () => void;
}

export default function CTACard({
  text,
  icon,
  actionTitle,
  action,
}: CTACardProps) {
  return (
    <div className="w-full sm:w-[28rem] h-40 bg-primary-300 rounded-lg flex flex-col shadow-md">
      <div className="p-4 flex w-full items-center">
        <div className="mr-2">
          <img className="w-7 mr-5 " src={isoTypo.src} alt="spa-car-logo" />
        </div>
        <span className="text-text-white text-lg font-light tracking-wider">
          {text}
        </span>{' '}
      </div>
      <div className="mt-auto ml-auto rounded-tl-lg rounded-br-lg bg-[#61C9C0] py-2 px-4  ">
        <div
          className="flex items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            action();
          }}
        >
          {icon}
          <span className=" text-text-white text-lg">{actionTitle}</span>
        </div>
      </div>
    </div>
  );
}
