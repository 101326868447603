import React from 'react';
import { Select } from '@spa-cars/ui';
import { Location } from '@spa-cars/models';

interface LocationSelectProps {
  locations: Location[];
  shop: string;
  setShop: React.Dispatch<React.SetStateAction<string>>;
}
function LocationSelect({ locations, shop, setShop }: LocationSelectProps) {
  return (
    <div className="w-3/4 md:mt-14">
      <h4 className="font-semibold text-lg text-primary-300 mt-6">
        Seleccione una sede
      </h4>

      <Select
        className="border-neutral-100 mb-10"
        value={shop}
        onChange={(e) => {
          e.preventDefault();
          setShop(e.target.value);
        }}
      >
        <option value="" disabled>
          Seleccione una sede{' '}
        </option>
        {locations.map((loc) => (
          <option key={loc._id} value={loc._id}>
            {loc.name}
          </option>
        ))}
      </Select>
    </div>
  );
}

export default LocationSelect;
