import React from 'react';
import { useQuery } from '@apollo/client';
import { Client, Shopcart, User } from '@spa-cars/models';
import { CURRENT_USER } from '../graphql/queries';
import { UserContext } from './UserContext';

type TShopCartContext = {
  shopCart: Shopcart;
  setShopCart: React.Dispatch<React.SetStateAction<Shopcart>>;
};

export const ShopCartContext = React.createContext<TShopCartContext>({
  shopCart: null,
  setShopCart: null,
});

interface ShopCartContextProviderProps {
  children: React.ReactNode;
}

export function ShopCartContextProvider({
  children,
}: ShopCartContextProviderProps) {
  const [shopCart, setShopCart] = React.useState<Shopcart>(null);
  const { user } = React.useContext(UserContext);
  const { data, loading, error } = useQuery<{ currentUser: { user: User } }>(
    CURRENT_USER,
    {
      fetchPolicy: 'network-only',
    }
  );

  React.useEffect(
    function syncShopCart() {
      const _shopCart = (user?.client as Client)?.shopCart as Shopcart;
      if (data && !loading) {
        setShopCart(
          _shopCart ??
            ((data?.currentUser?.user.client as Client)?.shopCart as Shopcart)
        );
      }
      if (error) {
        setShopCart(_shopCart);
      }
    },
    [data, loading, user, error]
  );
  const value = React.useMemo(() => ({ shopCart, setShopCart }), [shopCart]);
  return (
    <ShopCartContext.Provider value={value}>
      {loading ? null : children}
    </ShopCartContext.Provider>
  );
}
