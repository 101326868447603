import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Vehicle } from '@spa-cars/models';
import { GET_VEHICLE_BY_PLATE } from '../../../../../../graphql/queries';
import { useNotify } from '../../../../../../hooks';

type TUseVerifyPlate = {
  myCars?: Vehicle[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useVerifyPlate({ myCars, setIsOpen }: TUseVerifyPlate) {
  // React hook
  const notify = useNotify();
  // Apollo lazy query
  const [getVehicleByPlate] = useLazyQuery<{
    vehicle: Vehicle;
  }>(GET_VEHICLE_BY_PLATE, {
    fetchPolicy: 'network-only',
  });

  const verifyVehicle = async (plate: string): Promise<Partial<Vehicle>> => {
    // verify if the car is in my vehicles
    const isMyCar = isInMyVehicles(plate);
    if (isMyCar) {
      notify('Ya posee un carro con esa placa', 'error');
      setIsOpen(false);
      return null;
    }

    // get car by plate
    const { data } = await getVehicleByPlate({
      variables: { filter: { plate } },
    });

    // return car, if it is registered, if it is not registered, return null
    if (data?.vehicle?._id) {
      return data?.vehicle;
    }

    // if the car is not registered or it doesnt have owner, the user can add it
    return null;
  };

  const isInMyVehicles = (plate: string) =>
    myCars?.some((vehicle) => vehicle.plate === plate);

  const compareToken = (userToken: string, _car: Partial<Vehicle>) =>
    userToken === _car?.token;

  return [verifyVehicle, compareToken] as const;
}
