import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Vehicle } from '@spa-cars/models';
import { FIND_VEHICLE_BY_ID } from '../../../../../../graphql/queries';

export type TInmaInfo = {
  brandCode: string;
  modelCode: string;
  brandId: string;
  modelId: string;
  versionId: string;
};

export function useLazyInma() {
  const [findInmaInfo] = useLazyQuery<{
    findVehicleById: {
      vehicle: Vehicle;
      inmaInfo: TInmaInfo;
    };
  }>(FIND_VEHICLE_BY_ID, {
    fetchPolicy: 'network-only',
  });

  return findInmaInfo;
}
