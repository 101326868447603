import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Button, ExitIcon, MenuIcon } from '@spa-cars/ui';
import { useRouter } from 'next/router';
import { SignInModal, SignUpModal } from '../auth';
import { useUser } from '../../hooks';
import logo from '../../public/images/logo.png';
import UserDropdown from './UserDropdown';
import ResetPasswordModal from '../auth/ResetPasswordModal';
import ChangePasswordModal from '../auth/ChangePasswordModal';

const navItems = [
  { label: 'Inicio', href: '/app' },
  { label: 'Reservas', href: '/appointment' },
  { label: 'Mapa', href: '/map' },
];

function Navbar() {
  const router = useRouter();
  const [user] = useUser();
  const [logged, setLogged] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [signInModalIsOpen, setSignInModalIsOpen] = React.useState(false);
  const [signUpModalIsOpen, setSignUpModalIsOpen] = React.useState(false);
  const [resetPasswordModalIsOpen, setResetPasswordModalIsOpen] =
    React.useState(false);
  const [changePasswordModalIsOpen, setChangePasswordModalIsOpen] =
    React.useState(false);

  React.useEffect(() => {
    if (user) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, [user]);

  React.useEffect(() => {
    if (router.pathname.includes('reset-password')) {
      setChangePasswordModalIsOpen(true);
    }
  }, []);

  return (
    <>
      <nav
        className={`w-full absolute z-50 bg-white flex  px-5 sm:px-10 py-5 overflow-hidden md:overflow-visible ${
          isOpen ? 'h-44' : 'h-16 md:h-20'
        } ${logged ? ' flex-col md:flex-row' : ''} `}
      >
        {/* logo */}
        <div className="w-36 md:w-[14rem] ">
          <Image
            src={logo}
            alt="logo-spacars"
            layout="responsive"
            height={50}
            width={250}
          />
        </div>
        {/* hamburger menu */}
        {logged ? (
          <div className="ml-auto md:hidden w-auto absolute right-5 mt-1 ">
            <button
              className="text-font-black dark:text-font-white hover:text-primary-300 focus:outline-none"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
            >
              {isOpen ? <ExitIcon /> : <MenuIcon />}
            </button>
          </div>
        ) : null}
        {logged ? (
          <div className="flex flex-col md:flex-row items-center w-full md:w-auto mt-2 md:mt-0 md:mr-12 ml-auto">
            {/* logged options */}
            {navItems.map(({ label, href }) => (
              <Link
                href={href}
                key={`navitem-${href}`}
                className={`mx-5 my-1 md:my-0  hover:text-primary-300  transition-colors ${
                  router.pathname === href
                    ? 'text-primary-300'
                    : 'text-neutral-200'
                }`}
              >
                {label}
              </Link>
            ))}
          </div>
        ) : (
          <div className="ml-auto my-auto  lg:mr-6 flex items-center ">
            {/* unlogged options */}
            <button
              type="button"
              className="whitespace-nowrap mr-5  text-sm md:text-md sm:text-md text-primary-400 font-semibold flex "
            >
              Descarga la app
            </button>
            <Link
              href="https://docs.google.com/forms/d/e/1FAIpQLSep_M5wkB9vWwE_dHqROpEQcLUuY22tGQP--tTE8Vr4njn-Mg/viewform"
              passHref
              legacyBehavior
            >
              <Button
                className="text-sm md:text-md sm:text-md md:px-10 bg-primary-400 font-semibold "
                onClick={(e) => {
                  e.preventDefault();

                  setSignInModalIsOpen(true);
                }}
              >
                Ingresa
              </Button>
            </Link>
          </div>
        )}
      </nav>
      {!isOpen && logged ? <UserDropdown /> : null}
      <SignInModal
        isOpen={signInModalIsOpen}
        setIsOpen={setSignInModalIsOpen}
        setSignUpModalIsOpen={setSignUpModalIsOpen}
        setResetPasswordModalIsOpen={setResetPasswordModalIsOpen}
      />
      <SignUpModal
        isOpen={signUpModalIsOpen}
        setIsOpen={setSignUpModalIsOpen}
      />
      <ResetPasswordModal
        isOpen={resetPasswordModalIsOpen}
        setIsOpen={setResetPasswordModalIsOpen}
        setSignUpModalIsOpen={setSignUpModalIsOpen}
        setSignInModalIsOpen={setSignInModalIsOpen}
      />
      <ChangePasswordModal
        isOpen={changePasswordModalIsOpen}
        setIsOpen={setChangePasswordModalIsOpen}
        setSignInModalIsOpen={setSignInModalIsOpen}
      />
    </>
  );
}

export default Navbar;
