import React from 'react';
import { SpinnerIcon } from '@spa-cars/ui';
import { PaymentState } from '../reducer';

interface ConfirmButtonProps {
  confirmForm: () => void;
  state: Partial<PaymentState>;
}

function ConfirmButton({ confirmForm, state }: ConfirmButtonProps) {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        confirmForm();
      }}
      disabled={state.loadingShopCart}
      className="mt-4 py-2 px-4  rounded w-full bg-neutral-50 font-semibold  text-primary-400 border-2 border-primary-300 hover:text-white hover:bg-primary-300 transition-colors"
    >
      {state.loadingShopCart ? (
        <SpinnerIcon className="w-6 h-6 text-primary-300 hover:text-white animate-spin  fill-primary-300 hover:fill-white mx-auto" />
      ) : (
        'Confirmar orden'
      )}
    </button>
  );
}

export default ConfirmButton;
