import React from 'react';

interface EmptyProps {
  title: string;
  className: string;
}

function Empty({ className, title }: EmptyProps) {
  return (
    <div className="w-full m flex flex-col my-5">
      <span className="m-auto text-primary-400 font-semibold text-2xl">
        {title}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 892.91033 584.15813"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={`mx-auto ${className}`}
      >
        <path
          d="M887,583.15813H0v-2H887c.55228,0,1,.44771,1,1h0c0,.55229-.44771,1-1,1Z"
          fill="#3f3d56"
        />
        <g>
          <path
            d="M218.96458,581.15813h-55.57139c-8.30576,0-15.06327-6.75751-15.06327-15.06409v-163.11224c0-8.30658,6.75751-15.06409,15.06327-15.06409h55.57139c8.30576,0,15.06327,6.75751,15.06327,15.06409v163.11224c0,8.30658-6.75751,15.06409-15.06327,15.06409Z"
            fill="#3f3d56"
          />
          <path
            d="M763.39843,581.47778h-55.57139c-8.30576,0-15.06327-6.75751-15.06327-15.06409v-163.11224c0-8.30658,6.75751-15.06409,15.06327-15.06409h55.57139c8.30576,0,15.06327,6.75751,15.06327,15.06409v163.11224c0,8.30658-6.75751,15.06409-15.06327,15.06409Z"
            fill="#3f3d56"
          />
          <path
            d="M232.13882,175.85665l-81.83264,116.22607c-9.87473,14.02498-13.78987,31.39132-10.88821,48.29667l24.8708,144.89945c5.34189,31.1223,32.3273,53.87051,63.90471,53.87051h480.87558c34.27099,0,62.62374-26.66996,64.71804-60.8769l9.59799-156.76719c1.14261-18.66259-5.82682-36.90997-19.11964-50.05908l-114.78647-113.54553c-12.59955-12.46334-29.75157-19.22619-47.46661-18.71556l-318.72526,9.1872c-20.42807,.58884-39.38297,10.77419-51.1483,27.48436Z"
            fill="#2ea89d"
          />
          <path
            d="M244.60387,196.48965l-15.64649,29.25608c-9.97036,18.64275,4.56661,40.65808,26.68477,40.4124l391.72693-4.35119c24.06147-.26727,37.61304-26.2031,23.22255-44.44476l-24.66548-31.26643c-5.68817-7.21043-14.7072-11.38724-24.21686-11.21509l-351.41495,6.36154c-10.89609,.19725-20.77813,6.0623-25.69046,15.24745Z"
            fill="#fff"
          />
          <path
            d="M734.95471,257.54116c-2.53119,0-4.99593-.93863-6.88878-2.6518-2.14475-1.94127-3.37547-4.7145-3.37547-7.60753l.00082-6.43095,1.28898-29.63753c.23958-5.50381,4.74158-9.81463,10.25031-9.81463h40.7797c3.7972,0,7.26621,2.08075,9.05404,5.4316l4.85891,9.11066c1.53595,2.88154,1.6221,6.22912,.2363,9.1845-1.3858,2.95702-4.01463,5.03121-7.21123,5.69088l-30.74436,6.36203c-3.17856,.65639-5.68431,3.01118-6.53844,6.14543l-1.81573,6.65741c-1.11094,4.07124-4.67922,7.08898-8.87846,7.50907-.33886,.03446-.67854,.05087-1.01658,.05087Z"
            fill="#2ea89d"
          />
          <path
            d="M184.09893,257.49029c-4.19924-.42009-7.76752-3.43783-8.87846-7.50907l-1.81573-6.65741c-.85412-3.13425-3.35988-5.48904-6.53844-6.14543l-30.74436-6.36203c-3.19661-.65967-5.82544-2.73385-7.21123-5.69088-1.3858-2.95538-1.29965-6.30296,.2363-9.1845l4.85891-9.11066c1.78783-3.35086,5.25684-5.4316,9.05404-5.4316h40.7797c5.50873,0,10.01073,4.31082,10.25031,9.81463l1.28898,29.63753,.00082,6.43095c0,2.89303-1.23073,5.66626-3.37547,7.60753-1.89286,1.71317-4.35759,2.6518-6.88878,2.6518-.33804,0-.67772-.01641-1.01658-.05087Z"
            fill="#2ea89d"
          />
          <circle cx="240.74925" cy="344.22858" r="40.32843" fill="#fff" />
          <circle cx="705.36641" cy="345.06876" r="40.32843" fill="#fff" />
        </g>
        <g>
          <g>
            <path
              d="M187.06823,128.77693c3.71371,2.07874,6.87549,5.01631,9.22106,8.56759l26.48096,15.74671,44.99378,21.07444s10.61101-37.72078,23.28825-40.12999c12.67724-2.40921,33.5925,25.54663,33.5925,25.54663,0,0-36.45931,59.03377-52.34125,52.98082-15.88194-6.05296-51.36709-35.44894-51.36709-35.44894l-4.63539-3.76697-29.48273-19.61344c-4.24929-.25843-8.37472-1.53117-12.03136-3.71126-9.22116-5.32685-13.94699-14.40273-10.55602-20.27019,3.39106-5.86797,13.61354-6.30538,22.8373-.97538Z"
              fill="#a0616a"
            />
            <path
              d="M274.76795,145.14932l23.00932-27.08702,13.90084,2.00262c17.05049,2.45637,26.52918,21.05455,18.49758,36.29418l-8.00881,15.19639-47.39893-26.40616Z"
              fill="#e6e6e6"
            />
          </g>
          <path
            d="M199.94759,180.6123l-40.49781-55.82875c-.60058-.82422-.45375-1.97934,.33363-2.62947l9.71497-8.02212c.42125-.34782,.97316-.501,1.5135-.42134,.53947,.08058,1.025,.38537,1.32949,.84148l40.23752,60.38863c.33162,.49564,.41527,1.10557,.23069,1.67316-.18545,.56851-.61375,1.0124-1.17576,1.21874l-9.4534,3.45996c-.69498,.25501-1.47094,.08368-1.997-.41256-.08568-.08082-.16533-.16989-.23583-.26773Z"
            fill="#3f3d56"
          />
          <path
            d="M203.16706,177.19934l-41.91349-54.328c-.62128-.80228-.57834-1.84726,.09718-2.3788l8.33468-6.55887c.3614-.28437,.8592-.38019,1.365-.26392,.50507,.11704,.9783,.43488,1.29635,.87661l42.03913,58.47874c.34631,.48007,.4726,1.0448,.34759,1.54924-.12574,.50521-.48595,.87691-.9888,1.02054l-8.45933,2.40615c-.62185,.17748-1.35278-.04158-1.87884-.53782-.08568-.08082-.16645-.1687-.23946-.26387Z"
            fill="#e6e6e6"
          />
          <g>
            <polygon
              points="186.44365 516.9023 168.21967 556.22775 138.4858 542.79955 161.50557 513.06567 186.44365 516.9023"
              fill="#a0616a"
            />
            <path
              d="M155.75063,543.7587l22.06061-2.87747s15.34651,36.44797,3.83663,38.36629-101.67066,10.55073-101.67066-.95916c0-11.50989,37.40713-25.89724,37.40713-25.89724l25.89724-18.22399,12.46904,9.59157Z"
              fill="#2f2e41"
            />
          </g>
          <path
            d="M329.35807,263.68482s26.8564,40.2846-30.69303,65.22268l-61.38606,88.24246-50.83533,110.30307-30.69303-11.50989,53.7128-166.89334,30.69303-74.81426,14.38736-23.01977,74.81426,12.46904Z"
            fill="#2f2e41"
          />
          <g>
            <g>
              <polygon
                points="115.95946 412.13218 103.74339 453.7179 72.35236 444.82842 90.72822 412.02079 115.95946 412.13218"
                fill="#a0616a"
              />
              <path
                d="M89.56951,443.22718l21.3937-6.1041s20.5613,33.7817,9.46096,37.3789c-11.10034,3.5972-98.99741,25.45099-100.69733,14.06733-1.69992-11.38366,33.17208-31.13798,33.17208-31.13798l22.9217-21.84895,13.7489,7.6448Z"
                fill="#2f2e41"
              />
            </g>
            <path
              d="M270.85146,255.6295l-147.41339,31.98977c-11.37442,2.46833-20.19348,11.46121-22.43938,22.88162l-20.63274,104.91775,45.15656,14.6575,20.2803-93.67355,130.87476-5.07062-5.82612-75.70247Z"
              fill="#2f2e41"
            />
          </g>
          <g>
            <polygon
              points="307.90972 78.36957 289.68223 114.07671 338.1706 121.6205 337.64269 73.61325 307.90972 78.36957"
              fill="#a0616a"
            />
            <circle cx="318.03085" cy="59.26635" r="33.75291" fill="#a0616a" />
          </g>
          <path
            d="M339.90879,275.19471l5.5665-90.16887c4.816-21.58283,3.21227-44.10313-4.61357-64.7857l-1.91209-21.53034-44.12123,2.87747-18.70356,30.21345-17.74441,71.45721-3.83663,47.95786,85.36498,23.97893Z"
            fill="#e6e6e6"
          />
          <g>
            <path
              d="M210.79468,205.42633c4.21434-.59338,8.50924-.16907,12.52595,1.23782l30.61013-3.49568,48.57091-10.46128s-14.38736-36.44797-5.75494-46.03954c8.63241-9.59157,42.20291,0,42.20291,0,0,0,6.7141,69.05931-9.59157,73.8551-16.30567,4.79579-62.34521,2.87747-62.34521,2.87747l-5.96991-.19249-35.34012,2.23491c-3.53877,2.36651-7.59294,3.85068-11.8232,4.32885-10.56431,1.34179-19.81985-3.02172-20.67246-9.74472-.85284-6.72347,7.01922-13.2596,17.58751-14.60045Z"
              fill="#a0616a"
            />
            <path
              d="M290.5122,165.37122l1.91831-35.48881,12.27697-6.82054c15.05868-8.36594,33.8615,.70004,36.69353,17.69218l2.82399,16.94392-53.7128,7.67326Z"
              fill="#e6e6e6"
            />
          </g>
          <path
            d="M344.34678,80.01132s3.14744-15.73721-6.29489-16.78636c-9.44233-1.04915-18.88466-19.9338-18.88466-19.9338,0,0-32.58166-5.68318-33.63081,7.95574-1.04915,13.63892-.64712,10.60766-.64712,10.60766,0,0-7.74606-3.26362-4.59862-16.90254,.89927-3.89683,3.08321-7.45109,5.74431-10.54042,8.55266-9.92895,21.69179-14.61957,34.71021-13.11858,5.51337,.63568,21.92811,5.86844,25.09896,9.43565,8.39318,9.44233,12.14153,38.79118-1.49739,49.28266Z"
            fill="#3f3d56"
          />
        </g>
        <path
          d="M233.06638,480.15129c-5.92122,0-11.1447-3.09768-13.98677-8.29982-2.8578-5.23134-2.62466-11.33683,.62358-16.33447l.48257,.31348-.48257-.31348,10.52729-16.19627c2.9533-4.5426,7.95095-7.25489,13.36937-7.25489h457.87688c4.66844,0,9.08575,2.03479,12.11939,5.58302l11.55255,13.51206c4.08025,4.77293,4.99259,11.28402,2.38141,16.994-2.61118,5.70999-8.13353,9.27844-14.41148,9.31328l-479.95672,2.68309h-.0955Z"
          fill="#3f3d56"
        />
        <g>
          <g>
            <path
              id="uuid-f62fe5c4-23d7-4857-9194-89a06a859d22-85"
              d="M780.88651,284.62937c8.42319,5.50907,18.09341,5.62848,21.59853,.26734,3.50513-5.36115-.48214-14.17128-8.90907-19.68118-3.33602-2.24715-7.16168-3.66272-11.15692-4.12825l-35.98417-22.89144-10.38235,17.11529,36.57629,20.7498c2.02877,3.47466,4.86033,6.4128,8.25768,8.56845Z"
              fill="#ffb6b6"
            />
            <path
              d="M678.54084,228.21838l88.27877,45.62758,7.67994-21.12032-59.87881-46.90464-3.98642-68.43014c-.48671-8.35655-4.67767-15.93104-11.49794-20.7825-6.81978-4.84951-15.3506-6.32379-23.40448-4.04305h0c-14.33715,4.0616-22.88457,19.08268-19.0529,33.48281l21.86185,82.17026Z"
              fill="#e6e6e6"
            />
          </g>
          <polygon
            points="699.86472 105.76818 703.9922 79.97141 676.1317 68.62083 672.00421 107.83192 699.86472 105.76818"
            fill="#ffb6b6"
          />
          <path
            d="M612.1293,252.55575s-7.99823,49.98892,23.99468,55.98759c31.99291,5.99867,104.97672,9.99778,104.97672,9.99778l93.97916,113.97473,18.99579-20.99534-80.98204-124.97229-64.98559-40.99091-95.97872,6.99845Z"
            fill="#2f2e41"
          />
          <path
            d="M619.76489,244.4634s-18.65274,47.06313,11.27679,59.85974c29.92953,12.7966,100.3075,32.53418,100.3075,32.53418l67.01426,131.64882,23.09827-16.3742-51.94079-139.56475-54.54493-54.11314-95.2111-13.99064Z"
            fill="#2f2e41"
          />
          <path
            d="M705.10868,101.58923l-35.99202-5.99867-24.39407,13.57805c-14.19811,20.65179-15.37163,86.15298-18.7226,110.98953l-19.86937,46.39452,62.98603-8.998,50.98869-4.99889-16.99623-110.97539,1.99956-39.99113Z"
            fill="#e6e6e6"
          />
          <g>
            <circle cx="692.82418" cy="59.85308" r="28.41106" fill="#ffb6b6" />
            <path
              d="M690.55672,21.6564c.8221,.47989,1.92329-.24618,2.18689-1.16087s-.04346-1.88586-.34797-2.78776c-.51097-1.51344-1.02195-3.02687-1.53292-4.54031-1.08718-3.22009-2.24105-6.5527-4.59562-9.00361-3.55385-3.69925-9.20174-4.64032-14.28598-3.95855-6.52916,.87553-12.9712,4.411-16.00706,10.25737-3.03587,5.84637-1.74338,14.00414,3.74223,17.65168-7.8182,8.96096-10.54351,18.94764-10.11319,30.832s13.38135,22.82184,21.82716,31.1939c1.8861-1.14333,3.60078-6.50146,2.5636-8.44795s.44887-4.20156-.83565-5.99449c-1.28452-1.79293-2.35918,1.06195-1.06056-.7208,.81945-1.12494-2.37884-3.71295-1.16078-4.38625,5.89154-3.25664,7.85103-10.6002,11.5513-16.22372,4.46321-6.78301,12.102-11.37654,20.18586-12.13861,4.45312-.4198,9.15659,.34054,12.80664,2.92581,3.65005,2.58527,6.01307,7.2051,5.16666,11.59715,2.19206-2.22575,3.28323-5.48758,2.87167-8.5843-.41156-3.09672-2.31681-5.96033-5.01413-7.53626,1.64008-5.42366,.23507-11.66246-3.57164-15.85948s-19.2488-3.48219-24.80631-2.3776"
              fill="#2f2e41"
            />
            <path
              d="M689.69367,42.39307c-7.36075,.79459-12.67542,7.17064-17.16321,13.05891-2.58666,3.39387-5.29616,7.14182-5.23123,11.40854,.06565,4.31377,2.94985,8.01377,4.32825,12.10192,2.25304,6.6822,.05719,14.63248-5.30606,19.21107,5.29949,1.00564,11.02839-2.96791,11.94325-8.28383,1.06501-6.18838-3.62607-12.16111-3.0705-18.41584,.48947-5.51049,4.83197-9.75146,8.52374-13.87164,3.69177-4.12018,7.15899-9.58756,5.46059-14.85258"
              fill="#2f2e41"
            />
          </g>
          <g>
            <path
              id="uuid-3a487c00-6027-4cb8-ba45-983efea0d6d6-86"
              d="M758.30603,289.97312c8.42319,5.50907,18.09341,5.62848,21.59853,.26734s-.48214-14.17128-8.90907-19.68118c-3.33602-2.24715-7.16168-3.66272-11.15692-4.12825l-35.98417-22.89144-10.38235,17.11529,36.57629,20.7498c2.02877,3.47466,4.86033,6.4128,8.25768,8.56845Z"
              fill="#ffb6b6"
            />
            <path
              d="M655.96037,233.56212l88.27877,45.62758,7.67994-21.12032-59.87881-46.90464-3.98642-68.43014c-.48671-8.35655-4.67767-15.93104-11.49794-20.7825-6.81978-4.84951-15.3506-6.32379-23.40448-4.04305h0c-14.33715,4.0616-22.88457,19.08268-19.0529,33.48281l21.86185,82.17026Z"
              fill="#e6e6e6"
            />
          </g>
          <path
            d="M801.54376,450.84405l2.04308,1.16748s13.9969-7.99823,20.99534,0,11.99734,27.99379,11.99734,27.99379c0,0,28.99357,23.99468,11.99734,23.99468-12.79412,0-43.15053-14.16314-57.37897-21.16646-4.542-2.2356-6.65662-7.54699-4.89012-12.29115,1.47259-3.95485,3.37716-8.10443,5.28173-9.53285,1.99956-1.49967,3.24928-4.74895,3.99911-7.62331,.67943-2.60449,3.61814-3.8776,5.95514-2.54217Z"
            fill="#2f2e41"
          />
          <path
            d="M840.53512,412.85248l2.04308,1.16748s13.9969-7.99823,20.99534,0c6.99845,7.99823,11.99734,27.99379,11.99734,27.99379,0,0,28.99357,23.99468,11.99734,23.99468-12.79412,0-43.15053-14.16314-57.37897-21.16646-4.542-2.2356-6.65662-7.54699-4.89012-12.29115,1.47259-3.95485,3.37716-8.10443,5.28173-9.53285,1.99956-1.49967,3.24928-4.74895,3.99911-7.62331,.67943-2.60449,3.61814-3.8776,5.95514-2.54217Z"
            fill="#2f2e41"
          />
        </g>
      </svg>
    </div>
  );
}

export default Empty;
