import React from 'react';
import {
  ACTIONS_TYPE,
  CarDiagnosticModal,
  CarDiagnosticState,
  CAR_DIAGNOSTIC_ACTIONS,
} from '@spa-cars/ui';
import { Vehicle, VehicleSpecs } from '@spa-cars/models';
import { useQuery } from '@apollo/client';
import { GET_VEHICLE_DEFAULT_SPECS } from '../../../graphql/queries';
import { useNotify } from '../../../hooks';

interface CarDiagnosticModalWrapperProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  state: Partial<CarDiagnosticState>;
  dispatch: React.Dispatch<ACTIONS_TYPE>;
  carSelected: Vehicle;
  setCarSpecs?: React.Dispatch<React.SetStateAction<Partial<Vehicle>>>;
}

function CarDiagnosticModalWrapper({
  isOpen,
  setIsOpen,
  state,
  dispatch,
  carSelected,
  setCarSpecs,
}: CarDiagnosticModalWrapperProps) {
  const notify = useNotify();

  const { data, loading } = useQuery<{
    getVehicleDefaultSpecs: {
      specs: VehicleSpecs;
    };
  }>(GET_VEHICLE_DEFAULT_SPECS, {
    variables: {
      data: {
        vehicleId: carSelected?._id,
      },
    },
    fetchPolicy: 'network-only',
  });

  const onSubmit = async () => {
    const defaultSpecs = data?.getVehicleDefaultSpecs?.specs;

    // validate form
    if (!state?.changeOilBy) {
      return notify('Selecciona un tipo de cambio de aceite', 'error');
    }

    if (state?.changeOilBy === 'time' && !state?.time) {
      return notify('Selecciona un tiempo', 'error');
    }

    if (state?.changeOilBy === 'km' && !state?.millage) {
      return notify('Selecciona un kilometraje', 'error');
    }

    if (state.rememberYourOil === undefined) {
      return notify('Selecciona si deseas recordar tu aceite', 'error');
    }

    if (state?.changeOilBy === 'km_time' && (!state?.time || !state?.millage)) {
      return notify('Selecciona un tiempo y un kilometraje', 'error');
    }

    if (state?.rememberYourOil) {
      if (!state?.oilViscosity) {
        return notify('Selecciona una viscosidad', 'error');
      }

      if (!state?.oilOrigin) {
        return notify('Selecciona un origen', 'error');
      }

      // if (!state.oilQuantity) {
      //   return notify('Selecciona la cantidad de litros de ', 'error');
      // }
    }

    if (
      !state?.rememberYourOil &&
      (!defaultSpecs?.oilViscosity ||
        !defaultSpecs?.oilOrigin ||
        !defaultSpecs?.oilQuantity)
    ) {
      return notify(
        'El vehiculo no tiene especificaciones de aceite, por favor ingresa las especificaciones',
        'error'
      );
    }

    if (!state?.rememberYourOil && !data?.getVehicleDefaultSpecs?.specs) {
      return notify(
        'Error al obtener las especificaciones del vehiculo',
        'error'
      );
    }

    const specs = {
      ...carSelected.specs,
      oilViscosity: state?.rememberYourOil
        ? state?.oilViscosity
        : defaultSpecs?.oilViscosity,
      oilOrigin: state?.rememberYourOil
        ? state?.oilOrigin
        : defaultSpecs?.oilOrigin,
      screenWasher: defaultSpecs?.screenWasher,
      oilFilter: defaultSpecs?.oilFilter,
      coolantColor: defaultSpecs?.coolantColor,
      oilQuantity: defaultSpecs?.oilQuantity,
    };

    const oilChangeTypeValueObj = {
      time: state?.time,
      km: state?.millage,
      km_time: `${state.time}-${state.millage}`,
      nda: 'nda',
    };

    setCarSpecs({
      oilChangeType: state?.changeOilBy,
      oilChangeTypeValue: oilChangeTypeValueObj[state?.changeOilBy],
      rememberYourOil: state?.rememberYourOil,
      specs,
    } as Partial<Vehicle>);
    notify('Diagnostico exitoso', 'success');
    dispatch({
      type: CAR_DIAGNOSTIC_ACTIONS?.RESET,
      payload: {
        rememberYourOil: state.rememberYourOil,
      },
    });

    setIsOpen(false);
  };

  return (
    <CarDiagnosticModal
      state={state}
      dispatch={dispatch}
      onSubmit={onSubmit}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      specs={data?.getVehicleDefaultSpecs?.specs}
      loading={loading}
    />
  );
}

export default CarDiagnosticModalWrapper;
