/* eslint-disable react/button-has-type */
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { SpinnerIcon } from '@spa-cars/ui';
import { Appointment, Pagination } from '@spa-cars/models';
import { GET_APPOINTMENTS_HISTORY } from '../../../graphql/queries';
import { useUser } from '../../../hooks';
import AppointmentCard from './AppointmentCard';
import isoTypo from '../../../public/images/isotypo-yellow.png';

interface AppointmentRecordProps {
  appointmentRecord: Pagination<Appointment>;
}

function AppointmentRecord({ appointmentRecord }: AppointmentRecordProps) {
  const [user] = useUser();
  const [appointments, setAppointments] = React.useState(
    appointmentRecord?.items
  );
  const [pagination, setPagination] = React.useState(
    appointmentRecord?.pageInfo
  );

  const [loadPage, { called, loading, data }] = useLazyQuery<{
    getAppointmentsHistory: Pagination<Appointment>;
  }>(GET_APPOINTMENTS_HISTORY, {
    variables: {
      data: {
        userId: user._id,
        page: pagination?.currentPage,
        perPage: 4,
      },
    },
  });

  const handleNewPage = async (step: 'next' | 'prev') => {
    if (step === 'next' && pagination.hasNextPage) {
      setPagination((state) => ({
        ...state,
        currentPage: state.currentPage + 1,
      }));
    } else if (step === 'prev' && pagination.hasPreviousPage) {
      setPagination((state) => ({
        ...state,
        currentPage: state.currentPage - 1,
      }));
    }
    const resp = await loadPage();
    setAppointments(resp.data.getAppointmentsHistory.items);
    setPagination(resp.data.getAppointmentsHistory.pageInfo);
  };

  return (
    <div>
      {appointmentRecord?.items?.length > 0 ? (
        <>
          <div className="w-full flex my-10 pr-5">
            <img
              className="w-8 h-6  mr-5 mt-1"
              src={isoTypo.src}
              alt="spa-car-logo"
            />
            <div>
              <h2 className="text-xl md:text-2xl text-neutral-400 font-bold mb-2">
                Historial
              </h2>
              <span className="text-neutral-300 font-light text-md ">
                Estas son los servicios que has realizado con nosotros
              </span>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-center">
            {!loading ? (
              appointments.map((appointment) => (
                <AppointmentCard
                  key={`appointment-history-${appointment._id}`}
                  appointment={appointment}
                  className="md:mx-3 my-2"
                />
              ))
            ) : (
              <div className="h-48">
                <SpinnerIcon className="w-6 h-6 text-primary-300 animate-spin dark:text-gray-600 fill-primary-300" />
              </div>
            )}
          </div>
          <div className="flex flex-col items-center">
            <span className="text-sm text-gray-700 dark:text-gray-400">
              <span className="font-semibold text-gray-900 dark:text-white">
                {pagination.currentPage}
              </span>{' '}
              de{' '}
              <span className="font-semibold text-gray-900 dark:text-white">
                {pagination.pageCount}
              </span>{' '}
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleNewPage('prev');
                }}
                className="w-24 py-2 px-4 text-sm font-medium text-white bg-primary-300 rounded-l hover:bg-primary-400 "
              >
                Previo
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleNewPage('next');
                }}
                className="w-24 py-2 px-4 text-sm font-medium text-white bg-primary-300 rounded-r border-0 border-l  hover:bg-primary-400"
              >
                Siguiente
              </button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default AppointmentRecord;
