import { Product } from '@spa-cars/models';
import defaultImage from '../public/images/isotypo-yellow.png';

export interface VariantOption {
  productId: any;
  variantId: any;
  productTitle?: string;
  variantTitle?: string;
  brandTitle?: string;
  photo?: string;
  price: string;
}

export function getVariantsOptions(
  products: Product[],
  viscosity = ''
): VariantOption[] {
  const variants = [];
  for (const product of products) {
    for (const variant of product.variants) {
      const item = {
        productId: product._id,
        variantId: variant._id,
        productTitle: product.title,
        brand: product.brand ?? '',
        variantTitle: `${variant?.option?.option1 ?? ''} ${
          variant?.option?.option2 ?? ''
        } ${variant?.option?.option3 ?? ''}`,
        photo: variant?.photo?.src ?? defaultImage.src,
        price: variant.price,
      };
      if (viscosity && viscosity === variant.option.option1) {
        variants.push(item);
      } else if (!viscosity) {
        variants.push(item);
      }
    }
  }
  return variants;
}

export function getSelectedVariant(
  shopCartVariantsIds: string[],
  options: VariantOption[]
) {
  const variantsIds = options.map((item) => item.variantId);
  // get selected products
  return shopCartVariantsIds.find((_id) => variantsIds.includes(_id));
}
