import { Variant, Discount, Product } from '@spa-cars/models';
import { VariantOption } from '../../lib/variantsOptions';

const viscosities = [
  '0W-10',
  '0W-16',
  '0W-20',
  '0W-30',
  '0W-40',
  '5W-10',
  '5W-20',
  '5W-30',
  '5W-40',
  '5W-50',
  '10W-30',
  '10W-40',
  '10W-50',
  '15W-40',
  '15W-50',
  '20W-40',
  '20W-50',
  '20W-60',
];

export type TTotal = {
  subtotal?: number;
  taxableSubtotal?: number;
  totalTax?: number;
  total?: number;
  realTax?: number;
  discountTotal?: number;
};

export type TShopcartProduct = {
  product: Product;
  variant: Variant;
  quantity: number;
};

export const InitialState = {
  hydraulicOil: '',
  wiperWasher: '',
  discountCode: '',
  engineOil: '',
  oilFilter: '',
  flush: '',
  engineOilViscosity: '',
  engineOilQuantity: '',
  loadingEngineOilOptions: false,
  inPaymentProcess: false,
  engineOilOptions: [],
  wiperWasherOptions: [],
  hydraulicOilOptions: [],
  oilFilterOptions: [],
  flushOptions: [],
  appliedDiscounts: [],
  loadingShopCart: false,
  confirmedForm: false,
  withFlush: false,
  viscosities,
  allProducts: [],
  shopcartProducts: [],
  ownFlush: false,
  ownFilter: false,
  ownOil: false,
  total: {
    subtotal: 0,
    taxableSubtotal: 0,
    totalTax: 0,
    realTax: 0,
    total: 0,
    discountTotal: 0,
  },
};

export interface PaymentState {
  engineOil: string;
  flush: string;
  flushOptions: VariantOption[];
  hydraulicOil: string;
  wiperWasher: string;
  oilFilter: string;
  discountCode: string;
  engineOilViscosity: string;
  engineOilQuantity: string;
  loadingEngineOilOptions: boolean;
  engineOilOptions: VariantOption[];
  wiperWasherOptions: VariantOption[];
  hydraulicOilOptions: VariantOption[];
  oilFilterOptions: VariantOption[];
  loadingShopCart: boolean;
  confirmedForm: boolean;
  inPaymentProcess: boolean;
  viscosities: string[];
  appliedDiscounts: Discount[];
  withFlush: boolean;
  allProducts: Product[];
  shopcartProducts: TShopcartProduct[];
  ownFlush?: boolean;
  ownFilter?: boolean;
  ownOil?: boolean;
  total: TTotal;
}

export const ACTIONS = {
  FLUSH_OPTIONS: 'FLUSH_OPTIONS',
  FLUSH: 'FLUSH',
  ENGINE_OIL: 'ENGINE_OIL',
  ENGINE_OIL_QUANTITY: 'ENGINE_OIL_QUANTITY',
  IN_PAYMENT_PROCESS: 'IN_PAYMENT_PROCESS',
  ENGINE_OIL_VISCOSITY: 'ENGINE_OIL_VISCOSITY',
  HYDRAULIC_OIL: 'HYDRAULIC_OIL',
  OIL_FILTER: 'OIL_FILTER',
  WIPER_WASHER: 'WIPER_WASHER',
  DISCOUNT_CODE: 'DISCOUNT_CODE',
  LOADING_ENGINE_OIL_OPTIONS: 'LOADING_ENGINE_OIL_OPTIONS',
  ENGINE_OIL_OPTIONS: 'ENGINE_OIL_OPTIONS',
  WIPER_WASHER_OPTIONS: 'WIPER_WASHER_OPTIONS',
  HYDRAULIC_OIL_OPTIONS: 'HYDRAULIC_OIL_OPTIONS',
  OIL_FILTERS_OPTIONS: 'OIL_FILTERS_OPTIONS',
  LOADING_SHOPCART: 'LOADING_SHOPCART',
  CONFIRMED_FORM: 'CONFIRMED_FORM',
  VISCOSITIES: 'VISCOSITIES',
  APPLIED_DISCOUNTS: 'APPLIED_DISCOUNTS',
  TOTAL: 'TOTAL',
  WITH_FLUSH: 'WITH_FLUSH',
  ALL_PRODUCTS: 'ALL_PRODUCTS',
  SHOPCART_PRODUCTS: 'SHOPCART_PRODUCTS',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'FLUSH'; payload: string }
  | { type: 'ENGINE_OIL'; payload: string }
  | { type: 'ENGINE_OIL_QUANTITY'; payload: string }
  | { type: 'ENGINE_OIL_VISCOSITY'; payload: string }
  | { type: 'HYDRAULIC_OIL'; payload: string }
  | { type: 'WIPER_WASHER'; payload: string }
  | { type: 'DISCOUNT_CODE'; payload: string }
  | { type: 'OIL_FILTER'; payload: string }
  | { type: 'OIL_FILTERS_OPTIONS'; payload: VariantOption[] }
  | { type: 'LOADING_ENGINE_OIL_OPTIONS'; payload: boolean }
  | { type: 'HYDRAULIC_OIL_OPTIONS'; payload: VariantOption[] }
  | { type: 'ENGINE_OIL_OPTIONS'; payload: VariantOption[] }
  | { type: 'WIPER_WASHER_OPTIONS'; payload: VariantOption[] }
  | { type: 'FLUSH_OPTIONS'; payload: VariantOption[] }
  | { type: 'LOADING_SHOPCART'; payload: boolean }
  | { type: 'CONFIRMED_FORM'; payload: boolean }
  | { type: 'IN_PAYMENT_PROCESS'; payload: boolean }
  | { type: 'VISCOSITIES'; payload: string[] }
  | { type: 'APPLIED_DISCOUNTS'; payload: Discount[] }
  | { type: 'WITH_FLUSH'; payload: boolean }
  | { type: 'TOTAL'; payload: TTotal }
  | { type: 'SHOPCART_PRODUCTS'; payload: TShopcartProduct[] }
  | { type: 'ALL_PRODUCTS'; payload: Product[] }
  | { type: 'DEFAULT'; payload: Partial<PaymentState> };

export function reducer(
  state: Partial<PaymentState>,
  action: ACTIONS_TYPE
): Partial<PaymentState> {
  switch (action.type) {
    case ACTIONS.FLUSH_OPTIONS:
      return { ...state, flushOptions: action.payload };
    case ACTIONS.FLUSH:
      return { ...state, flush: action.payload };
    case ACTIONS.ENGINE_OIL:
      return { ...state, engineOil: action.payload };
    case ACTIONS.OIL_FILTER:
      return { ...state, oilFilter: action.payload };
    case ACTIONS.OIL_FILTERS_OPTIONS:
      return { ...state, oilFilterOptions: action.payload };
    case ACTIONS.ENGINE_OIL_QUANTITY:
      return { ...state, engineOilQuantity: action.payload };
    case ACTIONS.ENGINE_OIL_VISCOSITY:
      return { ...state, engineOilViscosity: action.payload };
    case ACTIONS.HYDRAULIC_OIL:
      return { ...state, hydraulicOil: action.payload };
    case ACTIONS.WIPER_WASHER:
      return { ...state, wiperWasher: action.payload };
    case ACTIONS.LOADING_ENGINE_OIL_OPTIONS:
      return { ...state, loadingEngineOilOptions: action.payload };
    case ACTIONS.ENGINE_OIL_OPTIONS:
      return { ...state, engineOilOptions: action.payload };
    case ACTIONS.HYDRAULIC_OIL_OPTIONS:
      return { ...state, hydraulicOilOptions: action.payload };
    case ACTIONS.WIPER_WASHER_OPTIONS:
      return { ...state, wiperWasherOptions: action.payload };
    case ACTIONS.DISCOUNT_CODE:
      return { ...state, discountCode: action.payload };
    case ACTIONS.LOADING_SHOPCART:
      return { ...state, loadingShopCart: action.payload };
    case ACTIONS.CONFIRMED_FORM:
      return { ...state, confirmedForm: action.payload };
    case ACTIONS.IN_PAYMENT_PROCESS:
      return { ...state, inPaymentProcess: action.payload };
    case ACTIONS.VISCOSITIES:
      return { ...state, viscosities: action.payload };
    case ACTIONS.APPLIED_DISCOUNTS:
      return { ...state, appliedDiscounts: action.payload };
    case ACTIONS.TOTAL:
      return { ...state, total: action.payload };
    case ACTIONS.WITH_FLUSH:
      return { ...state, withFlush: action.payload };
    case ACTIONS.ALL_PRODUCTS:
      return { ...state, allProducts: action.payload };
    case ACTIONS.SHOPCART_PRODUCTS:
      return { ...state, shopcartProducts: action.payload };
    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}
