import React from 'react';
import { useRouter } from 'next/router';
import { GoogleMap } from '@spa-cars/ui';
import { Location } from '@spa-cars/models';
import { Title } from '../shared';
import ShopCard from './ShopCard';

interface MapProps {
  locations: Location[];
}

function Map({ locations }: MapProps) {
  const router = useRouter();
  return (
    <main className="w-full md:max-w-[768px]  lg:max-w-[1440px] m-auto px-5 pt-5">
      <section className="flex flex-wrap">
        {/* left side */}
        <div className="w-full lg:w-1/2 min-h-[35rem] px-5 lg:px-10 py-5 order-2 lg:order-1 rounded-lg">
          <GoogleMap
            apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}
            centers={
              locations.length > 0
                ? locations.map((loc) => ({
                    id: loc._id,
                    lat: loc.address.coordinates.coordinates[0],
                    lng: loc.address.coordinates.coordinates[1],
                    action: () => {
                      router.push(`/shop/${loc._id}`);
                    },
                  }))
                : []
            }
            mapContainerStyle={{
              width: '100%',
              height: '70vh',
              borderRadius: '25px',
            }}
          />
        </div>
        {/* right side */}
        <div className="w-full lg:w-1/2 px-5 md:px-10 order-1 lg:order-2 justify-start items-start">
          {/* title */}
          <Title title="Todas las sedes" />
          {locations.map((location) => (
            <ShopCard
              key={`location-${location._id}`}
              id={location._id}
              name={location.name}
              address={location.address}
              img={location.photos[0]}
            />
          ))}
        </div>
      </section>
    </main>
  );
}

export default Map;
