/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import NProgress from 'nprogress';
import {
  AvilaIcon,
  Button,
  EyeIcon,
  Input,
  LockIcon,
  Modal,
  UserIcon,
} from '@spa-cars/ui';
import { validateEmail } from 'avilatek-utils';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { User } from '@spa-cars/models';
import { useNotify, useUser } from '../../hooks';
import { SIGN_IN } from '../../graphql/mutations';
import logo from '../../public/images/logo.png';

interface SignInModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSignUpModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setResetPasswordModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function SignInModal({
  isOpen,
  setIsOpen,
  setSignUpModalIsOpen,
  setResetPasswordModalIsOpen,
}: SignInModalProps) {
  const notify = useNotify();
  const router = useRouter();
  const [user, setUser] = useUser();
  const [signIn] = useMutation<{ signIn: { user: User } }>(SIGN_IN);
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      NProgress.start();
      if (disabled) {
        return;
      }
      setDisabled(true);
      if (!validateEmail(email)) {
        return notify('Su correo electrónico es inválido', 'error');
      }
      setDisabled(false);
      const { data } = await signIn({
        variables: {
          data: {
            email: email.toLowerCase(),
            password,
          },
        },
      });
      if (data.signIn.user) {
        setUser({ ...data.signIn.user });
        await router.push('/app');
      } else {
        notify('Ha ocurrido un error', 'error');
      }
    } catch (error) {
      notify(`${error}`, 'error');
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  return (
    <Modal isOpen={isOpen} setOpen={setIsOpen} className="max-w-[39rem] ">
      <div className="bg-white flex flex-col px-8 md:px-16 pt-16 rounded-lg">
        {/* logo */}
        <div className="relative m-auto h-full w-8/12 flex items-center">
          <img className="w-[25rem] " src={logo.src} alt="spa-car-logo" />
        </div>
        {/* subtitle */}
        <div className="my-9 text-center">
          {' '}
          <span className="text-primary-400 text-lg md:text-xl font-light tracking-wider">
            Tu carro nos prefiere
          </span>{' '}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="px-5 flex flex-col">
            <div className="mb-2">
              <Input
                label="Correo electrónico"
                placeholder="Ingrese su correo electrónico"
                value={email}
                onChange={(e) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
                required
              >
                <UserIcon className="w-5 h-5 text-neutral-200" />
              </Input>
            </div>
            <div className="my-2">
              <Input
                label="Contraseña"
                placeholder="Ingrese su contraseña"
                value={password}
                onChange={(e) => {
                  e.preventDefault();
                  setPassword(e.target.value);
                }}
                required
                rightIcon={
                  <EyeIcon
                    className="w-5 h-5 cursor-pointer text-neutral-200"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                  />
                }
                type={showPassword ? 'text' : 'password'}
              >
                <LockIcon className="w-5 h-5 text-neutral-200" />
              </Input>
            </div>
            <Button className="my-5" type="submit">
              Iniciar sesión
            </Button>
            <div className="w-full text-center">
              <span
                className="text-sm text-primary-300 tracking-wider cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                  setTimeout(() => {
                    setResetPasswordModalIsOpen(true);
                  }, 500);
                }}
              >
                Recuperar contraseña
              </span>
            </div>
            <div className="w-full text-center mb-8 mt-4 flex flex-col">
              <span className="text-sm text-neutral-200 tracking-wider">
                ¿No posee una cuenta?
              </span>{' '}
              <span
                className="text-sm text-primary-300 tracking-wider cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                  setTimeout(() => {
                    setSignUpModalIsOpen(true);
                  }, 500);
                }}
              >
                Crea una cuenta
              </span>
            </div>
            <div className="mx-auto mb-5 flex text-neutral-100 hover:text-[#42B87D] items-center">
              <span className="text-sm items-center"> Developed by</span>
              <a
                target="_blank"
                href="https://www.avilatek.com/"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <AvilaIcon className="w-8 h-8 mx-2 mb-1  items-center" />
                Avila Tek
              </a>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default SignInModal;
