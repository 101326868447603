import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const transition = { duration: 1, ease: 'easeInOut' };

const fadeVariant = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition },
  exit: { opacity: 0, transition },
};

interface FadeAnimationProps {
  children: React.ReactNode | React.ReactNode[];
}

function FadeAnimation({ children }: FadeAnimationProps) {
  return (
    <AnimatePresence>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={fadeVariant}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export default FadeAnimation;
