/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { Discount, Multimedia } from '@spa-cars/models';
import saleExample from '../../../public/images/sale-example.png';

interface SaleCardProps {
  discount: Discount;
  setSelectedPhotoStep: (step: Multimedia) => void;
  setIsPhotoOpen: (isOpen: boolean) => void;
}

function SaleCard({
  discount,
  setSelectedPhotoStep,
  setIsPhotoOpen,
}: SaleCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: [0, 1],
        transition: {
          duration: 0.5,
        },
      }}
      className="flex flex-col w-full sm:w-1/2 xl:w-1/4 p-5"
    >
      <div className="h-[15rem]">
        <img
          className="w-full h-full rounded-2xl relative object-cover object-center cursor-pointer"
          src={discount?.photo?.src ?? saleExample?.src}
          alt={discount?.photo?.alt ?? 'discount'}
          onClick={(e) => {
            e.preventDefault();
            setIsPhotoOpen(true);
            setSelectedPhotoStep(discount.photo);
          }}
        />
      </div>
      <Link
        href={discount.url ?? '/app'}
        passHref
        className="mt-3 flex flex-col"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="text-neutral-400 text-lg">{discount.name}</span>
        <span className="text-neutral-400 text-sm">
          Código: {discount.code}
        </span>
        {discount.date ? (
          <span className="text-neutral-200 text-sm">
            Valido hasta el {dayjs(discount?.date).format('LLLL')}
          </span>
        ) : null}
      </Link>
    </motion.div>
  );
}

export default SaleCard;
