/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { VehicleVersion } from '@spa-cars/models';
import { GET_BY_MODEL_AND_YEAR } from '../../../../../../graphql/queries';

export function useLazyVersions() {
  // React hook
  const [versions, setVersions] = React.useState<VehicleVersion[]>([]);

  // Apollo lazy query
  const [loadVersions, { data, loading }] = useLazyQuery<{
    getByModelAndYear: VehicleVersion[];
  }>(GET_BY_MODEL_AND_YEAR, {
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    if (data && !loading) {
      setVersions(data.getByModelAndYear ?? []);
    }
  }, [data, loading]);

  return [loadVersions, versions] as const;
}
