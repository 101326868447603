import React from 'react';
import { InfoIcon } from './icons';

interface AlertProps {
  title: string;
  text: string;
  className?: string;
  show: boolean;
  titleClassName?: string;
  textClassName?: string;
}

function Alert({
  title,
  text,
  show = false,
  className = '',
  titleClassName = '',
  textClassName = '',
}: AlertProps) {
  return (
    <div>
      {show ? (
        <div
          className={`mt-5 md:mt-0 bg-teal-50 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md ${className}`}
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <InfoIcon className="h-6 w-6 text-teal-500 mr-4" />
            </div>
            <div>
              <p className={`font-bold  ${titleClassName}`}>{title}</p>
              <p className={`text-sm text-text-gray ${textClassName}`}>
                {text}
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Alert;
