import React from 'react';
import { Appointment, Repair, User, Vehicle } from '@spa-cars/models';
import { Title } from '../../shared';
import AppointmentSummary from './AppointmentSummary';
import AppointmentProcessCard from './AppointmentProcessCard';
import { AppointmentVideoCard } from './AppointmentVideoCard';

interface AppointmentProfileProps {
  mechanic: User;
  repair: Repair;
  vehicle: Vehicle;
  appointment: Appointment;
}

function AppointmentProfile({
  mechanic,
  vehicle,
  repair,
  appointment,
}: AppointmentProfileProps) {
  return (
    <main className="w-full max-w-[1440px] m-auto px-5 pb-10">
      <section className="w-full flex flex-wrap ">
        {/* left */}
        <div className="w-full sm:min-w-[22rem]  xl:w-1/3 mt-5 flex">
          <div className="mx-auto w-full xl:block flex justify-center xl:fixed">
            <AppointmentSummary
              appointment={appointment}
              vehicle={vehicle}
              mechanic={mechanic}
            />
          </div>
        </div>
        {/* right */}
        <div className="w-full  xl:w-2/3 mt-5 sm:mt-0">
          <Title title="Proceso" className="" />
          <div className="flex flex-wrap">
            {repair.process.map((step, idx) => (
              <AppointmentProcessCard key={`${step._id}-${idx}`} step={step} />
            ))}
            {/* <AppointmentVideoCard
              date={{
                start: new Date(repair.start),
                end: new Date(repair.updatedAt),
              }}
            /> */}
          </div>
        </div>
      </section>
    </main>
  );
}

export default AppointmentProfile;
