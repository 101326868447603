import React from 'react';
import nProgress from 'nprogress';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import { SpinnerIcon } from '@spa-cars/ui';
import { User } from '@spa-cars/models';
import { SIGN_S3, UPDATE_USER } from '../../graphql/mutations';
import { useNotify, useUser } from '../../hooks';
import profileFallback from '../../public/images/Profile picture.png';

export default function UserPhoto() {
  const notify = useNotify();
  const [user, setUser] = useUser();
  const [signS3] = useMutation(SIGN_S3);
  const [updateUser] = useMutation<{ updateUser: { record: User } }>(
    UPDATE_USER
  );
  const [uploading, setUploading] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const MB3 = 3000000;
    if (fileUploaded.size >= MB3) {
      notify('Tu archivo debe pesar menos de 3MB', 'error');
    } else if (!uploading) {
      setUploading(true);
      const reader = new FileReader();
      reader.onload = function onLoadFile(e) {
        setFile({
          src: e.target.result,
          name: file?.name ?? 'N/A',
          file: fileUploaded,
        });
      };
      reader.readAsDataURL(fileUploaded);
    }
  };

  React.useEffect(() => {
    const upload = async () => {
      if (file) {
        const _src = file.src as string;
        if (_src && !_src.startsWith('http')) {
          try {
            nProgress.start();
            const { data } = await signS3({
              variables: {
                data: {
                  filename: file?.file?.name,
                  filetype: file?.file?.type,
                },
              },
            });

            const options = {
              headers: {
                'Content-Type': file?.file?.type,
                'x-amz-acl': 'public-read',
              },
            };
            const response = await axios.put(
              data?.signS3?.signedRequest,
              file?.file,
              options
            );
            if (response) {
              const { data: updatedData } = await updateUser({
                variables: {
                  record: {
                    photo: { src: data?.signS3?.url },
                  },
                  filter: {
                    _id: user._id,
                  },
                },
              });
              if (updatedData.updateUser.record) {
                setUser((userInfo) => ({
                  ...userInfo,
                  ...updatedData.updateUser.record,
                }));
                notify('Se ha actualizado el perfil con exito', 'success');
              } else {
                notify('Ha ocurrido un error', 'error');
              }
            } else {
              notify(`Error cargando el archivo`, 'error');
            }
          } catch (err) {
            notify(`Error cargando el archivo `, 'error');
          } finally {
            setUploading(false);
            nProgress.done();
          }
        }
      }
    };
    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file?.src]);

  return (
    <div className="relative w-full flex flex-col  sm:px-48 lg:px-5 mt-10">
      <div className="relative w-full lg:h-96">
        {user?.photo?.src ? (
          <img
            className={` w-full h-full rounded-lg shadow-lg object-cover object-center `}
            src={user?.photo?.src}
            alt="user-img"
          />
        ) : (
          <div className=" w-full h-full rounded-lg shadow-lg bg-primary-300 flex ">
            <img
              className="w-20 h-20  m-auto"
              src={profileFallback.src}
              alt="user-img"
            />
          </div>
        )}
      </div>
      <button
        type="button"
        onClick={handleClick}
        className="my-5 flex justify-center items-center bg-system-white text-primary-300 shadow-lg border-[0.3px] py-2 px-4 rounded w-full  font-semibold tracking-wide"
      >
        {uploading ? (
          <SpinnerIcon className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-white" />
        ) : (
          <span>Editar foto</span>
        )}
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        className="hidden"
        multiple={false}
        accept="image/*"
      />
    </div>
  );
}
