import { gql } from '@apollo/client';
import {
  APPOINTMENT_FRAGMENT,
  CURRENCY_FRAGMENT,
  DISCOUNT_FRAGMENT,
  LOCATION_FRAGMENT,
  ORDER_FRAGMENT,
  PAGINATION_FRAGMENT,
  PRODUCT_FRAGMENT,
  REPAIR_FRAGMENT,
  SHOPCART_FRAGMENT,
  USER_FRAGMENT,
  VEHICLE_FRAGMENT,
  CLIENT_FRAGMENT,
  VARIANT_FRAGMENT,
  SPECS_FRAGMENT,
} from './fragments';

// ------------------------------User
export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_CLIENT_VEHICLES = gql`
  query GET_CLIENT_VEHICLES(
    $filter: FilterFindOneClientInput
    $skip: Int
    $sort: SortFindOneClientInput
  ) {
    client(filter: $filter, skip: $skip, sort: $sort) {
      vehicles {
        ...VehicleFragment
      }
    }
  }
  ${VEHICLE_FRAGMENT}
`;

// -----------------------------locations
export const GET_LOCATIONS = gql`
  query GET_LOCATIONS($filter: FilterFindManyLocationInput) {
    locations(filter: $filter) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const GET_LOCATION = gql`
  query GET_LOCATION($filter: FilterFindOneLocationInput) {
    location(filter: $filter) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`;

// -------------------------------- vehicles

export const GET_VEHICLES = gql`
  query GET_VEHICLES($filter: FilterFindManyVehicleInput) {
    vehicles(filter: $filter) {
      ...VehicleFragment
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const GET_VEHICLE_BY_PLATE = gql`
  query GET_VEHICLE_BY_PLATE(
    $filter: FilterFindOneVehicleInput
    $skip: Int
    $sort: SortFindOneVehicleInput
  ) {
    vehicle(filter: $filter, skip: $skip, sort: $sort) {
      owner
      token
      _id
    }
  }
`;

export const FIND_VEHICLE_BY_ID = gql`
  query FIND_VEHICLE_BY_ID($data: FindVehicleByIdInput) {
    findVehicleById(data: $data) {
      inmaInfo {
        brandCode
        modelCode
        brandId
        modelId
        versionId
      }
    }
  }
`;

export const GET_BRANDS = gql`
  query GET_BRANDS($sort: SortFindManyVehicleBrandInput) {
    brands(sort: $sort) {
      _id
      name
      active
    }
  }
`;

export const GET_MODELS_BY_BRAND = gql`
  query GET_MODELS_BY_BRAND($data: GetAllByBrandInput) {
    getModelsByBrand(data: $data) {
      _id
      name
      active
    }
  }
`;

export const GET_YEARS_BY_MODELS = gql`
  query GET_YEARS_BY_MODELS($data: GetByModelInput) {
    getYearsByModel(data: $data) {
      years
    }
  }
`;

export const GET_ALL_BY_MODEL = gql`
  query GET_ALL_BY_MODEL($data: GetByModelInput) {
    getAllByModel(data: $data) {
      _id
      engine {
        transmission
        volume
        cylinders
      }
      year
    }
  }
`;

export const GET_BY_MODEL_AND_YEAR = gql`
  query GET_BY_MODEL_AND_YEAR($data: GetByModelAndYearInput) {
    getByModelAndYear(data: $data) {
      _id
      engine {
        transmission
        volume
        cylinders
        valves
      }
      year
    }
  }
`;

export const GET_BY_MODEL_YEAR_BRAND = gql`
  query GET_BY_MODEL_YEAR_BRAND($data: GetByModelYearAndBrandInput) {
    getByModelYearAndBrand(data: $data) {
      _id
      civi
      extra
      transmission
      engine
    }
  }
`;

export const GET_VEHICLE_SUGGESTIONS = gql`
  query GetVehicleSuggestions($data: GetVehicleSuggestions) {
    getVehicleSuggestions(data: $data) {
      service {
        ...ProductFragment
      }
      screenWashers {
        ...ProductFragment
      }
      oils {
        ...ProductFragment
      }
      oilFilters {
        ...ProductFragment
      }
      flushes {
        ...ProductFragment
      }
      coolants {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_VEHICLE_DEFAULT_SPECS = gql`
  query GetVehicleDefaultSpecs($data: GetVehicleDefaultSpecs) {
    getVehicleDefaultSpecs(data: $data) {
      specs {
        ...SpecsFragment
      }
    }
  }
  ${SPECS_FRAGMENT}
`;

// -----------------------------------appointments

export const GET_APPOINTMENTS = gql`
  query GET_APPOINTMENTS($filter: FilterFindManyAppointmentInput) {
    appointments(filter: $filter) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_APPOINTMENT_CUSTOM = gql`
  query GET_APPOINTMENT_CUSTOM($data: GetAppointmentsInput) {
    getAppointmentsCustom(data: $data) {
      todaysAppointments {
        ...AppointmentFragment
      }
      nextAppointments {
        ...AppointmentFragment
      }
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_APPOINTMENTS_HISTORY = gql`
  query GET_APPOINTMENT_HISTORY($data: GetAppointmentsHistory) {
    getAppointmentsHistory(data: $data) {
      count
      items {
        ...AppointmentFragment
      }
      pageInfo {
        ...PaginationFragment
      }
    }
  }
  ${APPOINTMENT_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_APPOINTMENT = gql`
  query GET_APPOINTMENT($filter: FilterFindOneAppointmentInput) {
    appointment(filter: $filter) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_ALL_APPOINTMENT_BY_LOCATION = gql`
  query GET_ALL_APPOINTMENT_BY_LOCATION($data: GetAppointmentsByLocationInput) {
    getAllAppointmentsByLocations(data: $data) {
      date
    }
  }
`;

export const GET_AVAILABLE_SLOTS = gql`
  query GET_AVAILABLE_SLOTS($data: GetAvailableSlots) {
    getAvailableSlots(data: $data) {
      slots {
        hour
        available
        count
      }
    }
  }
`;

export const VALIDATE_NEXT_BLOCK = gql`
  query VALIDATE_NEXT_BLOCK($data: GetAvailableSlots) {
    validateNextBlock(data: $data) {
      success
    }
  }
`;

// Products -----------------------------

export const GET_PRODUCTS = gql`
  query GET_PRODUCTS($data: GetProductsInput) {
    getProducts(data: $data) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const CUSTOM_GET_PRODUCTS = gql`
  query CUSTOM_GET_PRODUCTS($data: CustomGetProductsInput) {
    customGetProducts(data: $data) {
      oilTag {
        ...ProductFragment
      }
      refrigerantTag {
        ...ProductFragment
      }
      wipeTag {
        ...ProductFragment
      }
      serviceTag {
        ...ProductFragment
      }
      serviceFlushTag {
        ...ProductFragment
      }
      hydraulicOilTag {
        ...ProductFragment
      }
      flushTag {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

// Discounts ------------------------------
export const GET_DISCOUNTS = gql`
  query GET_DISCOUNTS(
    $filter: FilterFindManyDiscountInput
    $sort: SortFindManyDiscountInput
  ) {
    discounts(filter: $filter, sort: $sort) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const GET_DISCOUNT = gql`
  query GET_DISCOUNT(
    $filter: FilterFindOneDiscountInput
    $sort: SortFindOneDiscountInput
  ) {
    discount(filter: $filter, sort: $sort) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const GET_DISCOUNTS_LIST = gql`
  query GET_DISCOUNTS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyDiscountInput
  ) {
    discountPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...DiscountFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

// shopcart -----------------------

export const GET_SHOPCART_TALLY = gql`
  query GET_SHOPCART_TALLY($data: GetShopcartTallyInput) {
    getShopcartTally(data: $data) {
      subtotal
      taxableSubtotal
      realTax
      total
      discountTotal
      totalTax
    }
  }
`;

// currency -----------------------

export const GET_CURRENCIES = gql`
  query GET_CURRENCIES($filter: FilterFindManyCurrencyInput) {
    currencies(filter: $filter) {
      ...CurrencyFragment
    }
  }
  ${CURRENCY_FRAGMENT}
`;

// repair -----------------------

export const GET_REPAIR = gql`
  query GET_REPAIR($data: GetRepair) {
    getRepair(data: $data) {
      repair {
        ...RepairFragment
      }
      vehicle {
        ...VehicleFragment
      }
      mechanic {
        ...UserFragment
      }
      appointment {
        ...AppointmentFragment
      }
    }
  }
  ${REPAIR_FRAGMENT}
  ${VEHICLE_FRAGMENT}
  ${USER_FRAGMENT}
  ${APPOINTMENT_FRAGMENT}
`;

// orders  -----------------------

export const GET_ORDER = gql`
  query GET_ORDER($filter: FilterFindOneOrderInput!) {
    order(filter: $filter) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_VIDEOS = gql`
  query GET_VIDEOS($data: GetVideosInput) {
    getVideo(data: $data) {
      _id
      url
    }
  }
`;
