/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Vehicle } from '@spa-cars/models';
import Alert from '../../../Alert';
import Modal from '../../../Modal';
import { Button } from '../../../Button';
import { getInitialState, reducer } from '../reducer';
import TokenInput from './TokenInput';

interface CarTokenModalProps {
  vehicle?: Vehicle;
  userId?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleLinkVehicle: (_car: Partial<Vehicle>, state: any) => Promise<void>;
  car: Partial<Vehicle>;
  isDuplication?: boolean;
}

function CarTokenModal({
  vehicle,
  userId,
  isOpen,
  setIsOpen,
  handleLinkVehicle,
  car,
  isDuplication = false,
}: CarTokenModalProps) {
  const initialState = getInitialState(vehicle, userId, isDuplication);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  return (
    <Modal isOpen={isOpen} setOpen={setIsOpen} className="max-w-[40rem]">
      <div className="w-full flex px-7 md:px-10 py-8 rounded-lg bg-white flex-wrap min-h-[50vh]">
        <Alert
          title="Tu carro ha sido registrado por otro usuario"
          text="Si deseas continuar con el registro, por favor ingrese el token del carro que se encuentra en el perfil del carro de la persona que lo registro previamente"
          show
        />
        <TokenInput state={state} handleChange={handleChange} />
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleLinkVehicle(car, state);
          }}
        >
          Vincular{' '}
        </Button>
      </div>
    </Modal>
  );
}

export default CarTokenModal;
