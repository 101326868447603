import React from 'react';
import NProgress from 'nprogress';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { validateEmail, validatePhone, validateString } from 'avilatek-utils';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import {
  Input,
  UserIcon,
  EyeIcon,
  LockIcon,
  Button,
  AvilaIcon,
  MailIcon,
  Select,
  Modal,
} from '@spa-cars/ui';
import { User } from '@spa-cars/models';
import { SIGN_UP } from '../../graphql/mutations';
import { useNotify, useUser } from '../../hooks';
import logo from '../../public/images/logo.png';

interface SignUpModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSignInModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function SignUpModal({
  isOpen,
  setIsOpen,
  setSignInModalIsOpen,
}: SignUpModalProps) {
  const notify = useNotify();
  const router = useRouter();
  const [user, setUser] = useUser();
  const [signUp] = useMutation<{ signUp: { user: User } }>(SIGN_UP);
  const [showPassword, setShowPassword] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [dni, setDni] = React.useState('');
  const [dniType, setDniType] = React.useState('V');
  const [fullPhone, setFullPhone] = React.useState('+58');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      NProgress.start();
      if (disabled) {
        return;
      }
      setDisabled(true);

      if (!validateString(firstName) || String(firstName).length > 127) {
        return notify('Su nombre es inválido', 'error');
      }

      if (!validateString(lastName) || String(lastName).length > 127) {
        return notify('Su apellido es inválido', 'error');
      }

      if (!validateEmail(email)) {
        return notify('Su correo electrónico es inválido', 'error');
      }

      if (!validatePhone(fullPhone) || String(fullPhone).length > 12) {
        return notify('Su teléfono es inválido', 'error');
      }

      if (String(dni).length > 8) {
        return notify(
          'Su número de identificación es inválido, debe de tener menos de 8 caracteres',
          'error'
        );
      }

      if (String(password).length < 8) {
        return notify(
          'La contraseña debe contener al menos 8 carácteres',
          'error'
        );
      }
      const localPhone = fullPhone.slice(
        fullPhone.length - 10,
        fullPhone.length
      );
      const phoneCode = fullPhone.slice(0, fullPhone.length - 10);

      const { data } = await signUp({
        variables: {
          data: {
            email: email.toLocaleLowerCase(),
            password,
            firstName,
            lastName,
            dni,
            dniType,
            phone: {
              phone: localPhone,
              code: phoneCode,
            },
          },
        },
      });
      if (data.signUp.user) {
        setUser({ ...data.signUp.user });
        await router.push('/app');
      } else {
        notify('Ha ocurrido un error', 'error');
      }
    } catch (error) {
      notify(`Error al registrarse: ${error}`, 'error');
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  return (
    <Modal isOpen={isOpen} setOpen={setIsOpen} className="max-w-[39rem] ">
      <div className="bg-white flex flex-col px-6 md:px-16 pt-16 rounded-lg">
        {/* logo */}
        <div className="relative m-auto h-full w-8/12 flex items-center">
          <img className="w-[25rem] " src={logo.src} alt="spa-car-logo" />
        </div>
        {/* subtitle */}
        <div className="my-9 text-center">
          {' '}
          <span className="text-primary-400 text-lg md:text-xl font-light tracking-wider">
            Tu carro nos prefiere
          </span>{' '}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="px-5 flex flex-col">
            <div className="mb-2 flex  flex-wrap">
              <div className="w-full mb-4 md:mb-0 md:w-1/2 md:pr-1">
                <Input
                  label="Nombre "
                  placeholder="Ingrese su nombre"
                  value={firstName}
                  onChange={(e) => {
                    e.preventDefault();
                    setFirstName(e.target.value);
                  }}
                  className="m-0"
                  required
                >
                  <UserIcon className="w-5 h-5 text-neutral-100" />
                </Input>
              </div>
              <div className="w-full  md:w-1/2 md:pl-1">
                <Input
                  label="Apellido"
                  placeholder="Ingrese su apellido"
                  value={lastName}
                  onChange={(e) => {
                    e.preventDefault();
                    setLastName(e.target.value);
                  }}
                  required
                >
                  <UserIcon className="w-5 h-5 text-neutral-100" />
                </Input>
              </div>
            </div>
            <div className="my-2">
              <Input
                label="Cédula"
                placeholder="Ingrese su número de identificación"
                value={dni}
                onChange={(e) => {
                  e.preventDefault();
                  setDni(e.target.value);
                }}
                className="gap-1"
                type="number"
                required
              >
                <span className="flex w-4/12 sm:w-3/12 items-center text-neutral-200 ">
                  <UserIcon className="w-5 h-5 mr-1 text-neutral-100" />{' '}
                  <select
                    value={dniType}
                    onChange={(e) => {
                      e.preventDefault();
                      setDniType(e.target.value);
                    }}
                    className="border-none w-14 h-6 px-0 py-0"
                  >
                    <option value="V">V</option>
                    <option value="E">E</option>
                    <option value="J">J</option>
                    <option value="G">G</option>
                    <option value="P">P</option>
                  </select>
                </span>
              </Input>
            </div>
            <div className="my-2 mb-1">
              <span className="text-neutral-200 text-sm font-medium mb-2 ">
                Número de teléfono
              </span>
              <PhoneInput
                inputStyle={{
                  appearance: 'none',
                  height: '24px',
                  background: 'transparent',
                  width: '100%',
                  border: 'solid',
                  borderWidth: '0.5px',
                  borderColor: '#A6A6A6',
                  borderRadius: '4px',
                  minHeight: '40px',
                  fontSize: '16px',
                  color: '#606060',
                  fontWeight: '300',
                }}
                countryCodeEditable={false}
                value={fullPhone}
                placeholder="Ingrese su teléfono"
                onChange={(phoneNumber) => {
                  setFullPhone(phoneNumber);
                }}
              />
              <span className="text-xs text-neutral-100">
                Formato: +58 4123214569
              </span>
            </div>
            <div className="my-2">
              <Input
                label="Correo electrónico"
                placeholder="Ingrese su correo electrónico"
                value={email}
                onChange={(e) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
                required
              >
                <MailIcon className="w-5 h-5 text-neutral-100" />
              </Input>
            </div>
            <div className="my-2">
              <Input
                label="Contraseña"
                placeholder="Ingrese su contraseña"
                value={password}
                onChange={(e) => {
                  e.preventDefault();
                  setPassword(e.target.value);
                }}
                required
                rightIcon={
                  <EyeIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                  />
                }
                type={showPassword ? 'text' : 'password'}
              >
                <LockIcon className="w-5 h-5 text-neutral-100" />
              </Input>
            </div>
            <Button className="my-5" type="submit">
              Crear cuenta
            </Button>
            <div className="w-full text-center mb-8 mt-4 ">
              <span className="text-sm text-neutral-200 tracking-wider">
                ¿Ya tienes una cuenta?
              </span>{' '}
              <button
                type="button"
                className="text-sm text-primary-300 tracking-wider cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                  setTimeout(() => {
                    setSignInModalIsOpen(true);
                  });
                }}
              >
                Iniciar sesión
              </button>
            </div>
            <div className="mx-auto mb-5 flex text-neutral-100 hover:text-[#42B87D] items-center">
              <span className="text-sm items-center"> Developed by</span>
              <a
                target="_blank"
                href="https://wa.me/https://www.avilatek.com/"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <AvilaIcon className="w-8 h-8 mx-2 mb-1  items-center" />
                Avila Tek
              </a>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default SignUpModal;
