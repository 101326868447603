import React from 'react';
import Link from 'next/link';
import { Button, Card } from '@spa-cars/ui';
import { termAndConditionsBullets } from './DeleteAccountTermsAndConditions';
import { useNotify } from '../../../hooks';
import ConfirmDeleteAccountModal from './ConfirmDeleteAccountModal/ConfirmDeleteAccountModal';

function DeleteAccount() {
  const notify = useNotify();
  const [isOpen, setIsOpen] = React.useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    React.useState(false);

  const handleDeleteAccount = () => {
    if (acceptTermsAndConditions) {
      setIsOpen(true);
    } else {
      notify('Debes aceptar los términos y condiciones', 'error');
    }
  };

  return (
    <main className="w-full max-w-[1440px] m-auto px-5 pb-10">
      <Card className="max-w-[800px] mx-auto p-5 px-10 shadow-sm space-y-3">
        <h3 className="text-primary-400 text-xl font-semibold">
          Eliminación de cuenta
        </h3>
        <p className="text-gray-500 ">
          Spacars respeta la privacidad de los usuarios. Es por eso que
          brindamos la posibilidad de eliminar tu cuenta junto a cualquier
          información relacionada que se encuentre en nuestra base de datos que
          no requiramos mantener.
        </p>
        <ul>
          {termAndConditionsBullets.map((bullet, i) => (
            <li key={bullet._id} className="text-gray-500 my-3">
              <span className="text-primary-300 font-semibold">{i + 1}. </span>
              {bullet.text}
            </li>
          ))}
        </ul>
        <div className="flex items-center mb-4">
          <input
            checked={acceptTermsAndConditions}
            onChange={() => setAcceptTermsAndConditions((state) => !state)}
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="disabled-checkbox"
            className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-500 "
          >
            He leído y acepto los términos y condiciones de este proceso y
            entiendo las implicaciones de la eliminación de mi usuario
          </label>
        </div>
        <div className="flex flex-col sm:flex-row  sm:space-x-5 pt-10 space-y-2">
          <Link href="/profile" legacyBehavior>
            <Button>Volver</Button>
          </Link>
          <Button
            className="bg-danger-100"
            onClick={() => {
              handleDeleteAccount();
            }}
          >
            Eliminar cuenta
          </Button>
        </div>
      </Card>
      <ConfirmDeleteAccountModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </main>
  );
}

export default DeleteAccount;
