// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { VehicleModel } from '@spa-cars/models';
import { GET_MODELS_BY_BRAND } from '../../../../../../graphql/queries';

export function useLazyModels() {
  // React hooks
  const [models, setModels] = React.useState<VehicleModel[]>([]);

  // Apollo lazy query
  const [loadModels, { data, loading }] = useLazyQuery<{
    getModelsByBrand: VehicleModel[];
  }>(GET_MODELS_BY_BRAND, {
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    if (!loading && data) {
      setModels(data.getModelsByBrand ?? []);
    }
  }, [data, loading]);

  return [loadModels, models] as const;
}
