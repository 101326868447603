/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import { SpinnerIcon, ThanksIcon } from '@spa-cars/ui';
import { Appointment, Order } from '@spa-cars/models';
import { useRouter } from 'next/router';
import { useStripe } from '@stripe/react-stripe-js';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_ORDER } from '../../graphql/mutations';
import { useNotify, useShopCart } from '../../hooks';
import { GET_APPOINTMENT } from '../../graphql/queries';
import { FadeAnimation } from '../shared';

function Thanks() {
  const router = useRouter();
  const stripe = useStripe();
  const notify = useNotify();
  const [shopCart] = useShopCart();

  const appointmentId =
    'r' in router.query
      ? JSON.parse(atob(router.query.r as string)).TrazaId
      : router?.query?.appointmentId;

  const [loading, setLoading] = React.useState(false);

  const [createOrderMutation, { loading: loadingOrder }] = useMutation<{
    createOrder: { data: Order };
  }>(CREATE_ORDER);

  const [
    getAppointment,
    {
      loading: loadingAppointment,
      data: dataAppointment,
      fetchMore: fetchMoreAppointment,
    },
  ] = useLazyQuery<{
    appointment: Appointment;
  }>(GET_APPOINTMENT, {
    fetchPolicy: 'network-only',
  });

  const createOrder = React.useCallback(
    async (total: number, { gateway, currency, shopCartId }: any = {}) => {
      const { promoCodes } = JSON.parse(
        localStorage.getItem('promoCodes') ?? '{"promoCodes": []}'
      );

      localStorage.setItem('promoCodes', JSON.stringify({ promoCodes: [] }));

      let response: any = '';
      response =
        gateway === 'luka' ? JSON.parse(atob(router.query.r as string)) : '';

      try {
        await createOrderMutation({
          variables: {
            data: {
              shopcart:
                gateway === 'luka' ? shopCartId : shopCart?._id ?? shopCartId,
              appointment:
                gateway === 'luka'
                  ? String(appointmentId).split('-')[0]
                  : String(appointmentId),
              promoCodes,
              // actualmente todos los pagos con luka son diferidos, tomar en cuenta si en un futuro se cambia
              paymentStatus:
                gateway === 'luka' &&
                response?.InfoProceso?.EstatusProcesamiento === 'pending'
                  ? 'in_progress'
                  : 'approved',
              paymentInfo: {
                status: gateway === 'luka' ? 'manual_check' : 'approved',
                gateway: gateway || 'stripe',
                amount: total,
                currency: currency || 'ds',
              },
            },
          },
        });
        await fetchMoreAppointment({
          variables: {
            filter: { _id: router?.query?.appointmentId, active: true },
          },
        });
      } catch (error) {
        notify(`Transacción fallida: ${error} contacte a soporte`, 'error');
      }
    },
    [
      appointmentId,
      createOrderMutation,
      fetchMoreAppointment,
      notify,
      router?.query?.appointmentId,
      shopCart?._id,
    ]
  );

  React.useEffect(() => {
    if (typeof router?.query?.appointmentId === 'string') {
      getAppointment({
        variables: {
          filter: { _id: router?.query?.appointmentId, active: true },
        },
      });
    }
  }, [getAppointment, router?.query?.appointmentId]);

  React.useEffect(() => {
    async function verifyPaymentIntent() {
      try {
        setLoading(true);
        // verify appointment dont have order
        if (dataAppointment?.appointment?.order || loadingAppointment) {
          return;
        }
        const redirectStatus = router?.query?.redirect_status;
        const paymentIntentClientSecret =
          router?.query?.payment_intent_client_secret;
        if (!redirectStatus || !paymentIntentClientSecret || !stripe) return;
        const { paymentIntent } = await stripe.retrievePaymentIntent(
          paymentIntentClientSecret as string
        );
        if (paymentIntent && paymentIntent.status === 'succeeded') {
          await createOrder(paymentIntent.amount / 100);
        }
      } catch (error) {
        notify(`Transacción fallida: ${error} contacte a soporte`, 'error');
      } finally {
        setLoading(false);
      }
    }
    verifyPaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, loadingAppointment]);

  React.useEffect(() => {
    async function verifyPaymentIntentLuka() {
      try {
        setLoading(true);
        // verify appointment dont have order
        const redirectStatus = 'r' in router.query ? router.query.r : null;
        if (!redirectStatus) return;
        const response = JSON.parse(atob(router.query.r as string));
        const { data: _dataAppointment } = await getAppointment({
          variables: {
            filter: { _id: response.TrazaId.split('-')[0], active: true },
          },
        });
        if (_dataAppointment?.appointment?.order || loadingAppointment) {
          return;
        }
        if (response.Exitoso) {
          await createOrder(response.Monto, {
            currecy: response.Moneda,
            shopCartId: response.TrazaId.split('-')[1],
            gateway: 'luka',
          });
        }
      } catch (error) {
        notify(`Transacción fallida: ${error} contacte a soporte`, 'error');
      } finally {
        setLoading(false);
      }
    }
    if (shopCart !== null) {
      verifyPaymentIntentLuka();
    }
  }, [shopCart]);

  return (
    <div className="flex justify-center">
      {!loadingAppointment && !loading && !loadingOrder ? (
        <FadeAnimation>
          <div className="flex flex-col justify-center items-center">
            {dataAppointment?.appointment?.order || !dataAppointment ? (
              <ThanksIcon className="w-72 h-72" />
            ) : null}
            <h2 className="text-4xl text-neutral-400 font-semibold my-5">
              {dataAppointment?.appointment?.order || !dataAppointment
                ? 'r' in router.query
                  ? 'Estamos procesando su pago'
                  : 'e' in router.query
                  ? 'Ha ocurrido un error, por favor contacte a soporte'
                  : '¡Se ha agendado su cita!'
                : 'Ha ocurrido un error, por favor contacte a soporte'}
            </h2>
            <Link
              href="/appointment"
              className="text-primary-300 font-semibold text-lg cursor-pointer"
            >
              Ver reservas
            </Link>
          </div>
        </FadeAnimation>
      ) : (
        <div className="w-full h-[70vh] flex  opacity-70 z-30">
          <SpinnerIcon className="m-auto w-24 h-24 text-gray-200 animate-spin dark:text-gray-600 fill-primary-300" />
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
}

export default Thanks;
