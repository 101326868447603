import React from 'react';
import Link from 'next/link';
import {
  ByMyCarIcon,
  CardCarouselWrapper,
  useRefDimensions,
  useScreenSize,
} from '@spa-cars/ui';
import { Appointment } from '@spa-cars/models';
import AppointmentCard from './AppointmentCard';
import isoTypo from '../../../public/images/isotypo-yellow.png';

interface UpcomingAppointmentsProps {
  upcomingAppointments: Appointment[];
}

function UpcomingAppointments({
  upcomingAppointments,
}: UpcomingAppointmentsProps) {
  const [elementsToShow, setElementsToShow] = React.useState(2);
  // carousel cards refs
  const refs = upcomingAppointments?.reduce((acc, value, index) => {
    acc[index] = React.createRef<HTMLDivElement>();
    return acc;
  }, []);

  const { width: cardWidth } = useRefDimensions(
    upcomingAppointments?.length > 0 ? refs[0] : null
  );
  const { screenSize } = useScreenSize();

  React.useEffect(() => {
    if (screenSize > 1280 && upcomingAppointments?.length > 1) {
      setElementsToShow(2);
    } else {
      setElementsToShow(1);
    }
  }, [screenSize]);

  return (
    <div className="flex w-full">
      <div className="hidden lg:block border-l-2 border-neutral-100 mt-10 mr-5" />
      <div className="w-full ">
        <div className="w-full flex my-10">
          <img
            className="w-8 h-6  mr-5 mt-1"
            src={isoTypo.src}
            alt="spa-car-logo"
          />
          <div>
            <h2 className="text-xl md:text-2xl text-neutral-400 font-bold ">
              Próximas reservaciones
            </h2>
            <span className="text-neutral-300 font-light text-md ">
              Estas son las reservas programadas para las próximas fechas <br />
            </span>
          </div>
        </div>
        <div className="lg:w-full px-5 md:px-10 pt-[0.5px] ">
          {upcomingAppointments?.length > 0 ? (
            <CardCarouselWrapper
              cardDimension={cardWidth}
              elementsToShow={elementsToShow}
            >
              {upcomingAppointments?.map((appointment, i) => (
                <div ref={refs[i]} key={`upcoming-appointments-${i}`}>
                  <AppointmentCard
                    className="mx-3 my-2 w-[17.5rem] sm:w-[22rem]"
                    appointment={appointment}
                  />
                </div>
              ))}
            </CardCarouselWrapper>
          ) : (
            <Link className="" href="/appointment/create">
              <div className="flex w-full justify-center flex-col">
                <span className="m-auto text-center text-lg text-neutral-000">
                  ¡Agenda tu próxima cita!
                </span>
                <ByMyCarIcon className="h-[150px] md:h-[250px] m-auto" />
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default UpcomingAppointments;
