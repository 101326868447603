import React from 'react';
import { CarIcon } from '@spa-cars/ui';
import {
  Vehicle,
  VehicleBrand,
  VehicleModel,
  VehicleVersion,
} from '@spa-cars/models';

interface UpperCardProps {
  car: Vehicle;
}

export default function UpperCard({ car }: UpperCardProps) {
  return (
    <div className="flex mb-4">
      <span className=" text-neutral-400 font-medium flex items-center">
        <CarIcon className="w-6 h-6 text-neutral-100 mr-4" />
        <div>
          <p className="text-neutral-500 text-lg font-semibold">
            {(car?.brand as VehicleBrand)?.name}
          </p>
          <p className="text-neutral-200 font-medium">
            {(car?.model as VehicleModel)?.name}
          </p>
        </div>
      </span>
      <span className="text-neutral-100 font-medium ml-auto my-auto">
        {(car.version as VehicleVersion)?.year}
      </span>
    </div>
  );
}
