import React from 'react';
import { useQuery } from '@apollo/client';
import { Vehicle, VehicleBrand } from '@spa-cars/models';
import { GET_BRANDS } from '../../../../../../graphql/queries';

interface UseBrandsProps {
  car: Vehicle;
  duplicateVehicle: boolean;
  myCars: Vehicle[];
}

export function useBrands({ car, duplicateVehicle, myCars }: UseBrandsProps) {
  // React hooks
  const [brands, setBrands] = React.useState<VehicleBrand[]>([]);

  // Apollo query
  const { data, loading } = useQuery<{
    brands: VehicleBrand[];
  }>(GET_BRANDS, {
    fetchPolicy: 'network-only',
    variables: { sort: 'NAME_ASC' },
  });

  // load brands when component is mounted
  React.useEffect(() => {
    if (!loading && data) {
      setBrands(data.brands ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, car, duplicateVehicle, myCars]);

  return brands;
}
