import {
  Vehicle,
  VehicleBrand,
  VehicleModel,
  VehicleVersion,
} from '@spa-cars/models';

export const createCarModalInitialState = {
  brands: [],
  loadingBrands: false,
  models: [],
  loadingModels: false,
  versions: [],
  loadingVersions: false,
  years: [],
  loadingYears: false,
  brandId: '',
  modelId: '',
  versionId: '',
  year: '',
  plate: '',
  engineSerial: '',
  changeEngine: 'km',
  changeEngineOilFrequency: '',
  token: '',
  tires: '',
  psi: '',
};

interface CreateCarModalState {
  _id?: string;
  brands: VehicleBrand[];
  loadingBrands: boolean;
  models: VehicleModel[];
  loadingModels: boolean;
  versions: VehicleVersion[];
  loadingVersions: boolean;
  years: string[];
  loadingYears: boolean;
  loadingVehicle: boolean;
  brandId: string;
  modelId: string;
  versionId: string;
  year: string;
  plate: string;
  engineSerial: string;
  changeEngineOilBy: string;
  changeEngineOilFrequency: string;
  tires: string;
  token: string;
  psi: string;
}

export const ACTIONS = {
  TOKEN: 'TOKEN',
  BRANDS: 'BRANDS',
  PSI: 'PSI',
  LOADING_BRANDS: 'LOADING_BRANDS',
  MODELS: 'MODELS',
  LOADING_MODELS: 'LOADING_MODELS',
  VERSIONS: 'VERSIONS',
  LOADING_VERSIONS: 'LOADING_VERSIONS',
  YEARS: 'YEARS',
  LOADING_YEARS: 'LOADING_YEARS',
  BRAND_ID: 'BRAND_ID',
  MODEL_ID: 'MODEL_ID',
  VERSION_ID: 'VERSION_ID',
  YEAR: 'YEAR',
  PLATE: 'PLATE',
  ENGINE_SERIAL: 'ENGINE_SERIAL',
  CHANGE_ENGINE_OIL_BY: 'CHANGE_ENGINE_OIL_BY',
  CHANGE_ENGINE_OIL_FREQUENCY: 'CHANGE_ENGINE_OIL_FREQUENCY',
  TIRES: 'TIRES',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'TOKEN'; payload: string }
  | { type: 'BRANDS'; payload: VehicleBrand[] }
  | { type: 'LOADING_BRANDS'; payload: boolean }
  | { type: 'MODELS'; payload: VehicleModel[] }
  | { type: 'LOADING_MODELS'; payload: boolean }
  | { type: 'VERSIONS'; payload: VehicleVersion[] }
  | { type: 'LOADING_VERSIONS'; payload: boolean }
  | { type: 'YEARS'; payload: string[] }
  | { type: 'LOADING_YEARS'; payload: boolean }
  | { type: 'BRAND_ID'; payload: string }
  | { type: 'MODEL_ID'; payload: string }
  | { type: 'VERSION_ID'; payload: string }
  | { type: 'YEAR'; payload: string }
  | { type: 'PLATE'; payload: string }
  | { type: 'ENGINE_SERIAL'; payload: string }
  | { type: 'CHANGE_ENGINE_OIL_BY'; payload: string }
  | { type: 'CHANGE_ENGINE_OIL_FREQUENCY'; payload: string }
  | { type: 'PSI'; payload: string }
  | { type: 'TIRES'; payload: string }
  | { type: 'PSI'; payload: string }
  | { type: 'DEFAULT'; payload: Partial<CreateCarModalState> };

export function reducer(
  state: Partial<CreateCarModalState>,
  action: ACTIONS_TYPE
): Partial<CreateCarModalState> {
  switch (action.type) {
    case ACTIONS.TOKEN:
      return { ...state, token: action.payload };
    case ACTIONS.BRANDS:
      return { ...state, brands: action.payload };
    case ACTIONS.LOADING_BRANDS:
      return { ...state, loadingBrands: action.payload };
    case ACTIONS.MODELS:
      return { ...state, models: action.payload };
    case ACTIONS.VERSIONS:
      return { ...state, versions: action.payload };
    case ACTIONS.LOADING_VERSIONS:
      return { ...state, loadingVersions: action.payload };
    case ACTIONS.LOADING_MODELS:
      return { ...state, loadingModels: action.payload };
    case ACTIONS.YEARS:
      return { ...state, years: action.payload };
    case ACTIONS.LOADING_YEARS:
      return { ...state, loadingYears: action.payload };
    case ACTIONS.BRAND_ID:
      return { ...state, brandId: action.payload };
    case ACTIONS.MODEL_ID:
      return { ...state, modelId: action.payload };
    case ACTIONS.VERSION_ID:
      return { ...state, versionId: action.payload };
    case ACTIONS.PLATE:
      return { ...state, plate: action.payload };
    case ACTIONS.YEAR:
      return { ...state, year: action.payload };
    case ACTIONS.ENGINE_SERIAL:
      return { ...state, engineSerial: action.payload };
    case ACTIONS.CHANGE_ENGINE_OIL_BY:
      return { ...state, changeEngineOilBy: action.payload };
    case ACTIONS.CHANGE_ENGINE_OIL_FREQUENCY:
      return { ...state, changeEngineOilFrequency: action.payload };
    case ACTIONS.TIRES:
      return { ...state, tires: action.payload };
    case ACTIONS.PSI:
      return { ...state, psi: action.payload };
    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}

export function getInitialState(
  vehicle: Vehicle | undefined = undefined,
  userId: string | undefined = undefined,
  isDuplication: boolean = false
): Partial<CreateCarModalState> {
  return {
    ...createCarModalInitialState,
    _id: isDuplication || !vehicle ? '' : vehicle?._id,
    plate: isDuplication || !vehicle ? '' : vehicle.plate,
    engineSerial: isDuplication || !vehicle ? '' : vehicle.engineSerial,
    tires: vehicle?.specs?.tireFiller,
    changeEngineOilBy: vehicle?.oilChangeType,
    changeEngineOilFrequency: vehicle?.oilChangeTypeValue,
    token: userId === vehicle?.owner && !isDuplication ? vehicle?.token : '',
    psi: vehicle?.specs?.psi,
  };
}
