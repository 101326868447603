import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Discount, Multimedia, Pagination } from '@spa-cars/models';
import SaleCard from './SaleCard';
import isoTypo from '../../../public/images/isotypo-yellow.png';
import saleExample from '../../../public/images/sale-example.png';
import { GET_DISCOUNTS_LIST } from '../../../graphql/queries';
import StepPhotoModal from '../../appointment/AppointmentProfile/StepPhotoModal';

interface SalesProps {
  discounts: Pagination<Discount>;
}

function Sales({ discounts }: SalesProps) {
  const [myDiscounts, setMyDiscounts] = React.useState(discounts?.items);
  const [pagination, setPagination] = React.useState(discounts?.pageInfo);
  const [isPhotoOpen, setIsPhotoOpen] = React.useState(false);
  const [selectedPhotoStep, setSelectedPhotoStep] =
    React.useState<Multimedia>(null);

  const [loadPage] = useLazyQuery<{
    discountPagination: Pagination<Discount>;
  }>(GET_DISCOUNTS_LIST, {
    variables: {
      page: pagination?.currentPage,
      perPage: 8,
      filter: {
        active: true,
        show: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleNewPage = async (step: 'next' | 'prev') => {
    if (step === 'next' && pagination.hasNextPage) {
      setPagination((state) => ({
        ...state,
        currentPage: state.currentPage + 1,
      }));
    } else if (step === 'prev' && pagination.hasPreviousPage) {
      setPagination((state) => ({
        ...state,
        currentPage: state.currentPage - 1,
      }));
    }
    const resp = await loadPage();
    setMyDiscounts(resp.data.discountPagination.items);
    setPagination(resp.data.discountPagination.pageInfo);
  };

  return (
    <div>
      {myDiscounts?.length > 0 &&
      pagination.hasNextPage &&
      pagination.hasPreviousPage ? (
        <section className="max-w-[1440px] m-auto mt-16">
          <div className="w-full flex  ">
            <img
              className="w-8 h-6  mr-5 mt-1"
              src={isoTypo.src}
              alt="spa-car-logo"
            />
            <div>
              <h2 className="text-xl md:text-2xl text-neutral-400 font-bold mb-2">
                Promociones
              </h2>
              <span className="text-neutral-300 font-light text-md ">
                Estas son nuestras promociones activas
              </span>
            </div>
          </div>
          {/* cards */}
          <div className="w-full flex flex-wrap mt-10">
            {myDiscounts.map((discount) => (
              <SaleCard
                key={discount._id}
                discount={discount}
                setSelectedPhotoStep={setSelectedPhotoStep}
                setIsPhotoOpen={setIsPhotoOpen}
              />
            ))}
          </div>
          <div className="flex flex-col items-center">
            <span className="text-sm text-gray-700 dark:text-gray-400">
              <span className="font-semibold text-gray-900 dark:text-white">
                {pagination.currentPage}
              </span>{' '}
              de{' '}
              <span className="font-semibold text-gray-900 dark:text-white">
                {pagination.pageCount}
              </span>{' '}
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleNewPage('prev');
                }}
                className="w-24 py-2 px-4 text-sm font-medium text-white bg-primary-300 rounded-l hover:bg-primary-400 "
              >
                Previo
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleNewPage('next');
                }}
                className="w-24 py-2 px-4 text-sm font-medium text-white bg-primary-300 rounded-r border-0 border-l  hover:bg-primary-400"
              >
                Siguiente
              </button>
            </div>
          </div>
          <StepPhotoModal
            isOpen={isPhotoOpen}
            setIsOpen={setIsPhotoOpen}
            img={selectedPhotoStep}
          />
        </section>
      ) : (
        <div />
      )}
    </div>
  );
}

export default Sales;
