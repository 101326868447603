import React from 'react';

interface ShopServiceCardProps {
  name: string;
  icon: React.ReactNode;
}

function ShopServiceCard({ name, icon }: ShopServiceCardProps) {
  return (
    <div className="px-2 md:px-0 md:pr-4 py-2">
      <div className=" w-36 h-36 bg-transparent border-neutral-100 border-[1.5px] rounded-xl flex flex-col">
        <div className="mx-auto mt-auto">{icon}</div>
        <div className="mb-auto mx-auto pb-5">
          <span className="text-neutral-100 font-medium text-xl">{name}</span>
        </div>
      </div>
    </div>
  );
}

export default ShopServiceCard;
