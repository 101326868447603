import React from 'react';

interface StepNumberProps {
  step: number;
}

export default function StepNumber({ step }: StepNumberProps) {
  return (
    <div className="w-9 h-8 bg-primary-200 rounded flex items-center justify-center">
      <span className="text-white font-semibold">{step}</span>
    </div>
  );
}
