import React from 'react';
import dayjs from 'dayjs';
import { AvilaIcon, IsoType } from '@spa-cars/ui';
import Navbar from '../navigation/Navbar';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="relative overflow-hidden min-h-screen">
      {/* navbar */}
      <Navbar />
      {/* watermark */}
      <div className="absolute w-full overflow-hidden ">
        <IsoType className="relative md:top-[-10rem] opacity-5 w-[110vw] md:w-[105vw] h-auto" />
      </div>
      {/* children */}
      <div className="relative pt-24 md:pt-28 px-5">{children}</div>
      <div className="mx-auto pt-10 flex text-white gap-2 justify-center py-2">
        <span className="text-primary-300 font-light flex items-center">
          © {dayjs().year()} SERVICIOS Y MANTENIMIENTOS SPACARS C.A. | Powered
          by{' '}
          <a
            target="_blank"
            href="https://www.avilatek.com/"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <AvilaIcon className="w-8 h-8 mx-2  hover:text-[#42B87D] items-center" />
            Avila Tek
          </a>
        </span>
      </div>
    </div>
  );
}
