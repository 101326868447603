import React from 'react';
import NProgress from 'nprogress';
import { EyeIcon, Input, LockIcon, Button } from '@spa-cars/ui';
import { validateString } from 'avilatek-utils';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useNotify, useUser } from '../../../../hooks';
import { DELETE_USER } from '../../../../graphql/mutations';

interface ConfirmDeleteAccountStep2Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ConfirmDeleteAccountStep2({
  setStep,
  setIsOpen,
}: ConfirmDeleteAccountStep2Props) {
  const notify = useNotify();
  const router = useRouter();
  const [user, setUser] = useUser();
  const [disabled, setDisabled] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [rePassword, setRePassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showRePassword, setShowRePassword] = React.useState(false);

  const [deleteUser] = useMutation<{ deleteUser: { success: boolean } }>(
    DELETE_USER
  );

  const handleDeleteUser = async () => {
    try {
      NProgress.start();
      if (disabled) {
        return;
      }
      setDisabled(true);
      if (password !== rePassword) {
        return notify('Las contraseñas deben ser iguales', 'error');
      }
      if (
        !validateString(password) ||
        String(password).length > 127 ||
        String(password).length < 8
      ) {
        return notify(
          'La contraseña debe tener al menos 8 caracteres',
          'error'
        );
      }
      setDisabled(true);

      const { data } = await deleteUser({
        variables: {
          data: {
            password,
            email: user.email,
          },
        },
      });

      if (data.deleteUser.success) {
        notify(`Cuenta eliminada exitosamnete`, 'success');
        router.push('/');
        setUser(null);
        setIsOpen(false);
      } else {
        notify(`Ha ocurrido un error`, 'error');
      }
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      NProgress.done();
      setDisabled(false);
    }
  };
  return (
    <>
      <h3 className="text-lg text-primary-300 font-semibold">
        Lamentamos tu partida...
      </h3>
      <span className="text-neutral-200">
        Ingresa tu contraseña para iniciar el proceso de eliminación de tu
        cuenta.
      </span>
      <form>
        <div className="px-5 flex flex-col">
          <div className="mb-2">
            <Input
              label="Contraseña"
              placeholder="Ingrese su contraseña"
              value={password}
              onChange={(e) => {
                e.preventDefault();
                setPassword(e.target.value);
              }}
              required
              rightIcon={
                <EyeIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                />
              }
              type={showPassword ? 'text' : 'password'}
            >
              <LockIcon className="w-5 h-5 text-neutral-100" />
            </Input>
          </div>
          <div className="mb-2">
            <Input
              label="Confirmar contraseña"
              placeholder="Ingrese su contraseña"
              value={rePassword}
              onChange={(e) => {
                e.preventDefault();
                setRePassword(e.target.value);
              }}
              required
              rightIcon={
                <EyeIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRePassword(!showRePassword);
                  }}
                />
              }
              type={showRePassword ? 'text' : 'password'}
            >
              <LockIcon className="w-5 h-5 text-neutral-100" />
            </Input>
          </div>
          <div className="flex space-x-5">
            <Button
              className="my-5"
              type="button"
              onClick={() => {
                setStep(1);
                setIsOpen(false);
              }}
            >
              Volver
            </Button>
            <Button
              className="my-5 bg-red-400"
              type="button"
              onClick={() => {
                handleDeleteUser();
              }}
            >
              Eliminar cuenta
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default ConfirmDeleteAccountStep2;
