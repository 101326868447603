import React from 'react';
import NProgress from 'nprogress';
import {
  AvilaIcon,
  Button,
  EyeIcon,
  Input,
  LockIcon,
  Modal,
} from '@spa-cars/ui';
import { useMutation } from '@apollo/client';
import { validateString } from 'avilatek-utils';
import { useRouter } from 'next/router';
import { CHANGE_PASSWORD } from '../../graphql/mutations';
import { useNotify } from '../../hooks';
import logo from '../../public/images/logo.png';

interface ChangePasswordModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSignInModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ChangePasswordModal({
  isOpen,
  setIsOpen,
  setSignInModalIsOpen,
}: ChangePasswordModalProps) {
  const notify = useNotify();
  const router = useRouter();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showRePassword, setShowRePassword] = React.useState(false);

  const [password, setPassword] = React.useState('');
  const [rePassword, setRePassword] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      NProgress.start();
      if (disabled) {
        return;
      }
      setDisabled(true);
      if (password !== rePassword) {
        return notify('Las contraseñas deben ser iguales', 'error');
      }
      if (
        !validateString(password) ||
        String(password).length > 127 ||
        String(password).length < 8
      ) {
        return notify(
          'La contraseña debe tener al menos 8 caracteres',
          'error'
        );
      }
      setDisabled(true);

      const { data } = await changePassword({
        variables: {
          data: {
            password,
            token: router.query.token,
          },
        },
      });

      if (data.changePassword.success) {
        notify(`Contraseña restablecida exitosamnete`, 'success');
        setIsOpen(false);
        setSignInModalIsOpen(true);
      } else {
        notify(`Ha ocurrido un error: ${data.changePassword.err}`, 'error');
      }
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      NProgress.done();
      setDisabled(false);
    }
  };
  return (
    <Modal isOpen={isOpen} setOpen={setIsOpen} className="max-w-[39rem] ">
      <div className="bg-white flex flex-col px-8 md:px-16 pt-16 rounded-lg">
        {/* logo */}
        <div className="relative m-auto h-full w-8/12 flex items-center">
          <img className="w-[25rem] " src={logo.src} alt="spa-car-logo" />
        </div>
        {/* subtitle */}
        <div className="my-9 text-center">
          {' '}
          <span className="text-primary-400 text-lg md:text-2xl font-semibold tracking-wider">
            Ingrese su nueva contraseña
          </span>{' '}
        </div>
        <form onSubmit={onSubmit}>
          <div className="px-5 flex flex-col">
            <div className="mb-2">
              <Input
                label="Contraseña"
                placeholder="Ingrese su contraseña"
                value={password}
                onChange={(e) => {
                  e.preventDefault();
                  setPassword(e.target.value);
                }}
                required
                rightIcon={
                  <EyeIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                  />
                }
                type={showPassword ? 'text' : 'password'}
              >
                <LockIcon className="w-5 h-5 text-neutral-100" />
              </Input>
            </div>
            <div className="mb-2">
              <Input
                label="Confirmar contraseña"
                placeholder="Ingrese su contraseña"
                value={rePassword}
                onChange={(e) => {
                  e.preventDefault();
                  setRePassword(e.target.value);
                }}
                required
                rightIcon={
                  <EyeIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowRePassword(!showRePassword);
                    }}
                  />
                }
                type={showRePassword ? 'text' : 'password'}
              >
                <LockIcon className="w-5 h-5 text-neutral-100" />
              </Input>
            </div>
            <Button className="my-5" type="submit">
              Cambiar contraseña
            </Button>
            <div className="mx-auto mb-5 flex">
              <a
                target="_blank"
                href="https://www.avilatek.com/"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <AvilaIcon className="w-6 h-6 ml-2 pb-1" />
                <span className="text-sm text-neutral-100 items-center">
                  {' '}
                  Developed by Avila Tek
                </span>
              </a>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ChangePasswordModal;
