import React from 'react';
import dayjs from 'dayjs';
import Link from 'next/link';
import { CarIcon, ArrowIcon, formatCurrency } from '@spa-cars/ui';
import {
  Appointment,
  VehicleBrand,
  VehicleModel,
  VehicleVersion,
} from '@spa-cars/models';
import { appointmentStatusTypes } from '../../../lib/appoinmentsStatus';

interface AppointmentCardProps {
  className?: string;
  appointment?: Appointment;
}

function AppointmentCard({
  className = '',
  appointment,
}: AppointmentCardProps) {
  return (
    <div
      className={` w-full m-auto sm:w-[20rem] sm:max-w-[20rem] bg-white rounded-lg shadow-sm py-5 flex flex-col  ${className}`}
    >
      <div className="flex px-5 items-center">
        <span className="text-primary-300 font-medium text-lg">
          Reserva{' '}
          <span className="text-sm"> #{appointment?.reservationNumber}</span>
        </span>
        <span className="ml-auto text-neutral-300 font-medium text-md">
          {dayjs(appointment?.date).format('HH:mm A')}
        </span>
      </div>
      <div className="flex px-5 w-full">
        <span className="text-neutral-100 text-sm">
          {appointment?.location?.name}
        </span>
        <span className="ml-auto text-neutral-100 text-sm">
          {dayjs(appointment?.date).format('DD-MM-YYYY') ===
          dayjs(new Date()).format('DD-MM-YYYY')
            ? 'Hoy'
            : dayjs(appointment?.date).format('DD-MM-YYYY')}
        </span>
      </div>
      {appointment?.order?.total ? (
        <span className="px-5 text-neutral-100 text-sm">
          ${formatCurrency(appointment?.order?.total)}
        </span>
      ) : null}
      <hr className="my-4" />
      <div className="px-5 flex">
        <CarIcon className="w-5 h-5 mt-1 mr-2 text-neutral-100" />
        <div className="flex w-full flex-col">
          <div className="flex w-full">
            <span className="text-neutral-400 font-medium text-base">
              {(appointment?.vehicle.model as VehicleModel)?.name},{' '}
              {(appointment?.vehicle.brand as VehicleBrand)?.name}
            </span>
            <span className="text-neutral-100 text-sm my-auto ml-auto">
              {(appointment?.vehicle?.version as VehicleVersion)?.year}
            </span>
          </div>
          <span className="text-neutral-100 text-sm">
            {appointment?.vehicle?.plate}
          </span>
        </div>
      </div>
      <hr className="my-4" />
      <div className="flex w-full px-5 h-8 items-center">
        {appointment?.status &&
        appointment?.order &&
        appointment?.order?.paymentStatus !== 'in_progress' ? (
          <div
            className={`${
              appointment?.status === 'cancelled'
                ? 'bg-[#F7A9B1] text-danger-300'
                : 'bg-[#D8F2EF] text-success-300'
            } py-1 px-3 rounded  font-medium`}
          >
            {appointmentStatusTypes[appointment?.status]}
          </div>
        ) : null}
        {appointment?.status === 'not_started' &&
        !appointment.order &&
        appointment?.order?.paymentStatus === 'in_progress' ? (
          <>
            <div
              className={` py-1 px-3 rounded  font-medium bg-yellow-100 text-secondary-300`}
            >
              Por pagar
            </div>
            <Link
              href={`/payment/${appointment._id}/${appointment.location._id}?car=${appointment?.vehicle?._id}`}
              className="flex items-center ml-auto font-medium  text-success-300 gap-2 py-1 px-3 rounded "
            >
              Pagar
              <ArrowIcon className="w-3 h-3 cursor-pointer text-neutral-200  mt-[0.1rem]" />
            </Link>
          </>
        ) : null}
        {appointment?.order?.paymentStatus === 'in_progress' ? (
          <div
            className={` py-1 px-3 rounded  font-medium bg-yellow-100 text-secondary-300`}
          >
            Procesando el pago
          </div>
        ) : null}
        {appointment?.status === 'not_started' &&
        appointment?.order?.paymentStatus === 'approved' &&
        appointment?.order &&
        dayjs(appointment.date).subtract(1, 'day').isAfter(dayjs()) ? (
          <a
            target="_blank"
            href="https://wa.me/584142226592"
            rel="noopener noreferrer"
            className="bg-[#F7A9B1] p-1 rounded text-danger-300 font-medium ml-auto opacity-70"
          >
            Cancelar orden
          </a>
        ) : null}
        {!(
          appointment?.status === 'not_started' ||
          appointment?.status === 'cancelled'
        ) ? (
          <Link
            href={`appointment/profile/${appointment._id}`}
            className="ml-auto my-auto flex items-center"
          >
            Detalles
            <ArrowIcon className="w-3 h-3 ml-2 cursor-pointer text-neutral-200" />
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default AppointmentCard;
