// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_YEARS_BY_MODELS } from '../../../../../../graphql/queries';

export function useLazyYears() {
  const [loadYears] = useLazyQuery<{
    getYearsByModel: { years: string[] };
  }>(GET_YEARS_BY_MODELS, {
    fetchPolicy: 'network-only',
  });
  return loadYears;
}
