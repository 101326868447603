import { ArrowIcon } from '@spa-cars/ui';
import React from 'react';

interface ScrollCarouselWrapperProps {
  children: React.ReactNode;
  scrollLength: number;
  controlsClassName?: string;
}
function ScrollCarouselWrapper({
  children,
  // px que scrollea
  scrollLength,
  controlsClassName,
}: ScrollCarouselWrapperProps) {
  const carousel = React.useRef(null);

  const scroll = (direction: 'left' | 'right') => {
    carousel.current.scrollTo({
      top: 0,
      left:
        direction === 'left'
          ? (carousel?.current?.scrollLeft ?? 0) - scrollLength
          : (carousel?.current?.scrollLeft ?? 0) + scrollLength,
      behavior: 'smooth',
    });
  };

  return (
    <div className="w-full  mt-5 relative">
      {/* carousel controls */}
      <div
        className={`absolute left-[-35px] z-10  md:block ${controlsClassName}`}
      >
        <ArrowIcon
          onClick={() => scroll('left')}
          className="w-5 h-5  transform rotate-180 cursor-pointer"
        />
      </div>
      <div
        className={`absolute right-[-35px]  z-10  md:block ${controlsClassName}`}
      >
        <ArrowIcon
          onClick={() => scroll('right')}
          className=" w-5 h-5 cursor-pointer"
        />
      </div>
      {/* carousel */}
      <div
        ref={carousel}
        className="hide-scrollbar w-full flex relative overflow-x-scroll z-0 items-center "
      >
        <div className="flex">{children}</div>
      </div>
    </div>
  );
}

export default ScrollCarouselWrapper;
