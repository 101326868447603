import React from 'react';
import Link from 'next/link';
import {
  Button,
  MotorcycleIcon,
  SedanCarIcon,
  TruckIcon,
  CalendarIcon,
} from '@spa-cars/ui';
import { Location } from '@spa-cars/models';
import ShopCarouselSection from './ShopCarouselSection';
import ShopInfo from './ShopInfo';
import ShopServices from './ShopServices';

// esto creo que deberia de venir en la location, que son los tipos de vehivulos a los que ofrece servicio una location
const servicesExample = [
  {
    name: 'Carros',
    icon: <SedanCarIcon className="w-16 h-16" />,
  },
  // {
  //   name: 'Motos',
  //   icon: <MotorcycleIcon className="w-16 h-16" />,
  // },
  // {
  //   name: 'Camiones',
  //   icon: <TruckIcon className="w-16 h-16" />,
  // },
];

interface ShopProps {
  location: Location;
}

function Shop({ location }: ShopProps) {
  return (
    <main className="w-full">
      <section className="max-w-[1440px] flex-wrap flex m-auto px-5 ">
        {/* left side */}
        <div className="w-full lg:w-1/3 order-2 lg:order-1 px-10 lg:px-0">
          <ShopCarouselSection images={location?.photos} />
        </div>
        {/* right side */}
        <div className="w-full lg:w-2/3 flex flex-wrap px-5 md:px-10 lg:pl-32 order-1 lg:order-2">
          <ShopInfo
            name={location?.name}
            address={location?.address}
            description={location?.description}
          />
          <ShopServices services={servicesExample} />
          <div className="w-full md:w-1/2 my-5">
            <Link href="/appointment/create">
              <Button className="flex justify-center items-center">
                <CalendarIcon className="w-5 h-5 mr-3 text-white" /> Agendar
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Shop;
