import { ExitIcon } from '@spa-cars/ui';
import React from 'react';

interface UntangleButtonProps {
  vehicleId: string;
  onClick: (vehicleId: string) => void;
}

export default function UntangleButton({
  vehicleId,
  onClick,
}: UntangleButtonProps) {
  return (
    <button
      type="button"
      className="h-6 w-6 bg-red-500 rounded-full absolute top-3 right-3 flex justify-center items-center"
      aria-label="untangle-button"
      onClick={(e) => {
        e.preventDefault();
        onClick(vehicleId);
      }}
    >
      <span className="sr-only">Untangle Button</span>
      <ExitIcon className="h-3 text-white" />
    </button>
  );
}
