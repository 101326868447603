import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { Button } from '@spa-cars/ui';
import { paymentProcessorsEnum } from '@spa-cars/models';
import { CREATE_PAYMENT_INTENT_STRIPE } from '../../../graphql/mutations';
import { PaymentState } from '../reducer';
import { useUser } from '../../../hooks';
import CardInputStripe from './CardInputStripe';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);

interface StripeButtonProps {
  state: Partial<PaymentState>;
  processorSelected: paymentProcessorsEnum;
  setProcessorSelected: React.Dispatch<
    React.SetStateAction<paymentProcessorsEnum>
  >;
}

function StripeButton({
  state,
  processorSelected,
  setProcessorSelected,
}: StripeButtonProps) {
  const [user] = useUser();
  const [clientSecret, setClientSecret] = React.useState('');

  const [createPaymentIntentStripe] = useMutation<{
    createPaymentIntentStripe: {
      data: string;
    };
  }>(CREATE_PAYMENT_INTENT_STRIPE);

  React.useEffect(() => {
    async function createPaymentIntent() {
      try {
        const { data } = await createPaymentIntentStripe({
          variables: {
            data: {
              userId: user?._id,
              total: state?.total.total,
            },
          },
        });
        if (data?.createPaymentIntentStripe?.data) {
          setClientSecret(data?.createPaymentIntentStripe?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    createPaymentIntent();
  }, []);

  const appearance = {};
  const options = {
    clientSecret,
    appearance,
  };

  const showStripeBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setProcessorSelected('stripe');
  };

  return (
    <div className="mt-3 w-full">
      {processorSelected !== 'stripe' ? (
        <Button
          onClick={showStripeBox}
          className="flex items-center justify-center "
        >
          Pagar con tarjeta internacional
        </Button>
      ) : null}
      {clientSecret && processorSelected === 'stripe' ? (
        <Elements options={options} stripe={stripePromise}>
          <CardInputStripe state={state} />
        </Elements>
      ) : null}
    </div>
  );
}

export default StripeButton;
