import React from 'react';

interface FlushInputProps {
  serviceWithFlush: boolean;
  setServiceWithFlush: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

function FlushInput({
  serviceWithFlush,
  setServiceWithFlush,
  disabled = false,
}: FlushInputProps) {
  return (
    <div className="flex items-center mb-4">
      <input
        checked={serviceWithFlush}
        onChange={() => setServiceWithFlush(!serviceWithFlush)}
        type="checkbox"
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        disabled={disabled}
      />
      <label
        htmlFor="disabled-checkbox"
        className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-500 "
      >
        Desea realizar limpieza del motor con flush
      </label>
    </div>
  );
}

export default FlushInput;
