import React from 'react';

function SettingsIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      {...props}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2518 8.99996C15.2518 9.28329 15.2268 9.54996 15.1935 9.81663L16.9518 11.1916C17.1102 11.3166 17.1518 11.5416 17.0518 11.725L15.3852 14.6083C15.2852 14.7916 15.0685 14.8666 14.8768 14.7916L12.8018 13.9583C12.3685 14.2833 11.9018 14.5666 11.3935 14.775L11.0768 16.9833C11.0518 17.1833 10.8768 17.3333 10.6685 17.3333H7.33515C7.12682 17.3333 6.95182 17.1833 6.92682 16.9833L6.61015 14.775C6.10182 14.5666 5.63515 14.2916 5.20182 13.9583L3.12682 14.7916C2.94349 14.8583 2.71849 14.7916 2.61849 14.6083L0.951819 11.725C0.851819 11.5416 0.893486 11.3166 1.05182 11.1916L2.81015 9.81663C2.77682 9.54996 2.75182 9.27496 2.75182 8.99996C2.75182 8.72496 2.77682 8.44996 2.81015 8.18329L1.05182 6.80829C0.893486 6.68329 0.843486 6.45829 0.951819 6.27496L2.61849 3.39163C2.71849 3.20829 2.93515 3.13329 3.12682 3.20829L5.20182 4.04163C5.63515 3.71663 6.10182 3.43329 6.61015 3.22496L6.92682 1.01663C6.95182 0.816626 7.12682 0.666626 7.33515 0.666626H10.6685C10.8768 0.666626 11.0518 0.816626 11.0768 1.01663L11.3935 3.22496C11.9018 3.43329 12.3685 3.70829 12.8018 4.04163L14.8768 3.20829C15.0602 3.14163 15.2852 3.20829 15.3852 3.39163L17.0518 6.27496C17.1518 6.45829 17.1102 6.68329 16.9518 6.80829L15.1935 8.18329C15.2268 8.44996 15.2518 8.71663 15.2518 8.99996ZM6.08515 8.99996C6.08515 10.6083 7.39349 11.9166 9.00182 11.9166C10.6102 11.9166 11.9185 10.6083 11.9185 8.99996C11.9185 7.39163 10.6102 6.08329 9.00182 6.08329C7.39349 6.08329 6.08515 7.39163 6.08515 8.99996Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SettingsIcon;
