import React from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button, SpinnerIcon } from '@spa-cars/ui';
import { useRouter } from 'next/router';
import { PaymentState } from '../reducer';

interface CardInputStripeProps {
  state: Partial<PaymentState>;
}

function CardInputStripe({ state }: CardInputStripeProps) {
  const stripe = useStripe();
  const elements = useElements();
  const router = useRouter();

  const [message, setMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const promoCodes = state.appliedDiscounts.map((item) => item.code);

    localStorage.setItem('promoCodes', JSON.stringify({ promoCodes }));
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/thanks?appointmentId=${router?.query?.appointmentId}`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* <div className="py-4 px-2 bg-neutral-50 rounded shadow "> */}
      <PaymentElement id="payment-element" />
      <Button
        type="button"
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="mt-3"
        onClick={handleSubmit}
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner">
              <SpinnerIcon className="w-6 h-6 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-white" />
            </div>
          ) : (
            'Pagar'
          )}
        </span>
      </Button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

export default CardInputStripe;
