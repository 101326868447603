import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { ToastContextProvider, UserContextProvider } from '../context';
import { useApollo } from '../hooks';
import { ShopCartContextProvider } from '../context/ShopCartContext';
import '../styles.css';

// @ts-expect-error err is not defined
function MyApp({ Component, pageProps, err }: AppProps<any>) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  React.useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    Router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, []);
  return (
    <ApolloProvider client={apolloClient}>
      <>
        <Head>
          <title>Spacars</title>
          {/* favicon */}
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon/favicon-16x16.png"
          />
          <link
            rel="mask-icon"
            href="/favicon/safari-pinned-tab.svg"
            color="#5bbad5"
          />
        </Head>
        <ToastContextProvider>
          <UserContextProvider>
            <ShopCartContextProvider>
              <Component {...pageProps} err={err} />
            </ShopCartContextProvider>
          </UserContextProvider>
        </ToastContextProvider>
      </>
    </ApolloProvider>
  );
}

export default MyApp;
