/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import dayjs from 'dayjs';
import { ArrowIcon, PointIcon } from '@spa-cars/ui';
import { RepairStep, Multimedia } from '@spa-cars/models';
import StepPhotoModal from './StepPhotoModal';

interface AppointmentProcessCardProps {
  step: RepairStep;
}

function AppointmentProcessCard({ step }: AppointmentProcessCardProps) {
  const [isCollapse, setIsCollapse] = React.useState(false);

  const [isPhotoOpen, setIsPhotoOpen] = React.useState(false);
  const [selectedPhotoStep, setSelectedPhotoStep] =
    React.useState<Multimedia>(null);

  const getStepColor = () => {
    if (
      step.result === step.resultOptions[0] ||
      !(step.resultOptions.length > 0)
    ) {
      return 'text-primary-400';
    }
    if (
      step.result === step.resultOptions[step.resultOptions.length - 1] &&
      step.step === '1'
    ) {
      return 'text-danger-100';
    }
    return 'text-warning-100';
  };
  if (!step.result) return null;
  return (
    <div className="w-full p-5 ">
      <div className="z-0 bg-white rounded-xl flex py-6 shadow-lg p-10 relative ">
        <div className="relative z-0 w-full flex flex-col px-1">
          <button
            type="button"
            className="absolute right-0 top-3"
            onClick={(e) => {
              e.preventDefault();
              setIsCollapse(!isCollapse);
            }}
          >
            <ArrowIcon
              className={`w-3 h-3 text-primary-400 my-auto transform  ${
                isCollapse ? 'rotate-90' : 'rotate-0'
              } ml-2 `}
            />
          </button>

          <div
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              setIsCollapse(!isCollapse);
            }}
          >
            <div className="flex items-center mb-3">
              <PointIcon className={`w-6 h-6 ${getStepColor()}  mr-3`} />{' '}
              <span className="text-primary-300 font-semibold my-1">
                Punto # {step.step} - {step.title}
              </span>
            </div>
            <span className="text-neutral-100 my-1">
              {dayjs(step.date).format('hh:mm A')}
            </span>
            <div className="flex my-1 flex-col sm:flex-row ">
              <span className="text-primary-300 font-semibold">Resultado:</span>
              <span className="text-neutral-300 ml-0 sm:ml-1">
                {step.result}
                {step.step.includes('7') ? 'Llenado de cauchos' : null}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap w-full mt-2 ">
            {isCollapse
              ? step.stepInstruction.map((item, i) =>
                  item.photo ? (
                    <div
                      className={`w-full md:w-1/2 flex flex-col items-center sm:flex-row  my-2 `}
                      key={`${item._id}-${i}`}
                    >
                      <div className="w-full md:w-1/2">
                        <img
                          className=" w-full max-h-[15rem] sm:w-44 sm:h-44 object-cover rounded cursor-pointer"
                          src={item.photo.src}
                          alt={item.photo.alt}
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedPhotoStep(item.photo);
                            setIsPhotoOpen(true);
                          }}
                        />
                      </div>
                      {item.description ? (
                        <div className="w-full md:w-1/2 mt-1 sm:mt-0 sm:p-2">
                          <span className="text-text-black  ">
                            <span className="font-semibold text-primary-300">
                              Descripción:{' '}
                            </span>
                            {item.description}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                )
              : null}
          </div>
        </div>
      </div>
      <StepPhotoModal
        isOpen={isPhotoOpen}
        setIsOpen={setIsPhotoOpen}
        img={selectedPhotoStep}
      />
    </div>
  );
}

export default AppointmentProcessCard;
