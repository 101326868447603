import React from 'react';
import { useRouter } from 'next/router';
import { useUser } from '../../hooks';

interface AuthProps {
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Auth({ setAuth }: AuthProps) {
  const router = useRouter();
  const [user] = useUser();

  React.useEffect(
    function authSecurity() {
      if (user && router.pathname === '/') {
        router.push('/app');
      } else if (user) {
        setAuth(true);
      } else if (
        router.pathname.includes('reset-password') ||
        router.pathname.includes('privacy')
      ) {
        setAuth(false);
      } else {
        router.push('/');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return <div />;
}
