import React from 'react';
import Link from 'next/link';
import { Button } from '@spa-cars/ui';
import { Title } from '../shared';
import ProfileInfoForm from './ProfileInfoForm';
import ProfilePasswordForm from './ProfilePasswordForm';
import UserPhoto from './UserPhoto';

function Profile() {
  return (
    <main className="w-full max-w-[1440px] m-auto px-5 pb-10">
      <section className="flex flex-wrap">
        {/* title */}
        <Title title="Perfil" />
        {/* left side */}
        <div className="w-full lg:w-1/3 order-2 lg:order-1 px-5 lg:px-0">
          <UserPhoto />
        </div>
        {/* right side */}
        <div className="w-full lg:w-2/3 flex flex-wrap px-5 md:px-10 lg:pl-32 order-1 lg:order-">
          <ProfileInfoForm />
          <ProfilePasswordForm />
          <div className="flex w-full md:px-36 lg:px-0 md:w-full lg:w-3/4 flex-col">
            <Link href="/profile/delete" legacyBehavior>
              <Button className="bg-danger-100">Eliminar cuenta</Button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Profile;
