import React from 'react';
import NProgress from 'nprogress';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from '@apollo/client';
import { validateEmail, validatePhone, validateString } from 'avilatek-utils';
import {
  Button,
  Input,
  MailIcon,
  Select,
  UserIcon,
  UpdateIcon,
  SpinnerIcon,
} from '@spa-cars/ui';
import { User } from '@spa-cars/models';
import { useNotify, useUser } from '../../hooks';
import { UPDATE_USER } from '../../graphql/mutations';

function ProfileInfoForm() {
  const notify = useNotify();
  const [user, setUser] = useUser();
  const [updateUser] = useMutation<{ updateUser: { record: User } }>(
    UPDATE_USER
  );
  const [firstName, setFirstName] = React.useState(user?.firstName);
  const [lastName, setLastName] = React.useState(user?.lastName);
  const [dni, setDni] = React.useState(user?.dni);
  const [dniType, setDniType] = React.useState(user?.dniType);
  const [fullPhone, setFullPhone] = React.useState(
    `${user?.phone.code}${user?.phone.phone}`
  );
  const [email, setEmail] = React.useState(user?.email);
  const [disabled, setDisabled] = React.useState(false);

  const handleSubmitProfile = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      NProgress.start();
      if (disabled) {
        return;
      }
      setDisabled(true);
      if (!validateString(firstName) || String(firstName).length > 127) {
        return notify('Su nombre es inválido', 'error');
      }

      if (!validateString(lastName) || String(lastName).length > 127) {
        return notify('Su apellido es inválido', 'error');
      }

      if (!validateEmail(email)) {
        return notify('Su correo electrónico es inválido', 'error');
      }

      if (!validatePhone(fullPhone) || String(fullPhone).length > 12) {
        return notify('Su teléfono es inválido', 'error');
      }

      if (String(dni).length > 8) {
        return notify('Su cédula es inválida', 'error');
      }

      const localPhone = fullPhone.slice(
        fullPhone.length - 10,
        fullPhone.length
      );
      const phoneCode = fullPhone.slice(0, fullPhone.length - 10);

      const { data } = await updateUser({
        variables: {
          record: {
            firstName,
            lastName,
            email: email.toLowerCase(),
            phone: {
              phone: localPhone,
              code: phoneCode,
            },
            dni,
            dniType,
          },
          filter: {
            _id: user._id,
          },
        },
      });
      if (data.updateUser.record) {
        setUser((userInfo) => ({ ...userInfo, ...data.updateUser.record }));
        notify('Se ha actualizado el perfil con exito', 'success');
      } else {
        notify('Ha ocurrido un error', 'error');
      }
    } catch (error) {
      if (error.message.includes('email_1')) {
        notify(`El correo ingresado ya está en uso`, 'error');
      } else if (error.message.includes('dni_1')) {
        notify(`La cédula ingresada ya está en uso`, 'error');
      } else {
        notify(`Error al actualizar perfil: ${error}`, 'error');
      }
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  return (
    <div className="flex w-full md:px-36 lg:px-0  md:w-full lg:w-3/4 flex-col">
      <h4 className="font-semibold text-lg text-primary-300 ">
        Actualizar perfil
      </h4>
      <form onSubmit={handleSubmitProfile} className="py-4">
        <div className="flex flex-col">
          <div className="mb-2">
            <Input
              label="Nombre"
              placeholder="Ingrese su nombre"
              value={firstName}
              className="bg-system-white"
              onChange={(e) => {
                e.preventDefault();
                setFirstName(e.target.value);
              }}
              required
            >
              <UserIcon className="w-5 h-5" />
            </Input>
          </div>
          <div className="mb-2">
            <Input
              label="Apellido"
              placeholder="Ingrese su apellido"
              value={lastName}
              className="bg-system-white"
              onChange={(e) => {
                e.preventDefault();
                setLastName(e.target.value);
              }}
              required
            >
              <UserIcon className="w-5 h-5" />
            </Input>
          </div>
          <div className="my-2">
            <Input
              label="Cédula"
              placeholder="Ingrese su cédula"
              value={dni}
              onChange={(e) => {
                e.preventDefault();
                setDni(e.target.value);
              }}
              className="gap-0 bg-system-white"
              type="number"
              required
            >
              <span className="flex w-4/12 sm:w-2/12 items-center text-neutral-200 ">
                <UserIcon className="w-5 h-5 mr-1" />{' '}
                <Select
                  value={dniType}
                  onChange={(e) => {
                    e.preventDefault();
                    setDniType(
                      e.target.value as 'V' | 'E' | 'J' | 'G' | 'P' | 'N/A'
                    );
                  }}
                  className="border-none w-13 h-6 px-0 py-0"
                >
                  <option value="V">V</option>
                  <option value="E">E</option>
                  <option value="J">J</option>
                  <option value="G">G</option>
                  <option value="P">P</option>
                  <option value="N/A">N/A</option>
                </Select>
              </span>
            </Input>
          </div>
          <div className="my-2">
            <span className="text-neutral-200 text-sm font-medium mb-2 ">
              Número de teléfono
            </span>
            <PhoneInput
              inputStyle={{
                appearance: 'none',
                height: '24px',
                background: 'transparent',
                width: '100%',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: '#A6A6A6',
                borderRadius: '4px',
                minHeight: '40px',
                fontSize: '16px',
                color: '#606060',
                fontWeight: '300',
                backgroundColor: 'white',
              }}
              countryCodeEditable={false}
              value={fullPhone}
              placeholder="Ingrese su teléfono"
              onChange={(phoneNumber) => {
                setFullPhone(phoneNumber);
              }}
            />
          </div>
          <div className="my-2">
            <Input
              label="Correo electrónico"
              placeholder="Ingrese su correo electrónico"
              value={email}
              className="bg-system-white"
              onChange={(e) => {
                e.preventDefault();
                setEmail(e.target.value);
              }}
              required
            >
              <MailIcon className="w-5 h-5 text-neutral-100" />
            </Input>
          </div>
          <Button
            className="my-5 flex justify-center items-center"
            type="submit"
          >
            {disabled ? (
              <SpinnerIcon className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-white" />
            ) : (
              <>
                <UpdateIcon className="w-5 h-5 mr-3" /> Actualizar perfil
              </>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ProfileInfoForm;
