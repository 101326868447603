import React from 'react';
import isoTypo from '../../public/images/isotypo-yellow.png';

interface TitleProps {
  title: string;
  className?: string;
}

const Title = ({ title, className }: TitleProps) => (
  <div className="w-full flex items-center ">
    <img className="w-8 h-6 " src={isoTypo.src} alt="spa-car-logo" />
    <h2
      className={`text-2xl md:text-3xl text-neutral-400 font-semibold ml-5 ${className}`}
    >
      {title}
    </h2>
  </div>
);

export default Title;
