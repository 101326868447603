import React from 'react';
import nProgress from 'nprogress';
import { useMutation } from '@apollo/client';
import { CarIcon, EditIcon } from '@spa-cars/ui';
import { Vehicle } from '@spa-cars/models';
import { UNTANGLE_VEHICLE } from '../../graphql/mutations';
import { CTACard, Title } from '../shared';
import { useNotify, useUser } from '../../hooks';
import CarCard from './card/CarCard';
import Empty from '../shared/Empty';
import NewCarModalWrapper from '../appointment/CreateAppointment/MyCars/NewCarModal/NewCarModalWrapper';

interface CarsProps {
  cars: Vehicle[];
}

export default function Cars({ cars }: CarsProps) {
  const notify = useNotify();
  const [user] = useUser();
  const [myCars, setMyCars] = React.useState<Vehicle[]>(cars);
  const [newCarModalIsOpen, setNewCarModalIsOpen] = React.useState(false);
  const [editing, setEditing] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);

  const [untangleVehicle] = useMutation(UNTANGLE_VEHICLE);

  const handleUntangleVehicle = async (vehicleId: string) => {
    setDeleting(true);
    if (deleting) return;
    nProgress.start();

    if (!vehicleId) {
      return notify('No se pudo eliminar el vehículo', 'error');
    }
    try {
      const { data } = await untangleVehicle({
        variables: {
          data: {
            vehicleId,
            userId: user._id,
          },
        },
      });
      if (data.untangleVehicle) {
        notify('Vehículo eliminado', 'success');
        setMyCars(myCars.filter((car) => car._id !== vehicleId));
      }
    } catch {
      notify('No se pudo eliminar el vehículo', 'error');
    } finally {
      setDeleting(false);
      nProgress.done();
    }
  };

  return (
    <>
      <main className="w-full max-w-[1440px] m-auto px-5 pb-10">
        <CTACard
          text="Si aún no tienes vehículos ingresados, regístralo ahora y agenda tu cita"
          icon={<CarIcon className="w-5 h-5 mr-2 text-white" />}
          actionTitle=" Registra tu vehículo"
          action={() => {
            setNewCarModalIsOpen(true);
          }}
        />
        <section className="w-full flex flex-wrap mt-8">
          {/* title */}
          <div className="flex justify-center">
            <Title title="Mis autos" />
            <button
              type="button"
              className="text-primary-500 font-medium text-lg ml-4"
              aria-label="edit"
              onClick={(e) => {
                e.preventDefault();
                setEditing(!editing);
              }}
            >
              <EditIcon
                className={`h-6 transition-all duration-300 ${
                  editing ? 'text-primary-300 -rotate-45' : ' text-neutral-300'
                }`}
              />
              <span className="sr-only">Editar</span>
            </button>
          </div>

          <div className="flex w-full flex-wrap">
            {myCars.length > 0 ? (
              myCars.map((car) => (
                <CarCard
                  key={car._id}
                  car={car}
                  setMyCars={setMyCars}
                  editing={editing}
                  handleUntangleVehicle={handleUntangleVehicle}
                />
              ))
            ) : (
              <Empty title="Agrega un vehículo" className="w-56 h-56" />
            )}
          </div>
        </section>
      </main>
      <NewCarModalWrapper
        isOpen={newCarModalIsOpen}
        setIsOpen={setNewCarModalIsOpen}
        setMyCars={setMyCars}
        myCars={myCars}
      />
    </>
  );
}
