/* eslint-disable no-plusplus */
import React from 'react';
import dayjs from 'dayjs';
import NProgress from 'nprogress';
import {
  DateInput,
  SelectTime,
  DriverForm,
  Button,
  ByMyCarIcon,
  Alert,
  carDiagnosticReducer,
  EditVehicleSpecs,
} from '@spa-cars/ui';
import { useMutation } from '@apollo/client';
import { validateString } from 'avilatek-utils';
import { useRouter } from 'next/router';
import {
  Appointment,
  Location,
  Vehicle,
  TDiagnosticCar,
} from '@spa-cars/models';
import {
  CREATE_APPOINTMENT,
  CREATE_NEXT_BLOCK_APPOINTMENT,
  UPDATE_VEHICLE,
} from '../../../graphql/mutations';
import { useAvailableSlots, useNotify, useUser } from '../../../hooks';
import { FadeAnimation, Title } from '../../shared';
import MyCars from './MyCars/MyCars';
import LocationSelect from './LocationSelect';
import CarDiagnosticModalWrapper from './CarDiagnosticModalWrapper';
import FlushInput from './FlushInput';

const today = dayjs();

const flushTextAlert =
  'El flush es una limpieza interna al motor permites que toda la suciedad que se ha acumulado en el salga y quede libre de cualquier impureza.';
interface CreateAppointmentProps {
  locations: Location[];
  vehicles: Vehicle[];
  withFlush?: boolean;
}

export default function CreateAppointment({
  locations,
  vehicles,
  withFlush = false,
}: CreateAppointmentProps) {
  const notify = useNotify();
  const router = useRouter();
  const [user] = useUser();

  const [state, dispatch] = React.useReducer(carDiagnosticReducer, {});
  const [disabled, setDisabled] = React.useState(false);
  const [date, setDate] = React.useState(
    today.day() === 0
      ? today.add(1, 'day').format('DD/MM/YYYY')
      : today.format('DD/MM/YYYY')
  );
  const [time, setTime] = React.useState<string[]>([]);
  const [shop, setShop] = React.useState('');
  const [car, setCar] = React.useState((router?.query?.carId as string) ?? '');
  const [name, setName] = React.useState(`${user.firstName} ${user.lastName}`);
  const [dni, setDni] = React.useState(user.dni);
  const [dniType, setDniType] = React.useState(user.dniType);
  const [carSpecs, setCarSpecs] = React.useState<Partial<TDiagnosticCar>>({});
  const [editSpecs, setEditSpecs] = React.useState(false);
  const [serviceWithFlush, setServiceWithFlush] = React.useState(withFlush);
  const [flushDisable, setFlushDisable] = React.useState(false);
  const [carDiagnosticModalIsOpen, setCarDiagnosticModalIsOpen] =
    React.useState(false);

  const [cars, setCars] = React.useState(vehicles);

  const [slots, loadingGetAvailableSlots] = useAvailableSlots(
    shop,
    date,
    user._id
  );
  const [createAppointment] = useMutation<{
    createAppointment: Appointment;
  }>(CREATE_APPOINTMENT);

  const [createNextBlockAppointment] = useMutation<{
    createNextBlockAppointment: { success: boolean };
  }>(CREATE_NEXT_BLOCK_APPOINTMENT);

  const [updateVehicle] = useMutation<{
    updateVehicle: { record: Vehicle; recordId: string };
  }>(UPDATE_VEHICLE);

  const stringToDate = (value: string) => {
    const dateParts = value.split('/');
    return new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);
  };

  React.useEffect(() => {
    // if the car doesn't have specs you have to do a diagnostic
    function verifyCarDiagnostic() {
      if (car) {
        const carSelected = vehicles.find((v) => v._id === car);
        if (
          !carSelected?.specs?.oilViscosity ||
          !carSelected?.specs?.oilOrigin
        ) {
          // there is a bug with a consecutive modals opening
          // if you create a car and after open diagnostic modal scroll bar disapears for that reason you have to wait a little to open next modal
          setTimeout(() => setCarDiagnosticModalIsOpen(true), 500);
        } else {
          setServiceWithFlush(false);
          setFlushDisable(false);
          setCarSpecs({ specs: carSelected?.specs });
        }
      }
    }
    verifyCarDiagnostic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [car]);

  React.useEffect(() => {
    setTime([]);
  }, [date]);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      NProgress.start();
      if (disabled) {
        return;
      }
      setDisabled(true);

      if (!validateString(name) || String(name).length > 127) {
        return notify('Su nombre es inválido', 'error');
      }

      if (String(dni).length > 8) {
        return notify('Su cédula es inválida', 'error');
      }

      if (!validateString(shop)) {
        return notify('Sede inválida', 'error');
      }

      if (!validateString(car)) {
        return notify('Seleccione un vehículo', 'error');
      }

      if (!validateString(date)) {
        return notify('Seleccione una fecha', 'error');
      }

      if (!validateString(time[0])) {
        return notify(
          'Seleccione un horario para la fecha seleccionada',
          'error'
        );
      }

      if (serviceWithFlush && time.length !== 2) {
        return notify('Debe selecionar dos bloques para hacer flush ', 'error');
      }

      if (!serviceWithFlush && time.length !== 1) {
        return notify(
          'Debe selecionar un solo bloque para hacer el servicio ',
          'error'
        );
      }

      const timeParts = time[0].split(':');
      const partOfDay = timeParts[1].split(' ')[1]; // AM || PM

      const dateObject = stringToDate(date);

      dateObject.setHours(
        partOfDay === 'PM'
          ? parseInt(timeParts[0]) + 12
          : parseInt(timeParts[0])
      );
      dateObject.setMinutes(parseInt(timeParts[1]));

      const error = await handleUpdateVehicle();

      if (error) {
        return;
      }

      const { data } = await createAppointment({
        variables: {
          data: {
            date: new Date(dateObject).toISOString(),
            vehicle: car,
            location: shop,
            user: user._id,
            driver: {
              name,
              dni,
              dniType,
            },
          },
        },
      });

      const route = {
        pathname: `/payment/${data.createAppointment._id}/${shop}`,
        query: { car },
      };

      if (serviceWithFlush) {
        const { data: nextBlock } = await createNextBlockAppointment({
          variables: { data: { appointment: data.createAppointment._id } },
        });
        if (
          data.createAppointment._id &&
          nextBlock.createNextBlockAppointment
        ) {
          router.push(route);
        } else {
          notify('Ha ocurrido un error', 'error');
        }
      } else if (data.createAppointment) {
        router.push(route);
      } else {
        notify('Ha ocurrido un error', 'error');
      }
    } catch (error) {
      notify(`Error al al crear la cita: ${error}`, 'error');
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  const handleUpdateVehicle = async () => {
    try {
      const carSelected = cars.find((v) => v._id === car);
      const needDiagnostic =
        !carSelected?.specs?.oilViscosity || !carSelected?.specs?.oilOrigin;

      const carDiagnosticated =
        carSpecs?.specs?.oilViscosity && carSpecs?.specs?.oilOrigin;

      if (needDiagnostic && !carDiagnosticated)
        return notify(
          'Debe realizar un diagnostico para poder continuar',
          'error'
        );

      // updateCar
      if ((needDiagnostic && carDiagnosticated) || editSpecs) {
        const { data: updateCar } = await updateVehicle({
          variables: {
            record: {
              ...(carSpecs?.oilChangeType
                ? { oilChangeType: carSpecs?.oilChangeType }
                : {}),
              ...(carSpecs?.oilChangeType
                ? { oilChangeTypeValue: carSpecs?.oilChangeType }
                : {}),
              specs: {
                ...carSpecs.specs,
                oilQuantity: Number(carSpecs?.specs?.oilQuantity),
                __typename: undefined,
              },
            },
            filter: {
              _id: carSelected?._id,
            },
          },
        });

        if (!updateCar?.updateVehicle?.record?._id) {
          notify('Ha ocurrido un error', 'error');
          return 'error';
        }
      }
    } catch (error) {
      notify(`Error al actualizar el vehículo: ${error}`, 'error');
      return 'error';
    }
  };

  React.useEffect(() => {
    const carSelected = cars.find((v) => v._id === car);
    if (
      !state.rememberYourOil &&
      !carSelected?.specs?.oilViscosity &&
      !carSelected?.specs?.oilOrigin
    ) {
      setFlushDisable(true);
      setServiceWithFlush(true);
    } else {
      setFlushDisable(false);
      setServiceWithFlush(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.rememberYourOil]);

  return (
    <main className="w-full max-w-[1440px] m-auto px-5 pb-10  relative">
      <section className="flex flex-wrap ">
        {/* left side */}
        <div className="w-full lg:w-5/12 px-5 lg:px-0 relative ">
          {/* title */}
          <Title title="Crea una reserva" />
          <MyCars
            cars={cars}
            carSelected={car}
            setCar={setCar}
            setCars={setCars}
          />
          <FlushInput
            serviceWithFlush={serviceWithFlush}
            setServiceWithFlush={setServiceWithFlush}
            disabled={flushDisable}
          />
          {carDiagnosticModalIsOpen ? (
            <CarDiagnosticModalWrapper
              state={state}
              dispatch={dispatch}
              isOpen={carDiagnosticModalIsOpen}
              setIsOpen={setCarDiagnosticModalIsOpen}
              carSelected={cars.find((v) => v._id === car) ?? ({} as Vehicle)}
              setCarSpecs={setCarSpecs}
            />
          ) : null}
          {car ? (
            <EditVehicleSpecs
              vehicleSpecs={carSpecs}
              setVehicleSpecs={setCarSpecs}
              edit={editSpecs}
              setEdit={setEditSpecs}
            />
          ) : null}
        </div>
        {/* right side */}
        <div className="w-full lg:w-7/12 px-5 md:px-10 lg:pl-32 flex flex-col">
          <LocationSelect locations={locations} shop={shop} setShop={setShop} />
          {car && shop ? (
            <FadeAnimation>
              <Alert
                show={serviceWithFlush}
                title="Servicio de Flush incluido, tiempo 1 hora"
                text={flushTextAlert}
              />
              <h4 className="text-primary-300 font-semibold text-lg my-5 mt-3">
                Seleccione el día de su reserva
              </h4>
              <div className="w-full md:w-3/4">
                <DateInput
                  open
                  size="medium"
                  value={date}
                  setState={(value) => {
                    setDate(value);
                  }}
                />
              </div>
              <h4 className="font-semibold text-lg text-primary-300 mt-6">
                Seleccione una hora
              </h4>
              {shop && date ? (
                <SelectTime
                  time={time}
                  setTime={setTime}
                  hourSlots={slots}
                  loadingReservations={loadingGetAvailableSlots}
                  withFlush={serviceWithFlush}
                />
              ) : (
                <div className="flex w-full flex-col">
                  <ByMyCarIcon className="w-36 h-36  mx-auto" />
                  <span className="mx-auto text-center">
                    Selecciona día y tienda para conocer la disponibilidad de
                    horas
                  </span>
                </div>
              )}
              <DriverForm
                name={name}
                dni={dni}
                dniType={dniType}
                setName={setName}
                setDni={setDni}
                setDniType={setDniType}
              />
              <div className="my-3 sm:my-0 w-full sm:w-1/2 md:w-1/3 ml-auto">
                <Button onClick={handleSubmit}>Continuar</Button>
              </div>
            </FadeAnimation>
          ) : (
            <div className="my-auto">
              <Alert
                title="Seleccionar fecha y hora"
                text="Para poder visualizar las fechas y horas disponibles seleccione su vehículo y sede"
                show
              />
            </div>
          )}
        </div>
      </section>
    </main>
  );
}
