import { v4 as uuid } from 'uuid';

export const termAndConditionsBullets = [
  { _id: uuid(), text: 'La eliminación se realiza de forma inmediata' },
  {
    _id: uuid(),
    text: 'Al eliminar tu usuario, se eliminarán todos los datos relacionados que no sean requiramos mantener.',
  },
  {
    _id: uuid(),
    text: 'Al eliminar tu usuario NO se eliminará el registro de las órdenes realizadas en la plataforma por razones legales.',
  },
  {
    _id: uuid(),
    text: 'Al eliminar tu usuario, no podrás volver a iniciar sesión en la plataforma de Spacars. Si deseas usar nuestros servicios en un futuro, tendrás que registrarte nuevamente.',
  },
  {
    _id: uuid(),
    text: 'Cualquier cupón, descuento, etc. asociados a tu cuenta serán eliminados y no podrán ser recuperados de ninguna manera.',
  },
];
