import React from 'react';
import dayjs from 'dayjs';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  TikTokIcon,
  AvilaIcon,
  YoutubeIcon,
} from '@spa-cars/ui';
import logoLight from '../../public/images/logo-light.png';

interface FooterProps {
  scrollToSection: (idx: number) => any;
}

function Footer({ scrollToSection }: FooterProps) {
  return (
    <footer className="w-full relative bg-white">
      <div className="absolute z-20 top-0 w-full h-10 bg-white rounded-b-3xl -mt-1" />
      <div className="relative z-10 bg-primary-300 pt-20 flex flex-col px-5">
        <div className="flex items-center justify-center gap-4 flex-wrap">
          <div className="w-full sm:w-auto flex- justify-center">
            <img className="h-12 mx-auto" src={logoLight.src} alt="logo" />{' '}
          </div>
          <hr className="h-8 border-none w-[1px] bg-white hidden sm:block " />{' '}
          <div className="flex items-center w-full sm:w-auto justify-center">
            <a
              className="cursor-pointer"
              href="https://www.facebook.com/Spacars-Venezuela-103270769263523/"
            >
              <FacebookIcon className="w-9 h-9 text-white" />
            </a>
            <a className="cursor-pointer" href="https://twitter.com/SpacarsVE">
              <TwitterIcon className="w-8 h-8 text-white" />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.instagram.com/spacars_ve/?igshid=MDE2OWE1N2Q%3D"
            >
              <InstagramIcon className="w-8 h-8 text-white" />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.tiktok.com/@spacars_ve?_t=8WahlrwiGer&_r=1"
            >
              <TikTokIcon className="w-7 h-7 ml-1 text-white" />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.youtube.com/channel/UC9qZYXAgGFrHB5yo2i8aVuQ/featured"
            >
              <YoutubeIcon className="w-7 h-7 ml-1 text-white" />
            </a>
          </div>
        </div>
        <div className="flex w-full py-10">
          <div className="flex gap-10 mx-auto text-white text-lg font-light flex-nowrap flex-col lg:flex-row ">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(0);
              }}
            >
              Inicio
            </button>
            <hr className="hidden lg:block h-8 border-none w-[1px] bg-white " />{' '}
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(1);
              }}
            >
              Guías
            </button>
            <hr className="hidden lg:block  h-8 border-none w-[1px] bg-white " />{' '}
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(2);
              }}
            >
              Puntos de revisión
            </button>
            <hr className="hidden lg:block  h-8 border-none w-[1px] bg-white " />{' '}
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(3);
              }}
            >
              Historia
            </button>
            <hr className="hidden lg:block  h-8 border-none w-[1px] bg-white " />{' '}
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(4);
              }}
            >
              Sobre nososotros
            </button>
            <hr className="hidden lg:block  h-8 border-none w-[1px] bg-white " />{' '}
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(5);
              }}
            >
              Valores
            </button>
          </div>
        </div>
        <div className="flex  py-5 ">
          <p className="w-full md:w-1/2  mx-auto text-center text-white font-light ">
            Repensando, innovando, transformando y creando el futuro juntos
          </p>
        </div>
        <div className="mx-auto pt-10 flex text-white gap-2">
          <span className="text-white font-light flex items-center">
            © {dayjs().year()} SERVICIOS Y MANTENIMIENTOS SPACARS C.A. |
            Powered by{' '}
            <a
              target="_blank"
              href="https://www.avilatek.com/"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <AvilaIcon className="w-8 h-8 mx-2  hover:text-[#42B87D] items-center" />
              Avila Tek
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
