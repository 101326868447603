export const ACTIONS = {
  BRANDS: 'BRANDS',
  MODELS: 'MODELS',
  LOADING_MODELS: 'LOADING_MODELS',
  VERSIONS: 'VERSIONS',
  LOADING_VERSIONS: 'LOADING_VERSIONS',
  YEARS: 'YEARS',
  LOADING_YEARS: 'LOADING_YEARS',
  BRAND_ID: 'BRAND_ID',
  MODEL_ID: 'MODEL_ID',
  VERSION_ID: 'VERSION_ID',
  YEAR: 'YEAR',
  PLATE: 'PLATE',
  ENGINE_SERIAL: 'ENGINE_SERIAL',
  CHANGE_ENGINE_OIL_BY: 'CHANGE_ENGINE_OIL_BY',
  CHANGE_ENGINE_OIL_FREQUENCY: 'CHANGE_ENGINE_OIL_FREQUENCY',
  TIRES: 'TIRES',
  PSI: 'PSI',
  TOKEN: 'TOKEN',
  DEFAULT: 'DEFAULT',
} as const;
