import React from 'react';
import { LocationIcon } from '@spa-cars/ui/src';
import Link from 'next/link';
import { Multimedia, Address } from '@spa-cars/models';
import pdvImage from '../../public/images/shop-1.jpg';

interface ShopCardProps {
  name: string;
  address: Address;
  id: string;
  img: Multimedia;
}

function ShopCard({ name, address, img, id }: ShopCardProps) {
  return (
    <Link href={`/shop/${id}`}>
      <div className="  w-full h-28  md:h-36 bg-system-white rounded-xl p-3 flex my-3 ">
        <div className="w-1/3 h-30 sm:h-full">
          <img
            className={`w-full h-full rounded-lg relative object-cover  `}
            src={img?.src ?? pdvImage.src}
            alt={img?.alt ?? 'shop-img'}
          />
        </div>
        <div className="flex h-full w-2/3 items-center justify-center sm:justify-start  pl-3 sm:pl-10 ">
          <div className="">
            <h4 className="text-lg sm:text-xl  text-text-black font-semibold text-center sm:text-left">
              {name}
            </h4>
            <span className="flex mt-1 text-neutral-100 items-center justify-center sm:justify-start text-center text-xs sm:text-base sm:text-left">
              <LocationIcon className="w-5 h-5 mr-2" />
              {`${address.municipality} ${
                address?.parish ? `, ${address?.parish}` : ''
              }`}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ShopCard;
