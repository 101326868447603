/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Vehicle } from '@spa-cars/models';
import NewCarModalWrapper from '../../appointment/CreateAppointment/MyCars/NewCarModal/NewCarModalWrapper';
import UntangleButton from '../UntangleButton';
import LowerCard from './LowerCard';
import UpperCard from './UpperCard';

interface CardCars {
  car: Vehicle;
  setMyCars: React.Dispatch<React.SetStateAction<Vehicle[]>>;
  handleUntangleVehicle: (vehicleId: string) => void;
  editing: boolean;
}

export default function CarCard({
  car,
  setMyCars,
  handleUntangleVehicle,
  editing,
}: CardCars) {
  const [newCarModalIsOpen, setNewCarModalIsOpen] = React.useState(false);

  return (
    <div className="w-full sm:w-1/2 lg:w-1/4 p-5 relative">
      <div
        className="bg-white rounded-lg shadow-sm px-5 py-6 flex flex-col cursor-pointer transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
        onClick={(e) => {
          e.preventDefault();
          setNewCarModalIsOpen(true);
        }}
      >
        <UpperCard car={car} />
        <hr />
        <LowerCard car={car} />
      </div>
      {editing ? (
        <UntangleButton vehicleId={car._id} onClick={handleUntangleVehicle} />
      ) : null}
      {newCarModalIsOpen ? (
        <NewCarModalWrapper
          isOpen={newCarModalIsOpen}
          setIsOpen={setNewCarModalIsOpen}
          setMyCars={setMyCars}
          car={car}
        />
      ) : null}
    </div>
  );
}
