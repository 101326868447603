/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PaymentInfo, PaymentSummary, Alert } from '@spa-cars/ui';
import {
  Product,
  Shopcart as ShopCart,
  Currency,
  Discount,
  Appointment,
  Client,
} from '@spa-cars/models';
import { DELETE_FROM_SHOPCART, APPLY_DISCOUNT } from '../../graphql/mutations';
import { useNotify, useUser } from '../../hooks';
import { ACTIONS, InitialState, reducer, TTotal } from './reducer';
import { getVariantsOptions } from '../../lib/variantsOptions';
import { useShopCart } from '../../hooks/useShopCart';
import { GET_SHOPCART_TALLY } from '../../graphql/queries';
import { useShopcartTally } from '../../hooks/useShopcartTally';
import isoTypo from '../../public/images/isotypo-yellow.png';
import PaymentBtn from './PaymentButtons/PaymentBtn';
import SelectProductsWrapper from './SelectProductsWrapper';
import { FadeAnimation } from '../shared';

interface PaymentProps {
  appointment: Appointment;
  engineOils: Product[];
  hydraulicOils?: Product[];
  wiperWashers: Product[];
  service: Product;
  flushes: Product[];
  oilFilters: Product[];
  currencies: Currency[];
}

function Payment({
  appointment,
  engineOils,
  service,
  hydraulicOils,
  wiperWashers,
  currencies,
  flushes,
  oilFilters,
}: PaymentProps) {
  const notify = useNotify();
  const loaded = React.useRef(false);
  const [shopCart] = useShopCart();
  const [user] = useUser();
  const [state, dispatch] = React.useReducer(reducer, {
    ...InitialState,
    engineOilOptions: getVariantsOptions(engineOils ?? []),
    hydraulicOilOptions: getVariantsOptions(hydraulicOils ?? []),
    wiperWasherOptions: getVariantsOptions(wiperWashers ?? []),
    flushOptions: getVariantsOptions(flushes ?? []),
    oilFilterOptions: getVariantsOptions(oilFilters ?? []),
    withFlush: !!appointment?.services?.find((s) => s.name === 'flush'),
    allProducts: [
      ...(hydraulicOils ?? []),
      ...wiperWashers,
      ...engineOils,
      ...flushes,
      ...oilFilters,
      service,
    ],
  });

  const [deleteFromShopcart] = useMutation<{
    deleteFromShopcart: ShopCart;
  }>(DELETE_FROM_SHOPCART);

  const [applyDiscount] = useMutation<{
    applyDiscount: Discount;
  }>(APPLY_DISCOUNT);

  const [getShopcartTally, { error: errorShopcartTally }] = useLazyQuery<{
    getShopcartTally: TTotal;
  }>(GET_SHOPCART_TALLY, {
    fetchPolicy: 'network-only',
  });

  const total = useShopcartTally({
    shopcartProducts: state.shopcartProducts,
    appliedDiscounts: state.appliedDiscounts,
  });

  React.useEffect(() => {
    async function initOrder() {
      // delete all products from shopcart
      await clearShopcart();
      // verify if the service is in the shorcart or add it
      addServiceToShopcart();
    }
    if (!loaded.current) {
      loaded.current = true;
      initOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // recalculate total when shopcart changes
  React.useEffect(() => {
    // get total when shopcart was confirmed
    async function syncTally() {
      try {
        const promoCodes = state.appliedDiscounts.map((item) => item.code);
        const { data } = await getShopcartTally({
          variables: {
            data: {
              shopcart: shopCart._id,
              promoCodes,
            },
          },
        });
        if (data?.getShopcartTally) {
          dispatch({ type: ACTIONS.TOTAL, payload: data?.getShopcartTally });
        } else if (errorShopcartTally) {
          notify(`${errorShopcartTally}`, 'error');
        }
      } catch (error) {
        notify(`Error: ${error}`, 'error');
      }
    }

    if (!state.confirmedForm) {
      dispatch({ type: ACTIONS.TOTAL, payload: total });
    } else {
      syncTally();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, state.confirmedForm]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  // get the discount y push to state, total is recalculate
  const handleApplyDiscount = async () => {
    try {
      const wasApplied = state.appliedDiscounts.find(
        (discount) => discount.code === state.discountCode
      );
      if (state?.discountCode && !wasApplied) {
        const { data, errors } = (await applyDiscount({
          variables: {
            data: {
              code: state?.discountCode,
              client: (user?.client as Client)._id,
            },
          },
        })) as any;

        if (errors && errors?.length > 0) {
          return notify(
            `Error al aplicar la promoción: ${errors[0].message}`,
            'error'
          );
        }

        dispatch({
          type: ACTIONS.DISCOUNT_CODE,
          payload: '',
        });
        dispatch({
          type: ACTIONS.APPLIED_DISCOUNTS,
          payload: [data.applyDiscount],
        });

        // update local storage
        localStorage.setItem(
          'promoCodes',
          JSON.stringify({ promoCodes: state.appliedDiscounts })
        );

        notify('Promoción aplicada', 'success');
      } else {
        notify(`Promoción ya aplicada `, 'error');
      }
    } catch (error) {
      notify(`Promoción inválida: ${error}`, 'error');
    }
  };

  /**
   *@description: add obligatory service top shopcart
   */
  async function addServiceToShopcart() {
    const products = [];
    // agregar el servicio´
    if (service?._id && service?.variants?.length > 0) {
      const _service = {
        product: service,
        variant: service.variants[0],
        quantity: 1,
      };
      products.push(_service);
    }

    // agregar los productos seleccionados al carrito local
    dispatch({
      type: ACTIONS.SHOPCART_PRODUCTS,
      payload: [...state.shopcartProducts, ...products],
    });
  }

  /**
   *@description: clear shopcart to start a new order
   */
  async function clearShopcart() {
    try {
      if (shopCart.items.length > 0) {
        dispatch({ type: ACTIONS.LOADING_SHOPCART, payload: true });
        await deleteFromShopcart({
          variables: {
            data: {
              shopcartId: shopCart?._id,
              items: shopCart.items.map((item) => ({
                orderProductId: item._id,
              })),
            },
          },
        });
        dispatch({ type: ACTIONS.LOADING_SHOPCART, payload: false });
      }
    } catch (error) {
      notify(
        'Error al vaciar el carrito, intentelo nuevamente refrescando la página',
        'error'
      );
    }
  }

  return (
    <main className="w-full max-w-[1440px] m-auto px-5 pb-10">
      <section className="w-full flex flex-wrap">
        <div className="w-full flex items-center ">
          <img className="w-8 h-6 " src={isoTypo.src} alt="spa-car-logo" />
          <h2 className="text-2xl md:text-3xl text-neutral-400 font-bold  ml-5">
            Resumen de pago
          </h2>
        </div>
        <div className="my-2">
          <Alert
            show
            title="¡Atención!"
            text="El bloque de horario seleccionado es reservado durante 15 minutos, tiempo que dispone para completar el pago  y finzalizar su reserva."
          />
        </div>
        {/* left side */}
        <div className="w-full sm:px-10 lg:w-1/2 xl:w-3/4 ">
          {state.confirmedForm ? (
            <FadeAnimation>
              <PaymentInfo appointment={appointment} />
            </FadeAnimation>
          ) : null}
          <SelectProductsWrapper
            state={state}
            dispatch={dispatch}
            engineOils={engineOils}
            hydraulicOils={hydraulicOils}
            wiperWashers={wiperWashers}
            flushes={flushes}
            appointment={appointment}
            oilFilters={oilFilters}
          />
        </div>
        <div className="w-full px-5 mt-10 sm:px-10 sm:mt-5 lg:px-0 lg:w-1/2 xl:w-1/4">
          {/* right side */}
          <PaymentSummary
            appointment={appointment}
            discountCode={state.discountCode}
            loadingShopCart={state.loadingShopCart}
            handleChange={handleChange}
            total={state.total}
            localCurrency={currencies.find((item) => item.localCoin)}
            handleApplyDiscount={handleApplyDiscount}
            items={state.shopcartProducts}
            appliedDiscounts={state.appliedDiscounts}
            confirmedForm={state.confirmedForm}
          />
          {/* payment btn */}
          <PaymentBtn
            state={state}
            dispatch={dispatch}
            appointment={appointment}
            localCurrency={currencies.find((item) => item.localCoin)}
          />
        </div>
      </section>
    </main>
  );
}

export default Payment;
