import React from 'react';
import Link from 'next/link';
import { LocationIcon } from '@spa-cars/ui/src';
import { Address } from '@spa-cars/models';

interface ShopInfoProps {
  name: string;
  description: string;
  address: Address;
}

function ShopInfo({ name, address, description }: ShopInfoProps) {
  return (
    <>
      <div className="w-3/4">
        {/* title */}
        <h1 className="text-4xl text-text-black font-semibold">{name} </h1>
        {/* location */}
        <span className="flex mt-2 text-neutral-100 items-center">
          <LocationIcon className="w-5 h-5 mr-2" />
          {address.municipality}, {address.parish ?? ''}
        </span>
      </div>
      {/* space to rating */}
      {/* <div className="w-1/4">Rating</div> */}
      <div className="w-full mt-3">
        {/* map btn */}
        <div className="relative w-full sm:w-[22rem] h-16 bg-[url('/images/map-rectangle.png')] bg-cover rounded-lg flex justify-center items-center">
          <Link
            href="/map"
            className=" bg-white px-2 py-1 rounded-xl text-primary-300 font-medium tracking-wide"
          >
            Ver mapa
          </Link>
        </div>
      </div>
      {/* description */}
      <div className="w-full md:w-10/12 mt-5  flex-col ">
        <span className="text-primary-300 font-semibold text-lg">
          Descripción
        </span>
        <div
          className="mt-3"
          dangerouslySetInnerHTML={{
            __html: description ?? '',
          }}
        />
      </div>
    </>
  );
}

export default ShopInfo;
