/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import NProgress from 'nprogress';
import { useMutation } from '@apollo/client';
import { validateNumber, validateString } from 'avilatek-utils';
import { CarModal } from '@spa-cars/ui';
import CarTokenModal from '@spa-cars/ui/src/appointment/MyCars/CarTokenModal/CarTokenModal';
import { Client, Vehicle } from '@spa-cars/models';
import {
  CREATE_VEHICLE,
  LINK_VEHICLE,
  UPDATE_VEHICLE,
} from '../../../../../graphql/mutations';
import { ACTIONS, createCarModalInitialState } from './reducer';
import { useNotify, useUser } from '../../../../../hooks';
import { useVerifyPlate } from './hooks/useVerifyPlate';
import {
  useBrands,
  useLazyModels,
  useLazyVersions,
  useLazyYears,
} from './hooks';

// Images
import modelImg from '../../../../../public/images/Modelo.png';
import brandImg from '../../../../../public/images/Marca.png';
import plateImg from '../../../../../public/images/Placa.png';
import serialImg from '../../../../../public/images/Serial.png';
import versionImg from '../../../../../public/images/Version.png';
import yearImg from '../../../../../public/images/year.png';
import byMyCar from '../../../../../public/images/byMyCar-service.png';

interface NewCarModalWrapper {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMyCars?: React.Dispatch<React.SetStateAction<Vehicle[]>>;
  setCar?: React.Dispatch<React.SetStateAction<string>>;
  car?: Vehicle;
  myCars?: Vehicle[];
}

export default function NewCarModalWrapper({
  isOpen,
  setIsOpen,
  setMyCars = () => {},
  setCar,
  myCars,
  car,
}: NewCarModalWrapper) {
  const notify = useNotify();
  const [user] = useUser();
  const [disabled, setDisabled] = React.useState(false);
  const [carTokenModalIsOpen, setCarTokenModalIsOpen] = React.useState(false);
  const [carToLink, setCarToLink] = React.useState<Partial<Vehicle>>();

  const referenceImages = [
    brandImg.src,
    modelImg.src,
    yearImg.src,
    versionImg.src,
    plateImg.src,
    serialImg.src,
    byMyCar.src,
  ];

  const [createVehicle] = useMutation<{ createVehicle: Vehicle }>(
    CREATE_VEHICLE
  );

  const [updateVehicle] = useMutation<{
    updateVehicle: { record: Vehicle; recordId: string };
  }>(UPDATE_VEHICLE);

  const [linkVehicleMutation] = useMutation<{ linkVehicle: Vehicle[] }>(
    LINK_VEHICLE
  );

  // Apollo hooks for brand, models, years and versions
  const brands = useBrands({
    car: car ?? null,
    duplicateVehicle: false,
    myCars,
  });
  const [loadModels, models] = useLazyModels();
  const [loadVersions, versions] = useLazyVersions();
  const loadYears = useLazyYears();

  // apollo hook for verify plate
  const [verifyPlate, compareToken] = useVerifyPlate({
    myCars,
    setIsOpen,
  });

  // create or update a car
  const handleSubmit = async (state: any, dispatch: any) => {
    try {
      NProgress.start();
      if (disabled) {
        return;
      }

      // verificar que el vehiculo ya no este registrado por otra persona
      const verifiedCar = await verifyPlate(state.plate);
      if (!!verifiedCar?.owner && verifiedCar?.owner !== user?._id) {
        setIsOpen(false);
        setCarTokenModalIsOpen(true);
        setCarToLink(verifiedCar);
        return;
      }

      // registered car but not linked to any client, so we link it
      if (verifiedCar?.owner === null && !!verifiedCar?._id) {
        return handleLinkVehicle(verifiedCar, state);
      }

      setDisabled(true);
      if (!validateString(state.brandId)) {
        return notify('La marca es invalida', 'error');
      }
      if (!validateString(state.modelId)) {
        return notify('El modelo es invalido', 'error');
      }
      if (!validateNumber(parseInt(state.year))) {
        return notify('El año es invalido', 'error');
      }
      if (!validateString(state.plate) || String(state.plate).length > 127) {
        return notify('La placa es invalida', 'error');
      }
      if (String(state.engineSerial).length > 127) {
        return notify('El serial es invalido', 'error');
      }
      if (!validateString(state.psi)) {
        return notify('Los PSI de los cauchos es invalido', 'error');
      }
      if (!validateString(state.tires)) {
        return notify(
          'El gas para el llenado de los cauchos es invalido',
          'error'
        );
      }
      if (!validateString(state.versionId)) {
        return notify('La versión es invalida', 'error');
      }

      const { brandId, modelId, versionId } = state;
      const brand = brands.find((item) => item._id === brandId);
      const model = models.find((item) => item._id === modelId);
      const version = versions.find((item) => item._id === versionId);

      const vehicleData = {
        brand: brand._id,
        model: model._id,
        version: version._id,
        plate: state.plate.toUpperCase(),
        engineSerial: state.engineSerial,
        owner: user._id,
        specs: {
          psi: state.psi,
          tireFiller: state.tires,
        },
      };

      if (!car) {
        const { data } = await createVehicle({
          variables: {
            data: {
              ...vehicleData,
            },
          },
        });
        if (data.createVehicle) {
          notify('Se ha registrado el vehículo exitosamnete', 'success');
          setMyCars((cars) => [...cars, data.createVehicle]);
          setIsOpen(false);
          if (setCar) {
            setCar(data.createVehicle._id);
          }
          dispatch({
            type: ACTIONS.DEFAULT,
            payload: { ...createCarModalInitialState, brands: state.brands },
          });
        } else {
          notify('Ha ocurrido un error', 'error');
        }
      } else {
        const { data } = await updateVehicle({
          variables: {
            record: {
              ...vehicleData,
            },
            filter: {
              _id: car._id,
            },
          },
        });

        setMyCars((cars) => {
          const index = cars.findIndex((item) => item._id === car._id);
          const currentMyCars = [...cars];
          currentMyCars[index] = data.updateVehicle.record;
          return currentMyCars;
        });

        if (data.updateVehicle.recordId) {
          notify('Se ha actualizado el vehículo exitosamnete', 'success');
          setIsOpen(false);
        } else {
          notify('Ha ocurrido un error', 'error');
        }
      }
    } catch (error) {
      if (error.message.includes('plate_1')) {
        return notify('La placa ya se encuentra registrada', 'error');
      }
      notify(`Error al registrar el vehículo: ${error}`, 'error');
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  const handleLinkVehicle = async (_car: Partial<Vehicle>, state: any) => {
    if (!_car) return;
    const validToken = compareToken(state?.token, _car);
    if (validToken || _car?.owner === null) {
      const { data } = await linkVehicleMutation({
        variables: {
          data: {
            userId: user._id,
            vehicleId: _car._id,
            token: _car.token,
          },
        },
      });
      if (data?.linkVehicle[0]?._id) {
        notify('Vehículo agregado correctamente', 'success');
        setIsOpen(false);
        setCarTokenModalIsOpen(false);
        setMyCars(data.linkVehicle);
      }
    } else {
      notify('Token incorrecto', 'error');
    }
  };

  return (
    <>
      <CarModal
        userId={user._id}
        isOpen={isOpen}
        brands={brands}
        loadModels={loadModels}
        loadYears={loadYears}
        loadVersions={loadVersions}
        setIsOpen={setIsOpen}
        handleSubmit={handleSubmit}
        referenceImages={referenceImages}
        vehicle={car ?? undefined}
      />
      <CarTokenModal
        userId={user._id}
        car={car}
        isOpen={carTokenModalIsOpen}
        setIsOpen={setCarTokenModalIsOpen}
        handleLinkVehicle={handleLinkVehicle}
      />
    </>
  );
}
