import React from 'react';
import NProgress from 'nprogress';
import {
  Button,
  EyeIcon,
  Input,
  LockIcon,
  SpinnerIcon,
  UpdateIcon,
} from '@spa-cars/ui';
import { useMutation } from '@apollo/client';
import { User } from '@spa-cars/models';
import { UPDATE_USER } from '../../graphql/mutations';
import { useNotify, useUser } from '../../hooks';

function ProfilePasswordForm() {
  const notify = useNotify();
  const [user, setUser] = useUser();
  const [updateUser] = useMutation<{ updateUser: { record: User } }>(
    UPDATE_USER
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const [showRePassword, setShowRePassword] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [rePassword, setRePassword] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);

  const handleSubmitPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setDisabled(true);
      NProgress.start();
      if (disabled) {
        return;
      }
      if (String(password).length < 8) {
        return notify(
          'La contraseña debe contener al menos 8 carácteres',
          'error'
        );
      }

      if (password !== rePassword) {
        return notify('La contraseñas no coinciden', 'error');
      }

      const { data } = await updateUser({
        variables: {
          record: {
            password,
          },
          filter: {
            _id: user._id,
          },
        },
      });

      if (data.updateUser.record) {
        notify('Se ha actualizado la contraseña con exito', 'success');
        setPassword('');
        setRePassword('');
      } else {
        notify('Ha ocurrido un error', 'error');
      }
    } catch (error) {
      notify(`Error al actualizar perfil: ${error}`, 'error');
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  return (
    <div className="flex w-full md:px-36 lg:px-0 md:w-full lg:w-3/4 flex-col">
      {' '}
      <h4 className="font-semibold text-lg text-primary-300 ">
        Actualizar contraseña
      </h4>
      <form onSubmit={handleSubmitPassword} className="py-4">
        <div className="flex flex-col">
          <div className="my-2">
            <Input
              label="Contraseña"
              placeholder="Ingrese su contraseña"
              value={password}
              onChange={(e) => {
                e.preventDefault();
                setPassword(e.target.value);
              }}
              className="bg-system-white"
              required
              rightIcon={
                <EyeIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                />
              }
              type={showPassword ? 'text' : 'password'}
            >
              <LockIcon className="w-5 h-5 text-neutral-100" />
            </Input>
          </div>
          <div className="my-2">
            <Input
              label="Confirmar contraseña"
              placeholder="Ingrese su contraseña"
              value={rePassword}
              onChange={(e) => {
                e.preventDefault();
                setRePassword(e.target.value);
              }}
              className="bg-system-white"
              required
              rightIcon={
                <EyeIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRePassword(!showRePassword);
                  }}
                />
              }
              type={showRePassword ? 'text' : 'password'}
            >
              <LockIcon className="w-5 h-5 text-neutral-100" />
            </Input>
          </div>
          <Button
            className="my-5 flex justify-center items-center"
            type="submit"
          >
            {disabled ? (
              <SpinnerIcon className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-white" />
            ) : (
              <>
                <UpdateIcon className="w-5 h-5 mr-3" /> Actualizar contraseña
              </>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ProfilePasswordForm;
