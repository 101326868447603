import React from 'react';
import Link from 'next/link';
import NProgress from 'nprogress';
import { Router, useRouter } from 'next/router';
import { CarIcon, EditIcon, LogoutIcon } from '@spa-cars/ui';
import { useMutation } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import SettingsIcon from '@spa-cars/ui/src/icons/SettingsIcon';
import { useNotify, useUser } from '../../hooks';
import { SIGN_OUT } from '../../graphql/mutations';
import profileFallback from '../../public/images/Profile picture.png';

function UserDropdown() {
  const router = useRouter();
  const notify = useNotify();
  const [user, setUser] = useUser();
  const [userDropdownOpen, setUserDropdownOpen] = React.useState(false);
  const [signOut] = useMutation<{ signOut: { signOut: { success: boolean } } }>(
    SIGN_OUT
  );
  Router.events.on('routeChangeStart', () => setUserDropdownOpen(false));

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const handleSignOut = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      NProgress.start();
      await signOut();
      await router.push('/');
      setUser(null);
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      NProgress.done();
    }
  };

  return (
    <div className="absolute z-50 top-3 right-14 md:top-5 md:right-8">
      <button
        type="button"
        onClick={toggleUserDropdown}
        className="w-full flex items-center  space-x-2 lg:justify-between cursor-pointer py-1 lg:px-2 outline-none focus:outline-none"
      >
        <SettingsIcon className="w-4 h-4 text-neutral-100 ml-1" />
        <img
          className="rounded-full w-8 h-8 ml-5"
          src={user?.photo?.src ?? profileFallback.src}
          alt="user"
        />
      </button>
      {userDropdownOpen ? (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: [0, 1],
              transition: {
                duration: 0.5,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.5,
              },
            }}
            className={`w-48 right-0 text-sm leading-normal z-40 p-1 bg-white divide-y shadow rounded ${
              userDropdownOpen ? 'absolute' : 'hidden'
            }  `}
          >
            <Link
              href="/cars"
              as="/cars"
              className="px-4 py-2 flex items-center text-neutral-200 hover:text-primary-400 transition-colors border-none"
            >
              <CarIcon className="mr-3 w-5 h-5 text-primary-400" />
              Mis vehículos
            </Link>
            <Link
              href="/profile"
              as="/profile"
              className="px-4 py-2 flex items-center text-neutral-200 hover:text-primary-400 transition-colors border-none"
            >
              <EditIcon className="mr-3 w-5 h-5" />
              Editar perfil
            </Link>
            <button
              type="button"
              className="flex  w-full text-left border-none"
              onClick={handleSignOut}
            >
              <span className="px-4 py-2 flex items-center text-neutral-200 hover:text-primary-400">
                <LogoutIcon className="mr-3 w-5 h-5" /> Cerrar sesión
              </span>
            </button>
          </motion.div>
        </AnimatePresence>
      ) : null}
    </div>
  );
}

export default UserDropdown;
