/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import NProgress from 'nprogress';
import { AvilaIcon, Button, Input, Modal, UserIcon } from '@spa-cars/ui';
import { validateEmail } from 'avilatek-utils';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from '../../graphql/mutations';
import { useNotify } from '../../hooks';
import logo from '../../public/images/logo.png';

interface ResetPasswordModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSignUpModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSignInModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ResetPasswordModal({
  isOpen,
  setIsOpen,
  setSignUpModalIsOpen,
  setSignInModalIsOpen,
}: ResetPasswordModalProps) {
  const notify = useNotify();
  const [email, setEmail] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (disabled) {
        return;
      }
      if (!validateEmail(email) || String(email).length > 127) {
        return notify('Email inválido', 'error');
      }

      NProgress.start();
      setDisabled(true);

      const { data } = await resetPassword({
        variables: {
          data: {
            email,
          },
        },
      });

      if (data.resetPassword.success) {
        notify(
          `Se ha enviado un email para reestablecer su contraseña`,
          'success'
        );
        setIsOpen(false);
        setTimeout(() => {
          setSignInModalIsOpen(true);
        }, 500);
      } else {
        notify(`Ha ocurrido un error: ${data.resetPassword.err}`, 'error');
      }
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      NProgress.done();
      setDisabled(false);
    }
  };
  return (
    <Modal isOpen={isOpen} setOpen={setIsOpen} className="max-w-[39rem] ">
      <div className="bg-white flex flex-col px-8 md:px-16 pt-16 rounded-lg">
        {/* logo */}
        <div className="relative m-auto h-full w-8/12 flex items-center">
          <img className="w-[25rem] " src={logo.src} alt="spa-car-logo" />
        </div>
        {/* subtitle */}
        <div className="my-9 text-center">
          {' '}
          <span className="text-primary-400 text-lg md:text-2xl font-semibold tracking-wider">
            Ingrese su correo para recuperar su contraseña
          </span>{' '}
        </div>
        <form onSubmit={onSubmit}>
          <div className="px-5 flex flex-col">
            <div className="mb-2">
              <Input
                label="Correo electrónico"
                placeholder="Ingrese su correo electrónico"
                value={email}
                onChange={(e) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
              >
                <UserIcon className="w-5 h-5" />
              </Input>
            </div>
            <Button className="my-5" type="submit">
              Recuperar contraseña
            </Button>
            <div className="w-full text-center mb-8 mt-4">
              <span className="text-sm text-neutral-200 tracking-wider">
                ¿No posee una cuenta?
              </span>{' '}
              <span
                className="text-sm text-primary-300 tracking-wider cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                  setTimeout(() => {
                    setSignUpModalIsOpen(true);
                  }, 500);
                }}
              >
                Crea una cuenta
              </span>
            </div>
            <div className="mx-auto mb-5 flex">
              <a
                target="_blank"
                href="https://www.avilatek.com/"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <AvilaIcon className="w-6 h-6 ml-2 pb-1" />
                <span className="text-sm text-neutral-100 items-center">
                  {' '}
                  Developed by Avila Tek
                </span>
              </a>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ResetPasswordModal;
