import React from 'react';
import { SpinnerIcon } from '../../../../../icons';

interface StepTitleProps {
  title: string;
  description: string;
  loading?: boolean;
  showDescription?: boolean;
}

export default function StepTitle({
  title,
  description,
  loading,
  showDescription,
}: StepTitleProps) {
  return (
    <>
      <div className="flex">
        <h4 className="font-medium text-lg text-primary-300 mt-[0.2rem]">
          {title}
        </h4>
        {loading ? (
          <SpinnerIcon className="w-6 h-6 ml-auto my-auto text-primary-200 animate-spin dark:text-gray-600 fill-white" />
        ) : null}
      </div>
      {showDescription ? <span>{description}</span> : null}
    </>
  );
}
